import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelpWidgetService {
  private _isVisibleSignal = signal(true);
  isVisibleSignal = this._isVisibleSignal.asReadonly();

  setVisibility(isVisible: boolean): void {
    this._isVisibleSignal.set(isVisible);
  }
}
