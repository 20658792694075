/**
 * @deprecated
 */
export enum StorageKeys {
  APP_ANNOUNCEMENT_DISMISSED = 'APP_ANNOUNCEMENT_DISMISSED',
  // Note that if you decide to change it, it is already used on prod
  APP_CLIENT_SELECTION = 'client_selection',
  APP_ENTITY_SELECTION = 'entity_selection',
}

/**
 * @deprecated
 */
export type StorageKey = `${StorageKeys}`;
