import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatNativeDateModule } from '@angular/material/core';

import { BASE_API_URL } from '@1stdigital/ng-sdk/core';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { ApplicationSettingsService } from '@app/core/services';
import { LayoutModuleSettings } from '@app/layout/layout-module.settings';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  ProtocolMode,
  PublicClientApplication,
} from '@azure/msal-browser';
import { RaygunErrorHandler } from 'app.raygun.setup';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { EnvironmentLoaderService } from './core/services/environment-loader.service';
import { RequestInterceptor } from './core/services/request-interceptor.service';
import { LayoutModule } from './layout/layout.module';
import { provideSnackBarService } from './notifications/utils';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FdtIconModule,
    MsalModule,
    LayoutModule,
    CoreModule,
    MatNativeDateModule,
  ],
  providers: [
    {
      provide: BASE_API_URL,
      useFactory: (envService: EnvironmentLoaderService): string => {
        return envService.baseUrl;
      },
      deps: [EnvironmentLoaderService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvironmentLoaderService): (() => Promise<void>) => {
        return () => {
          const envPath = environment.envPath || '/environment.json';
          const _configPath = environment.configPath || `/auth.clientConfiguration.json`;
          const configPath = `${_configPath}?v=${Date.now()}`;

          return envService.loadConfig(envPath, configPath);
        };
      },
      multi: true,
      deps: [EnvironmentLoaderService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: (envService: EnvironmentLoaderService): IPublicClientApplication => {
        return new PublicClientApplication({
          auth: {
            clientId: envService.config.clientId,
            authority: envService.config.authority,
            redirectUri: envService.config.redirectUri,
            postLogoutRedirectUri: '/',
            knownAuthorities: [new URL(envService.config.authority).host],
            protocolMode: ProtocolMode.AAD,
          },
          cache: {
            cacheLocation: BrowserCacheLocation.SessionStorage,
            storeAuthStateInCookie: false,
          },
          system: {
            allowNativeBroker: false, // Disables WAM Broker
            loggerOptions: {
              loggerCallback: (logLevel: LogLevel, message: string) => console.log(message),
              logLevel: LogLevel.Warning,
              piiLoggingEnabled: false,
            },
          },
        });
      },
      deps: [EnvironmentLoaderService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: (envService: EnvironmentLoaderService): MsalGuardConfiguration => {
        return {
          interactionType: InteractionType.Redirect,
          authRequest: {
            scopes: envService.config.scope.split(' '),
            extraQueryParameters: {
              ui_locales: 'en',
            },
          },
          loginFailedRoute: '/redirect-error',
        };
      },
      deps: [EnvironmentLoaderService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: (envService: EnvironmentLoaderService): MsalInterceptorConfiguration => {
        const protectedResourceMap = new Map<string, string[]>();
        protectedResourceMap.set(envService.environment['apiEndpoint'] as string, envService.config.scope.split(' '));
        return {
          interactionType: InteractionType.Redirect,
          protectedResourceMap,
        };
      },
      deps: [EnvironmentLoaderService],
    },
    {
      provide: ErrorHandler,
      useClass: RaygunErrorHandler,
    },
    EnvironmentLoaderService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideSnackBarService(),
    // Replace deprecated HTTP related modules with provider functions.
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor() {
    ApplicationSettingsService.registerSettingsClass('layout', LayoutModuleSettings);
  }
}
