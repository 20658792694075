import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

/* eslint-disable */

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {
  private condition: Subject<boolean> = new Subject<boolean>();
  private dataTransfer: Subject<DataTransfer> = new Subject<DataTransfer>();

  constructor() {}

  public status(): Observable<boolean> {
    return this.condition.asObservable();
  }

  public enable(): void {
    this.condition.next(true);
  }

  public disable(): void {
    this.condition.next(false);
  }

  public newValue(value: DataTransfer): void {
    this.dataTransfer.next(value);
  }

  public data(): Observable<DataTransfer> {
    return this.dataTransfer.asObservable();
  }
}

@Injectable({
  providedIn: 'root'
})
export class DataUploadedService {
  private condition: Subject<boolean> = new Subject<boolean>();
  private dataTransfer: Subject<DataTransfer> = new Subject<DataTransfer>();

  constructor() {}

  public status(): Observable<boolean> {
    return this.condition.asObservable();
  }

  public enable(): void {
    this.condition.next(true);
  }

  public disable(): void {
    this.condition.next(false);
  }

  public newValue(value: DataTransfer): void {
    this.dataTransfer.next(value);
  }

  public data(): Observable<DataTransfer> {
    return this.dataTransfer.asObservable();
  }
}

export class DataTransfer {
  public loaded: number;
  public total?: number;
  public fileId?: number;

  constructor(loaded: number, total?: number, fileId?: number) {
    this.loaded = loaded;
    this.total = total;
    this.fileId = fileId;
  }

  public getPercentDone(): number | null {
    if (this.loaded && this.total) {
      return Math.round((this.loaded / this.total) * 100);
    }

    return null;
  }
}
