@if (message) {
  <div class="announcement">
    <div class="announcement__content">
      <fdt-icon-frame color="accent" type="circle" icon="alertLine" size="small"></fdt-icon-frame>
      <div class="announcement__message" [innerHTML]="message"></div>
    </div>
    <button fdt-ghost-button class="announcement__close-btn" (click)="onCloseAnnouncement()">
      <fdt-icon size="24" [name]="'closeLine'"></fdt-icon>
    </button>
  </div>
}
