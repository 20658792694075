import { fdtProp } from '@1stdigital/ng-sdk/annotations';
import { fdtPropertyGridProp } from '@1stdigital/ng-sdk/property-grid';
import { AssetAccountDto, CryptoAccountDetailsDto } from '@app/core/models';

export class AssetAccountModel implements AssetAccountDto {
  @fdtProp('Client', 'string')
  clientName!: string;
  @fdtProp('Service Account No.', 'string')
  assetAccountNumber!: string;
  @fdtProp('Alias', 'string')
  @fdtPropertyGridProp({ nullable: true })
  assetAccountAlias: string | null = null;
}

export class CryptoAssetAccountModel implements CryptoAccountDetailsDto {
  assetName!: string;
  assetSymbol!: string;

  @fdtProp('Wallet Currency', 'asset')
  walletCurrency!: { assetName: string; assetSymbol: string };

  @fdtProp('Wallet address', 'string')
  @fdtPropertyGridProp({ actions: ['copy'], nullable: true })
  walletAddress!: string;

  @fdtProp('Network', 'network')
  network!: string;

  @fdtProp('Client', 'string')
  clientName!: string;

  @fdtProp('Service Account No.', 'string')
  assetAccountNumber!: string;
}
