import { Injectable } from '@angular/core';

import { AnalyticsService } from '@app/core/services/analytics.service';
import { ClientService } from '@app/core/services/client.service';
import { KycSelfieContextService } from '@app/core/services/kyc-selfie-context.service';
import { MsalService } from '@azure/msal-angular';

const LOGOUT_CHANNEL = 'logout';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  public logoutChannel: BroadcastChannel = new BroadcastChannel(LOGOUT_CHANNEL);

  constructor(
    private authService: MsalService,
    private clientService: ClientService,
    private analyticsService: AnalyticsService,
    private kycSelfieContextService: KycSelfieContextService
  ) {}

  logout(): void {
    this.analyticsService.reset();
    this.clientService.forgetTemporarySelection();
    this.authService.logoutRedirect();
    this.logoutChannel.postMessage(LOGOUT_CHANNEL);
    this.kycSelfieContextService.clear();
  }

  /**
   * Temporary here; will be moved to a more appropriate service.
   * @returns The time of the last authentication in seconds since the Unix epoch.
   */
  getAuthTime(): number {
    const activeAccount = this.authService.instance.getActiveAccount();

    return activeAccount?.idTokenClaims?.auth_time || -1;
  }
}
