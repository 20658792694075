import { InjectionToken } from '@angular/core';

import { MatSnackBarConfig } from '@angular/material/snack-bar';

export const CLICKABLE_ID_PREFIX = 'fdt---clickable---id---';
export const CLICKABLE_CLASS = 'fdt-snack-bar__clickable';
export const LINK_CLASS = 'fdt-snack-bar__link';

export interface CustomSnackBarComponent {}

export interface MakeLinkOptions {
  href: string;
  class?: string;
}

export interface MakeClickableOptions {
  id: string;
}

export const enum SnackBarMode {
  Success,
  Warning,
  Error,
  Info,
}

export interface SnackBarMessageConfig {
  message?: string;
  caption?: string;
  /*
    NOTE: use can use makeLink and makeClickable
    to provide links and callbacks inside snack-bars
  */
  inMessageCallbacks?: { [id: string]: () => void };
  /**
   * Hardcoded this option as widely used across the app
   */
  undoCallback?: () => void;
  suggestedAction?: SuggestedActionConfig;
}

export interface SnackBarData extends SnackBarMessageConfig {
  mode: SnackBarMode;
  duration?: number;
}

export interface SuggestedActionConfig {
  actionName: string;
  callback: () => void;
}

/**
 * NOTE that panelClass should be an array
 */
export type SnackBarConfig = MatSnackBarConfig;

export interface CommonConfig {
  component?: CustomSnackBarComponent;
  snackBarConfig?: SnackBarConfig;
}
export type PreparedCommonConfig = Required<CommonConfig>;

export interface ErrorConfig {
  snackBarOverrides?: SnackBarConfig;
}
export type PreparedErrorConfig = Required<ErrorConfig>;

export interface SuccessConfig {
  snackBarOverrides?: SnackBarConfig;
}
export type PreparedSuccessConfig = Required<SuccessConfig>;

export interface InfoConfig {
  snackBarOverrides?: SnackBarConfig;
}
export type PreparedInfoConfig = Required<InfoConfig>;

export interface SnackBarUserConfig {
  common?: CommonConfig;
  error?: ErrorConfig;
  success?: SuccessConfig;
  info?: InfoConfig;
}

export interface SnackBarPreparedConfig {
  common: PreparedCommonConfig;
  error: PreparedErrorConfig;
  success: PreparedSuccessConfig;
  info: PreparedInfoConfig;
}

export const COMMON_CONFIG_DEFAULTS: CommonConfig = {
  snackBarConfig: {
    duration: 8000,
    panelClass: [],
    horizontalPosition: 'right',
  },
};

export const ERROR_CONFIG_DEFAULTS: ErrorConfig = {
  snackBarOverrides: {
    duration: undefined,
    panelClass: [],
  },
};
export const SUCCESS_CONFIG_DEFAULTS: SuccessConfig = {
  snackBarOverrides: {
    panelClass: [],
  },
};

export const INFO_CONFIG_DEFAULTS: InfoConfig = {
  snackBarOverrides: {
    panelClass: [],
  },
};

export const FDT_SNACK_BAR_CONFIGURATION = new InjectionToken<SnackBarPreparedConfig>(
  'Configuration for snackbar prepared for use'
);
