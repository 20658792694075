@switch (eventId) {
  @case ('ams.instruction.cancelled.maker') {
    Your {{ requestData.instructionType }} instruction from {{ requestData.instructionCreatedDate }} was cancelled by
    {{ requestData.checkerFullName }}. Please create a new instruction (Ref:
    {{ requestData.instructionReferenceNumber }}).
  }
  @case ('ams.instruction.waitingforreview.checker') {
    New {{ requestData.instructionType }} instruction by {{ requestData.makerFullName }} created on
    {{ requestData.instructionCreatedDate }} (Ref: {{ requestData.instructionReferenceNumber }}).
  }
}
