import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';

import { FdtFormFieldControl, FdtFormFieldControlType } from '@1stdigital/ng-sdk/form-field';
import {
  FileUploaderComponent,
  FileUploaderComponentInput,
} from '@app/shared/file-upload/file-uploader/file-uploader.component';
import { TemporaryUploadedFileDto } from '@app/shared/file-upload/services/file-storage.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-file-upload-control',
  standalone: true,
  imports: [FileUploaderComponent],
  providers: [
    {
      provide: FdtFormFieldControl,
      useExisting: FileUploadControlComponent,
    },
  ],
  templateUrl: './file-upload-control.component.html',
  styleUrl: './file-upload-control.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadControlComponent
  implements ControlValueAccessor, FdtFormFieldControl<TemporaryUploadedFileDto[] | null>, OnInit, OnDestroy
{
  @ViewChild(FileUploaderComponent) fileUploader!: FileUploaderComponent;

  value: TemporaryUploadedFileDto[] | null = null;

  @Input() config!: Partial<FileUploaderComponentInput>;

  focused = false;
  disabled = false;
  stateChanges: Subject<void> = new Subject<void>();
  hasValue!: boolean;
  required!: boolean | null;

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onChange = (_: unknown): void => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = (): void => {};

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private readonly cdr: ChangeDetectorRef
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.ngControl.control?.addValidators([this.checkAllFileTypes.bind(this)]);
    this.ngControl.control?.updateValueAndValidity();
  }

  controlType = FdtFormFieldControlType.file;

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  writeValue(value: TemporaryUploadedFileDto[] | null): void {
    this.value = value;
  }

  onAttachmentUpload(temporaryUploadedFileDto: TemporaryUploadedFileDto[]): void {
    const value = temporaryUploadedFileDto.length > 0 ? temporaryUploadedFileDto : null;

    // when first file is uploaded marking as untouched, to not show error, since file is there, just the select type pending;
    if (!this.value && value?.length) {
      this.ngControl.control?.markAsUntouched();
    }

    this.onChange(value);

    this.value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  // Set the function to be called when the control is touched in the view
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onFocus(): void {
    this.focused = true;
    this.stateChanges.next();
  }

  onBlur(): void {
    this.focused = false;

    if (this.onTouched) {
      // be caution with this, since this triggers showing our validation errors;
      this.onTouched();
    }

    this.stateChanges.next();
  }

  onParentContainerClick(): void {
    this.fileUploader.onCustomClick();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  private checkAllFileTypes(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const allFilesAreValid = control.value.every((file: TemporaryUploadedFileDto) => !!file.documentType);

    return allFilesAreValid ? null : { documentTypeRequired: true };
  }
}
