import { Injectable } from '@angular/core';

import { FdtFormControl } from '@1stdigital/ng-sdk/form';

@Injectable({
  providedIn: 'root',
})
export class FormHelperService {
  handleRestrictedSelection(restricted: boolean, control: FdtFormControl): void {
    const message =
      'Service account is restricted. Please contact the client services team to be able to use this service account';
    this.updateControlCustomErrorState(control, restricted, message, 'restrictedAccount');
  }

  handleDifferentAccountCurrency(restricted: boolean, control: FdtFormControl): void {
    const message = 'This account does not support the selected currency';
    this.updateControlCustomErrorState(control, restricted, message, 'differentAccount');
  }

  private updateControlCustomErrorState(
    control: FdtFormControl,
    restricted: boolean,
    message: string,
    validatorName: string = 'custom'
  ): void {
    if (restricted) {
      control.setErrors({
        ...control.errors,
        [validatorName]: message,
      });
    } else if (control.errors) {
      const { errors } = control.errors;
      errors[validatorName] = null;
      control.setErrors(Object.keys(errors).length ? errors : null);
    }
  }
}
