import { Injectable } from '@angular/core';

import { Amount, AssetClasses } from '@1stdigital/ng-sdk/core';
import { AssetData, ColorMapping, ColorVariables } from '@app/dashboard/shared/models/portfolio-overview.interface';
import { shorthandAmount } from '@app/shared/display-property/utils';

@Injectable({
  providedIn: 'root',
})
export class WidgetChartUtilsService {
  private assetMapping: ColorMapping = {
    [AssetClasses.Cash]: { colorVariable: ColorVariables.Cash },
    [AssetClasses.Bond]: { colorVariable: ColorVariables.Bond },
    [AssetClasses.Cryptocurrency]: { colorVariable: ColorVariables.Cryptocurrency },
    [AssetClasses.NFT]: { colorVariable: ColorVariables.NFT },
    [AssetClasses.Commodity]: { colorVariable: ColorVariables.Commodity },
    [AssetClasses.PrivateEquity]: { colorVariable: ColorVariables.PrivateEquity },
    [AssetClasses.RealEstate]: { colorVariable: ColorVariables.RealEstate },
    [AssetClasses.Stock]: { colorVariable: ColorVariables.Stock },
  };

  // To avoid conflicts, I avoided too many changes in this service.
  // After merging another branch with some extra enums, I will think what could we do here
  // One missing options is "Fixed income"
  private assetLabelMapping: Record<AssetClasses, string> = {
    [AssetClasses.Cash]: 'Cash',
    [AssetClasses.Bond]: 'Bonds',
    [AssetClasses.Cryptocurrency]: 'Crypto',
    [AssetClasses.NFT]: 'NFTs',
    [AssetClasses.Commodity]: 'Commodities',
    [AssetClasses.PrivateEquity]: 'Private equity',
    [AssetClasses.RealEstate]: 'Real estate',
    [AssetClasses.Stock]: 'Stocks',
  };

  nFormatter(num: number, digits: number): string {
    const result = shorthandAmount(num, { digits, shorthandGain: 1 });

    return result.value;
  }

  prepareAmountForChart(amount: number): Amount {
    return {
      amount,
      assetSymbol: 'USD',
    };
  }

  formatAssetHoldingYLegend(value: number | string): string {
    return `$${this.nFormatter(+value, 0)}`;
  }
  getLabelByAsset(key: AssetClasses): string {
    return this.assetLabelMapping[key] || '';
  }

  getAssetColors(assets: AssetData[]): string[] | undefined {
    const colors: string[] = [];

    for (const asset of assets) {
      const color = this.getAssetColorFromCss(`${asset.assetClass}`);

      if (!color) {
        // let chart decide colors
        return undefined;
      }

      colors.push(color);
    }

    return colors;
  }

  getAssetColor(asset: AssetClasses): string | undefined {
    return this.getAssetColorFromCss(`${asset}`);
  }

  getChartColor(key: ColorVariables): string | undefined {
    return this.getColorFromCss(key);
  }

  private getAssetColorFromCss(key: string): string {
    const colorVariable = this.assetMapping[key].colorVariable;

    return this.getColorFromCss(colorVariable);
  }

  private getColorFromCss(colorVariable: string): string {
    return getComputedStyle(document.documentElement).getPropertyValue(colorVariable);
  }
}
