import { Injectable } from '@angular/core';

import { BaseApiService } from '@1stdigital/ng-sdk/core';
import { ClientAccessorDto } from '@app/core/models/interfaces/client.interface';
import { Observable } from 'rxjs';

Injectable({
  providedIn: 'root',
});
export class LayoutApiService extends BaseApiService {
  getClientAccessorList(): Observable<ClientAccessorDto[]> {
    return this.get<ClientAccessorDto[]>('clients-accessor');
  }
}
