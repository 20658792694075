<ng-template>
  <ng-content></ng-content>
  @if (!isLastStep) {
    @if (isEdit) {
      <!-- For now this logic lives here, in future if we encounter different cases for Next, we might need to handle this outside -->
      <div class="stepper__footer">
        <ng-container
          *ngTemplateOutlet="
            editActionsTemplateRef ? editActionsTemplateRef : editActionsTemp;
            context: {
              $implicit: this,
            }
          "
        ></ng-container>
      </div>
    } @else if (!isDone) {
      <div class="stepper__footer">
        <ng-container
          *ngTemplateOutlet="
            nextActionTemplateRef ? nextActionTemplateRef : nextTemp;
            context: {
              $implicit: this,
            }
          "
        ></ng-container>
      </div>
    }
  }
</ng-template>

<ng-template #nextTemp>
  <button
    fdt-button
    color="primary"
    size="large"
    [disabled]="stepDisabled || isBusy"
    [loading]="isBusy"
    (click)="onNext()"
  >
    Next
  </button>
</ng-template>

<ng-template #editActionsTemp>
  <button fdt-button color="secondary" size="large" (click)="onCancel()">Cancel</button>

  <button
    fdt-button
    color="primary"
    size="large"
    [disabled]="stepDisabled || isBusy || stepControl.pristine"
    [loading]="isBusy"
    (click)="onUpdate()"
  >
    Edit
  </button>
</ng-template>
