import { AfterViewChecked, booleanAttribute, Directive, ElementRef, inject, Input } from '@angular/core';

import { LoggingService } from '@app/core/services';

/**
 * AutoFocusDirective automatically focuses the first available focusable element within its host element once the view has been fully checked.
 * If no focusable elements are found within the host element, the host element itself will receive focus.
 *
 * @example
 * ```html
 *  <div class="fdt-asset-transfer-form" [formGroup]="additionalInfoForm" af-disabled="true">
 *  <fdt-form-field></fdt-form-field>
 *  <fdt-form-field></fdt-form-field>
 *  </div>
 * ```
 *
 * @example
 * ```html
 * <fdt-form-field appAutoFocus>
 *   <fdt-label>Instruction notes</fdt-label>
 *   <fdt-textarea [rows]="2"></fdt-textarea>
 * </fdt-form-field>
 * ```
 */
@Directive({
  selector: '[appAutoFocus], [formGroup]',
  standalone: true,
})
export class AutoFocusDirective implements AfterViewChecked {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input({ alias: 'af-disable', transform: booleanAttribute }) isDisabled = false;

  private readonly hostElement = inject(ElementRef);
  private readonly loggingService = inject(LoggingService);

  private readonly focusableItemsSelector = `a[href], button, input, textarea, select, [tabindex]:not([tabindex="-1"]), [contenteditable]:not([contenteditable="false"])`;
  private isFocused: boolean = false;

  ngAfterViewChecked(): void {
    // Node.COMMENT_NODE (8) - A Comment node, such as <!-- … -->.
    // (https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType#node.comment_node)
    if (this.hostElement.nativeElement.nodeType === 8) {
      throw new Error('[formGroup] cannot be used on an ng-container. Comment nodes are not supported.');
    }

    if (!this.isFocused && !this.isDisabled) {
      this.focusFirstElement();
    }
  }

  private focusFirstElement(): void {
    const { allElements, availableElements } = this.getFocusableElements();

    if (allElements.length === 0 || availableElements.length > 0) {
      const targetElement: HTMLElement =
        availableElements.length > 0 ? availableElements[0] : this.hostElement.nativeElement;

      targetElement.focus();
      this.loggingService.log('AutoFocusDirective: First element in formGroup is focused');

      if (availableElements.length > 0) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      }

      this.isFocused = true;
    }
  }

  private getFocusableElements(): { allElements: HTMLElement[]; availableElements: HTMLElement[] } {
    const allElements: HTMLElement[] = [
      ...this.hostElement.nativeElement.querySelectorAll(this.focusableItemsSelector),
    ];
    const availableElements = allElements.filter((el: HTMLElement): boolean => {
      const computedStyle = getComputedStyle(el);

      return (
        !el.hasAttribute('disabled') &&
        el.offsetParent !== null &&
        computedStyle.display !== 'none' &&
        computedStyle.visibility !== 'hidden'
      );
    });

    return {
      allElements,
      availableElements,
    };
  }
}
