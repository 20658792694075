import { CdkObserveContent } from '@angular/cdk/observers';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { interval, Subscription, takeWhile } from 'rxjs';

/*
  I picked already implemented mat-spinner components to not deal with programmable animations and varying progress value.
  mat-spinner itself are just svg circles with some extra functionality.
  At same time if it is needed, we can change inner implementation without breaking API of this component

  NOTE: since mat-spinner is used, we can relatively simply add "intermediate" spinner mode if it is needed once
 */
/**
 * A component that displays a progress spinner, with a countdown feature.
 *
 * Component size:
 * - When pass inner content, it will be used to calculate component dimensions by default
 * - If you pass diameter, it will be used to calculate component size over inner content dimensions
 * - If you want to use component without inner content, provide diameter to calculate dimensions
 *
 * Progress:
 *
 * Progress change can work in 2 modes:
 * - Progress mode, when progress value is provided outside
 * - Countdown mode, when progress changes over provided time value
 *
 * Progress mode works by priority principle: Countdown >>> Progress
 * - If Countdown and Progress passed simultaneously, countdown is used, progress is changed back to 0
 * - Otherwise decide based on active input
 *
 * Once progress is complete (100) component emits progressComplete.
 * Note, if pass progress=100, it will also emit event.
 *
 * Note, if you have content with varying size, like block that initiated asyncronously,
 * it is better to pass it in the container with known dimensions
 *
 * @example
 * ```html
 * <app-progress-spinner [diameter]="30" [countdown]="8000"></app-progress-spinner>
 *
 * <app-progress-spinner [progress]="91">
 *   <fdt-icon name="arrowUpDownLine"></<fdt-icon>
 * </app-progress-spinner>
 *
 * <app-progress-spinner [diameter]="30" [progress]="91">
 *   <!-- Ignore size of inner content and use diameter instead -->
 *   <fdt-icon size="40" name="arrowUpDownLine"></<fdt-icon>
 * </app-progress-spinner>
 * ```
 */
@Component({
  selector: 'app-progress-spinner',
  standalone: true,
  imports: [MatProgressSpinnerModule, CdkObserveContent],
  templateUrl: './progress-spinner.component.html',
  styleUrl: './progress-spinner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressSpinnerComponent implements AfterContentInit {
  passedContent = viewChild<ElementRef>('passedContent');

  innerContainerSize = 0;
  usedDiameter = 0;

  /**
   * Emits when a progress field reached 100
   */
  @Output() progressComplete = new EventEmitter<void>();

  /**
   * @param value Progress value from 0 to 100
   */
  @Input() set progress(value: number) {
    this._progress = value;
    this.emitIfCompleted();
    this.cd.markForCheck();
  }

  get progress(): number {
    return this._progress;
  }

  // NOTE: used only to set the diameter from outside
  /**
   * @param value Diameter size in pixels
   */
  @Input() set diameter(value: SpinnerDiameter | undefined) {
    if (value) {
      this.sizeMode = 'diameter';
      this.usedDiameter = value;
      this.calculateContainerSizeByDiameter();
    } else {
      this.sizeMode = 'content';
      this.onProjectContentChanged();
    }
  }

  // NOTE: used only to set the diameter from outside
  /**
   * @param time Countdown time in milliseconds
   */
  @Input() set countdown(time: number | undefined) {
    if (!time) {
      this.countdownSub?.unsubscribe();
      return;
    }

    this._countdown = time;
    this.restartTimer(this._countdown);
  }

  private sizeMode: 'diameter' | 'content' = 'content';
  private incrementStep = 2;
  private _progress = 0;
  private _countdown?: number;
  private countdownSub?: Subscription;

  constructor(
    private destroyRef: DestroyRef,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterContentInit(): void {
    this.onProjectContentChanged();
  }

  // Used only when there is no diameter provided outside
  onProjectContentChanged(): void {
    if (this.sizeMode !== 'content') {
      return;
    }

    const size = this.getContentDimension();

    if (size) {
      this.innerContainerSize = size;
      this.calculateDiameterByContentSize(size);
    }
  }

  private calculateDiameterByContentSize(contentSize: number): void {
    this.usedDiameter = Math.round(Math.SQRT2 * contentSize);
  }

  private calculateContainerSizeByDiameter(): void {
    // calculate size of square inside circle
    this.innerContainerSize = Math.sqrt(this.usedDiameter ** 2 / 2);
  }

  private incrementProgress(): void {
    this.progress += this.incrementStep;
  }

  private restartTimer(countdown: number): void {
    this.progress = 0;
    this.countdownSub?.unsubscribe();
    this.countdownSub = interval(countdown / (100 / this.incrementStep))
      .pipe(
        takeWhile(() => this.progress <= 100),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.incrementProgress();
      });
  }

  private emitIfCompleted(): void {
    if (this.progress >= 100) {
      this.progressComplete.emit();
    }
  }

  private getContentDimension(): number {
    const content = this.passedContent();
    const height = content?.nativeElement.offsetHeight ?? 0;
    const width = content?.nativeElement.offsetWidth ?? 0;

    return height > width ? height : width;
  }
}

// Use hardcoded values to keep component in sync with ui-kit
export type SpinnerDiameter = 30;
