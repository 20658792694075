<section class="welcome-modal">
  <header class="welcome-modal__head"></header>

  <main>
    <h2 class="welcome-modal__title">The Reimagined FDT Client Portal</h2>
    <p class="welcome-modal__description">
      We’re thrilled to introduce you to our next-generation Client Portal, designed to elevate your experience. Explore
      new cutting-edge functionalities and a superior interface! Move, manage and custody your assets all in one place.
    </p>
  </main>

  <footer class="welcome-modal__actions">
    <button fdt-button color="secondary" (click)="onTakeTourLater()" appTrackEvent="Take tour later clicked">
      Take tour later
    </button>
    <button fdt-button (click)="onStartTour()" appTrackEvent="Start tour click">Start tour</button>
  </footer>
</section>
