export enum ActivityType {
  TransferIn = 'transfer-in',
  TransferOut = 'transfer-out',
  RsnTransfer = 'rsn-transfer',
  ThirdPartyTransfer = 'third-party-transfer',
  Otc = 'otc',
  Fx = 'fx',
  Bond = 'bond',
  Card = 'card',
  None = 'none',
}
