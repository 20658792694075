import { TourContentArrowDirection } from '@app/tour/models/tour-content-arrow-direction.type';

/**
 * Calculates the offset for the arrow based on its direction, positions of the trigger and overlay elements.
 *
 * @param direction - The direction of the arrow: 'left', 'right', 'top', or 'bottom'.
 * @param triggerEle - The element that triggers the tour step.
 * @param overlayEle - The element displaying the tour step content.
 * @returns The offset in pixels, or `null` if the direction is not recognized.
 */
export function getArrowOffset(
  direction: TourContentArrowDirection,
  triggerEle: HTMLElement,
  overlayEle: HTMLElement
): number | null {
  const triggerRect = triggerEle.getBoundingClientRect();
  const overlayRect = overlayEle.getBoundingClientRect();

  switch (direction) {
    case 'left':
    case 'right':
      return triggerRect.top + triggerRect.height / 2 - overlayRect.top;

    case 'top':
    case 'bottom':
      return triggerRect.left + triggerRect.width / 2 - overlayRect.left;

    default:
      return null;
  }
}
