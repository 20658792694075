import { Injectable } from '@angular/core';

import { BaseApiService } from '@1stdigital/ng-sdk/core';
import { Observable } from 'rxjs';
import {
  CreateKycSelfieRequest,
  KycSelfieCheckStatusResponse,
  KycSelfieResultResponse,
  KycSelfieTokenResponse,
  UserDetailForKYCSelfieResponse,
} from 'src/app/kyc-selfie/shared/kyc-selfie.models';

Injectable({
  providedIn: 'root',
});
export class KycApiService extends BaseApiService {
  getUserKycSelfieDetails(): Observable<UserDetailForKYCSelfieResponse> {
    return this.get('kyc/selfie-user-details');
  }

  getSelfieToken(): Observable<KycSelfieTokenResponse> {
    return this.get('kyc/selfie-token');
  }

  getSelfieCheck(): Observable<KycSelfieResultResponse> {
    return this.get('kyc/selfie-check');
  }

  createApplicant(): Observable<void> {
    return this.post('kyc/create-applicant', {});
  }

  createSelfieCheck(dto: CreateKycSelfieRequest): Observable<KycSelfieCheckStatusResponse> {
    return this.post('kyc/selfie-check', dto);
  }
}
