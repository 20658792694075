/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { delayedRetry } from '@app/shared/operators';
import { map, Observable, Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import {
  InboxMarkAllReadResponse,
  InboxMessageRequestData,
  InboxMessageRequestDataResponse,
  InboxMessagesResponse,
  MessageStatus,
} from '../models/interfaces/inbox.interface';
import { ApplicationSettingsService } from './application-settings.service';
import { EnvironmentLoaderService } from './environment-loader.service';

@Injectable({
  providedIn: 'root',
})
export class CourierService {
  private readonly limit = 10;
  private wsUrl = `${this.envService.environment['courierWssUrl']}?clientKey=${this.envService.environment['courierClientKey']}`;
  private _ws$?: WebSocketSubject<unknown>;
  private messagesProps = `
    totalCount
    pageInfo {
      hasNextPage
      startCursor
    }
    data: nodes {
      actions(version: 2) {
        data
        content
        href
      }
      archived
      created
      data
      icon
      messageId
      preview
      read
      icon
      tags
      title
    }
`;
  wsMessage$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private envService: EnvironmentLoaderService,
    private appSettingsService: ApplicationSettingsService
  ) {}

  private createGetMessagesQuery(): string {
    return `
      query GetMessageLists(
        $params: FilterParamsInput,
        $limit: Int = ${this.limit},
        $after: String
      ) {
        messages(params: $params, limit: $limit, after: $after) {
          ${this.messagesProps}
        }
      }
    `;
  }

  initWebSocket(clientId: string): void {
    this._ws$ = webSocket({
      url: this.wsUrl,
      openObserver: {
        next: () => {
          const subscriptionMessage = {
            action: 'subscribe',
            data: {
              channel: `${this.appSettingsService.userId}_${clientId}`,
              clientKey: this.envService.environment['courierClientKey'],
            },
          };

          this._ws$?.next(subscriptionMessage);
        },
      },
      closeObserver: {
        next: () => {
          console.log('WebSocket connection closed');
        },
      },
    });

    this._ws$.pipe(delayedRetry()).subscribe(() => this.wsMessage$.next());
  }

  getMessages(status: MessageStatus, after?: string | null): Observable<InboxMessagesResponse> {
    const payload = {
      query: this.createGetMessagesQuery(),
      operationName: 'GetMessageLists',
      variables: {
        params: {
          status,
        },
        limit: this.limit,
        after,
      },
    };

    return this.http.post<InboxMessagesResponse>('/q', payload).pipe(
      map((response) => ({
        ...response,
        data: {
          ...response.data,
          messages: {
            ...response.data.messages,
            data: response.data.messages.data.map((m) => ({
              ...m,
              data: {
                ...m.data,
                requestData: this.toCamelCase(m.data.requestData as unknown as InboxMessageRequestDataResponse),
              },
            })),
          },
        },
      }))
    );
  }

  private toCamelCase(requestData: InboxMessageRequestDataResponse): InboxMessageRequestData {
    if (requestData) {
      return Object.keys(requestData).reduce((result: any, key: string) => {
        const camelKey = (key.charAt(0).toLowerCase() + key.slice(1)) as keyof InboxMessageRequestData;
        result[camelKey] = requestData[key as keyof InboxMessageRequestDataResponse];
        return result;
      }, {});
    }

    return {} as InboxMessageRequestData;
  }

  markAllAsRead(): Observable<InboxMarkAllReadResponse> {
    const payload = {
      operationName: 'TrackEvent',
      query: `
        mutation TrackEvent {
          markAllRead
        }
      `,
    };

    return this.http.post<InboxMarkAllReadResponse>('/q', payload);
  }

  markAsRead(messageId: string): Observable<void> {
    const payload = {
      operationName: 'TrackEvent',
      query: `
        mutation TrackEvent(
          $messageId: String!
        ) {
          read(messageId: $messageId)
        }
      `,
      variables: {
        messageId,
      },
    };

    return this.http.post<void>('/q', payload);
  }

  disconnectWs(): void {
    this._ws$?.complete();
  }
}
