import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, OperatorFunction, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterHelperService {
  private readonly route404 = ['404'];
  constructor(private router: Router) {}

  /**
   * Navigates the user to the previous page in the browser's history if there is one.
   * If there is no history (i.e., if the history length is 1 or less), it navigates
   * the user to a specified default route.
   *
   * @param {Array<string | number>} defaultCommands - The route commands to navigate to
   * when there is no browser history to go back to. (router.navigate accepts "any[]",
   * but this function restricts it to strings and numbers since we don't have other use cases).
   */
  goBack(defaultCommands: (string | number)[]): void {
    if (history.length > 1) {
      history.back();
    } else {
      this.router.navigate(defaultCommands);
    }
  }

  navigateToParent(): void {
    const url = this.router.url;
    const segments = url.split('/');
    segments.pop();
    const parentUrl = segments.join('/');
    this.router.navigate([parentUrl]);
  }

  redirectOnApiAccessError<T>(): OperatorFunction<T, T> {
    return this.redirectOnApiErrorCases([403, 404], this.route404);
  }

  private redirectOnApiErrorCases<T>(statusCodes: number[], redirectRoute: string[]): OperatorFunction<T, T> {
    return catchError((err) => {
      if (statusCodes.includes(err.status)) {
        this.router.navigate(redirectRoute);
        return throwError(() => err);
      }

      return throwError(() => err);
    });
  }
}
