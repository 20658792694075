import { Injectable } from '@angular/core';

import { KycSelfieStatus, UserDetailForKYCSelfieResponse } from 'src/app/kyc-selfie/shared/kyc-selfie.models';

export const ACTIVE_KYC_SELFIE_ENTITY = 'active_kyc_selfie_entity';
export const KYC_SELFIE_ENTITY_IN_PROGRESS = 'kyc_selfie_entity_in_progress';

@Injectable({
  providedIn: 'root',
})
export class KycSelfieContextService {
  setActiveKycSelfieEntity(kycSelfie: UserDetailForKYCSelfieResponse): void {
    sessionStorage.setItem(ACTIVE_KYC_SELFIE_ENTITY, JSON.stringify(kycSelfie));
  }

  setKycSelfieStatus(status: KycSelfieStatus): void {
    const activeKycSelfieEntity = this.getActiveKYCSelfieEntity()!;
    activeKycSelfieEntity.kycSelfieStatus = status;
    sessionStorage.setItem(ACTIVE_KYC_SELFIE_ENTITY, JSON.stringify(activeKycSelfieEntity));
  }

  getActiveKYCSelfieEntity(): UserDetailForKYCSelfieResponse | null {
    const kycSelfie = JSON.parse(sessionStorage.getItem(ACTIVE_KYC_SELFIE_ENTITY)!) as UserDetailForKYCSelfieResponse;

    if (kycSelfie != null) {
      return kycSelfie;
    }

    return null;
  }

  clear(): void {
    sessionStorage.removeItem(ACTIVE_KYC_SELFIE_ENTITY);
    sessionStorage.removeItem(KYC_SELFIE_ENTITY_IN_PROGRESS);
  }
}
