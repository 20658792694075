import { Provider } from '@angular/core';

import { AppSnackBarComponent } from './app-snack-bar/app-snack-bar.component';
import {
  CLICKABLE_CLASS,
  CLICKABLE_ID_PREFIX,
  COMMON_CONFIG_DEFAULTS,
  CommonConfig,
  ERROR_CONFIG_DEFAULTS,
  ErrorConfig,
  FDT_SNACK_BAR_CONFIGURATION,
  INFO_CONFIG_DEFAULTS,
  InfoConfig,
  LINK_CLASS,
  MakeClickableOptions,
  MakeLinkOptions,
  SnackBarPreparedConfig,
  SnackBarUserConfig,
  SUCCESS_CONFIG_DEFAULTS,
  SuccessConfig,
} from './models';

/**
 * @description
 * This function is used in the SnackBarMessageConfig
 * for message and caption fields to provide link inside notification
 *
 * @example
 * this.snackBarService.showError({
 *  message: 'My sad message',
 *  caption: `My sad caption ${makeLink('link text', { href: 'https://www.google.com' })}`
 * }})
 *
 */
export function makeLink(text: string, options: MakeLinkOptions): string {
  return `<a class="${options.class ?? LINK_CLASS}" target="_blank" href="${options.href}">${text}</a>`;
}

/**
 * @description
 * This function is used in the SnackBarMessageConfig
 * for message and caption fields to provide clickable element inside snack-bar message or string
 *
 * @example
 * this.snackBarService.showError({
 *  message: 'My sad message',
 *  caption: `My sad caption ${makeClickable('link text', { id: 'someId' })}`
 *  callbacks: { someId: () => {
 *    // anything here
 *  } }
 * }})
 *
 */
export function makeClickable(text: string, options: MakeClickableOptions): string {
  return `<span class="${CLICKABLE_CLASS} ${CLICKABLE_ID_PREFIX}${options.id}">${text}</span>`;
}

/**
 * @description
 * Utility function to extract callbackId
 * from the class of clickable span inside snackbar innerHTML.
 */
export function getIdFromClickableClassString(classesStr: string): string {
  let bindId = classesStr.split(' ').find((c) => c.startsWith(CLICKABLE_ID_PREFIX));
  bindId = bindId?.split('---').slice(3).join('') ?? '';

  return bindId;
}

/**
 * @description
 * HIGHLY recommended to use this function in the root of application to provide
 * configuration for the SnackBarService.
 */
export function provideSnackBarService(config?: SnackBarUserConfig): Provider {
  return {
    provide: FDT_SNACK_BAR_CONFIGURATION,
    useValue: getDefaultPreparedSnackBarConfig(config),
  };
}

/**
 * Avoid to use this function directly without purpose
 */
export function getDefaultPreparedSnackBarConfig(config?: SnackBarUserConfig): SnackBarPreparedConfig {
  const preparedConfig: SnackBarPreparedConfig = {
    common: prepareCommonConfig(config?.common),
    error: prepareErrorConfig(config?.error),
    success: prepareSuccessConfig(config?.success),
    info: prepareInfoConfig(config?.info),
  };

  return preparedConfig;
}

/**
 * Avoid to use this function directly without purpose
 */
function prepareCommonConfig(common?: CommonConfig): Required<CommonConfig> {
  const component = common?.component ?? AppSnackBarComponent;
  const commonSnackBarConfig = {
    ...COMMON_CONFIG_DEFAULTS.snackBarConfig,
    ...common?.snackBarConfig,
  };

  return {
    component,
    snackBarConfig: commonSnackBarConfig,
  } as Required<CommonConfig>;
}

/**
 * Avoid to use this function directly without purpose
 */
function prepareErrorConfig(error?: ErrorConfig): Required<ErrorConfig> {
  return {
    snackBarOverrides: {
      ...ERROR_CONFIG_DEFAULTS.snackBarOverrides,
      ...error?.snackBarOverrides,
    },
  };
}

/**
 * Avoid to use this function directly without purpose
 */
function prepareSuccessConfig(success?: SuccessConfig): Required<SuccessConfig> {
  return {
    snackBarOverrides: {
      ...SUCCESS_CONFIG_DEFAULTS.snackBarOverrides,
      ...success?.snackBarOverrides,
    },
  };
}

/**
 * Avoid to use this function directly without purpose
 */
function prepareInfoConfig(info?: InfoConfig): Required<InfoConfig> {
  return {
    snackBarOverrides: {
      ...INFO_CONFIG_DEFAULTS.snackBarOverrides,
      ...info?.snackBarOverrides,
    },
  };
}
