import { ComponentType } from '@angular/cdk/overlay';
import { Inject, Injectable, Optional } from '@angular/core';

import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

import { LoggingService } from '@app/core/services';

import {
  FDT_SNACK_BAR_CONFIGURATION,
  PreparedCommonConfig,
  PreparedErrorConfig,
  PreparedInfoConfig,
  PreparedSuccessConfig,
  SnackBarConfig,
  SnackBarData,
  SnackBarMessageConfig,
  SnackBarMode,
  SnackBarPreparedConfig,
} from './models';
import { getDefaultPreparedSnackBarConfig } from './utils';

/*
 NOTE: this comment is not a jsdoc, it is note for devs
 * Adding Stack behavior is delayed for now.
 * Current plan is to add container component and handle stack behavior there,
 * but it is under consideration
 *
 * can be useful for implementing stack behaviour
 * we can refer to https://angular.io/guide/dynamic-component-loader
 *
 * Note: according to material guidelines it is recommended to avoid multiple
 * snackbars at time
 */

/**
 * Service for displaying snack bar notifications.
 *
 * add function provideSnackBarService to provide configuration for the snack-bar service
 */
@Injectable({
  providedIn: 'root',
  deps: [MatSnackBarModule],
})
export class SnackBarService {
  private classStyleOverrides = 'fdt-snackbar-style-overrides';
  private snackComponent!: ComponentType<unknown>;
  private commonConfig!: PreparedCommonConfig;
  private errorConfig!: PreparedErrorConfig;
  private successConfig!: PreparedSuccessConfig;
  private infoConfig!: PreparedInfoConfig;

  constructor(
    private loggingService: LoggingService,
    private matSnackBar: MatSnackBar,
    // default configuration is handled by provider function
    // but if no config was provided, create fallback config
    @Optional() @Inject(FDT_SNACK_BAR_CONFIGURATION) preparedConfig?: SnackBarPreparedConfig
  ) {
    const config = preparedConfig ?? getDefaultPreparedSnackBarConfig();
    this.initService(config);
  }

  showSuccess(config: SnackBarMessageConfig): void {
    const data: SnackBarData = {
      ...config,
      mode: SnackBarMode.Success,
      duration: this.commonConfig.snackBarConfig.duration,
    };
    this.matSnackBar.openFromComponent(this.snackComponent, {
      ...this.getMatSnackBarConfig(this.successConfig.snackBarOverrides),
      data,
    });
  }

  showError(config: SnackBarMessageConfig): void {
    const data: SnackBarData = {
      ...config,
      mode: SnackBarMode.Error,
    };
    this.matSnackBar.openFromComponent(this.snackComponent, {
      ...this.getMatSnackBarConfig(this.errorConfig.snackBarOverrides),
      data,
    });
  }

  // NOTE: I only skipped part from UI kit where we have 2 info snackbars with white and grey BG
  // Maybe even info snack bar should be handled in a different way
  showInfo(config: SnackBarMessageConfig): void {
    const data: SnackBarData = {
      ...config,
      mode: SnackBarMode.Info,
    };
    this.matSnackBar.openFromComponent(this.snackComponent, {
      ...this.getMatSnackBarConfig(this.infoConfig.snackBarOverrides),
      data,
    });
  }

  // Idea is to pass template reference to render, maybe custom component
  // here should be another config interface
  showCustom(config: SnackBarMessageConfig): void {
    this.loggingService.log(config, 'Show custom notification');
  }

  private initService(preparedConfig: SnackBarPreparedConfig): void {
    this.snackComponent = preparedConfig.common.component as ComponentType<unknown>;
    this.commonConfig = preparedConfig.common;
    this.commonConfig.snackBarConfig = {
      ...this.commonConfig.snackBarConfig,
      panelClass: [this.classStyleOverrides],
    };
    this.errorConfig = preparedConfig.error;
    this.successConfig = preparedConfig.success;
    this.infoConfig = preparedConfig.info;
  }

  private getMatSnackBarConfig(snackBarOverrides: SnackBarConfig): SnackBarConfig {
    return {
      ...this.commonConfig.snackBarConfig,
      ...snackBarOverrides,
      panelClass: [
        ...(this.commonConfig.snackBarConfig.panelClass as string[]),
        ...(snackBarOverrides.panelClass as string[]),
      ],
    };
  }
}
