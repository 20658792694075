import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { StorageKeys } from '@app/core/models';
import { ClientAccessorDto, ClientStoredData, ServiceEntityDto } from '@app/core/models/interfaces/client.interface';
import { StorageService } from '@app/core/services/storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  serviceEntityFallback: ServiceEntityDto | null =
    this.storageService.getSessionItem(StorageKeys.APP_ENTITY_SELECTION) ||
    this.storageService.getItem(StorageKeys.APP_ENTITY_SELECTION);
  activeClientFallback: ClientAccessorDto | null =
    this.storageService.getSessionItem(StorageKeys.APP_CLIENT_SELECTION) ||
    this.storageService.getItem(StorageKeys.APP_CLIENT_SELECTION);

  activeClient$ = new BehaviorSubject<ClientAccessorDto | null>(this.activeClientFallback);
  // Need to revisit this place to check the flow and when entity may be undefined and later make updates based on the actual flow.
  activeServiceEntity$ = new BehaviorSubject<ServiceEntityDto>(this.serviceEntityFallback!);

  constructor(
    private storageService: StorageService,
    private router: Router
  ) {}

  // NOTE: that client selection fetching process may become asynchronous operation in future
  // NOTE: Probably, revealing this method for public usage was a bad idea
  /*
    Method tries to get data from session storage, if it is empty, then local storage.
    It is current flow how we work in app with selection data. It is PM/QA criteria as far as I remember.
    Exception is entity switcher.
    There are some extra methods for this component.
    Also, currently we make migration for this data, I believe after some time related code will be removed
   */
  getClientSelection(): ClientAccessorDto | null {
    const sessionStorageSelection: ClientAccessorDto | null = this.storageService.getSessionItem(
      StorageKeys.APP_CLIENT_SELECTION
    );
    const localStorageSelection: ClientAccessorDto | null = this.storageService.getItem(
      StorageKeys.APP_CLIENT_SELECTION
    );

    // We make migration for data that exists while tab with app is opened
    // Edge case can be if app changes were deployed and user have opened tab and refreshed it
    sessionStorageSelection && this.ensureNotLegacyStorage(sessionStorageSelection, 'session');
    localStorageSelection && this.ensureNotLegacyStorage(localStorageSelection, 'local');

    return (
      this.storageService.getSessionItem<ClientAccessorDto>(StorageKeys.APP_CLIENT_SELECTION) ||
      this.storageService.getItem<ClientAccessorDto>(StorageKeys.APP_CLIENT_SELECTION)
    );
  }

  /*
    It is used to support entity switcher.
    I copied my comment from one of PRs to explain this method existence:

    Reason why we have default selection method is to fetch item exactly from local storage.
    We also have (at least it was previously) method to get entity from session or local storage by described rules (priority sessionStorage, if no, localStorage).
    So, 2 methods: getEntity and getDefaultEntity. DefaultSelection is used in form initialization in the entity switcher.
    By default, we set checkbox to true only when selection in form equals to user default selection from local storage,
    no matter what is user current selection in session storage.
    It was criteria from QA and PM as far as I remember
  */
  getClientSelectionFromLocalStorage(): ClientAccessorDto | null {
    const localStorageSelection: ClientAccessorDto | null = this.storageService.getItem(
      StorageKeys.APP_CLIENT_SELECTION
    );

    // Applying migration, temporary code
    localStorageSelection && this.ensureNotLegacyStorage(localStorageSelection, 'local');

    return this.storageService.getItem<ClientAccessorDto>(StorageKeys.APP_CLIENT_SELECTION);
  }

  getEntityDefaultSelection(): ServiceEntityDto | null {
    return (
      this.storageService.getSessionItem<ServiceEntityDto>(StorageKeys.APP_ENTITY_SELECTION) ||
      this.storageService.getItem<ServiceEntityDto>(StorageKeys.APP_ENTITY_SELECTION)
    );
  }

  /*
    Used in entity switcher. See comment to method getClientSelectionFromLocalStorage
   */
  getEntitySelectionFromLocalStorage(): ServiceEntityDto | null {
    return this.storageService.getItem<ServiceEntityDto>(StorageKeys.APP_ENTITY_SELECTION);
  }

  saveClientSelectionTemporary(selection: ClientAccessorDto | ClientStoredData): void {
    this.storageService.setSessionItem(StorageKeys.APP_CLIENT_SELECTION, selection);
  }

  saveActiveServiceEntityTemporary(entity: ServiceEntityDto): void {
    this.storageService.setSessionItem(StorageKeys.APP_ENTITY_SELECTION, entity);
  }

  saveActiveServiceEntity(entity: ServiceEntityDto): void {
    this.storageService.removeSessionItem(StorageKeys.APP_ENTITY_SELECTION);
    this.storageService.setItem(StorageKeys.APP_ENTITY_SELECTION, entity);
  }

  saveClientSelection(selection: ClientAccessorDto): void {
    this.storageService.removeSessionItem(StorageKeys.APP_CLIENT_SELECTION);
    this.storageService.setItem(StorageKeys.APP_CLIENT_SELECTION, selection);
  }

  forgetClientSelection(): void {
    this.storageService.removeSessionItem(StorageKeys.APP_CLIENT_SELECTION);
    this.storageService.removeItem(StorageKeys.APP_CLIENT_SELECTION);
    this.storageService.removeSessionItem(StorageKeys.APP_ENTITY_SELECTION);
    this.storageService.removeItem(StorageKeys.APP_ENTITY_SELECTION);
  }

  forgetTemporarySelection(): void {
    this.storageService.removeSessionItem(StorageKeys.APP_CLIENT_SELECTION);
    this.storageService.removeSessionItem(StorageKeys.APP_ENTITY_SELECTION);
  }

  emitValues(client: ClientAccessorDto, entity: ServiceEntityDto): void {
    this.activeClient$.next(client);
    this.activeServiceEntity$.next(entity);
  }

  start(clients: ClientAccessorDto[]): void {
    const selection = this.getClientSelection();
    // client with active status is selected as priority
    const newlySelectedClient: ClientAccessorDto = clients.find((c) => c.status === 'active') || clients[0];

    if (!selection) {
      this.saveClientSelection(newlySelectedClient);
      this.saveActiveServiceEntity(newlySelectedClient.serviceEntities[0]);
      this.emitValues(newlySelectedClient, newlySelectedClient.serviceEntities[0]);
      this.onHasOneInactiveClient(clients);
      return;
    }

    const foundClient = clients.find((c) => c.id === selection.id);

    if (!foundClient) {
      // Optionally add something to notify user about error
      this.emitValues(newlySelectedClient, newlySelectedClient.serviceEntities[0]);
      this.forgetClientSelection();
      return;
    }

    const foundEntity = this.getEntityDefaultSelection();

    if (foundEntity) {
      this.emitValues(foundClient, foundEntity);
    } else {
      this.saveClientSelection(foundClient);
      this.saveActiveServiceEntity(foundClient.serviceEntities[0]);
      this.emitValues(foundClient, foundClient.serviceEntities[0]);
    }

    this.onHasOneInactiveClient(clients);
  }

  onHasOneInactiveClient(clients: ClientAccessorDto[]): void {
    const hasOnlyOneInactiveClient: boolean = clients.length === 1 && clients[0].status !== 'active';

    if (hasOnlyOneInactiveClient) {
      this.router.navigate(['inactive-client']);
    }
  }

  // Code makes migration of data
  private ensureNotLegacyStorage(selection: ClientAccessorDto, storage: 'session' | 'local'): void {
    if (
      selection &&
      'serviceEntityId' in selection &&
      'clientId' in selection &&
      'clientName' in selection &&
      'clientType' in selection &&
      'clientStatus' in selection
    ) {
      const migrateSelection = {
        id: selection.clientId,
        name: selection.clientName,
        serviceEntities: [
          {
            id: selection.serviceEntityId,
          },
        ],
        type: selection.clientType,
        status: selection.clientStatus,
      } as ClientAccessorDto;

      if (storage === 'session') {
        this.storageService.setSessionItem(StorageKeys.APP_CLIENT_SELECTION, migrateSelection);
      } else {
        this.storageService.setItem(StorageKeys.APP_CLIENT_SELECTION, migrateSelection);
      }
    }
  }
}
