import { Directive, HostListener, Input, Optional } from '@angular/core';

import { ButtonComponent } from '@1stdigital/ng-sdk/button';
import { AnalyticsProps } from '@app/core/models';
import { AnalyticsEvents } from '@app/core/models/analytics-events.type';
import { AnalyticsService } from '@app/core/services';

@Directive({
  selector: '[appTrackEvent]',
  standalone: true,
})
export class TrackEventDirective {
  @Input() appTrackEvent?: AnalyticsEvents;
  @Input() props?: AnalyticsProps;

  @HostListener('click', ['$event'])
  onHistClick(): void {
    if (this.buttonComponent?.disabled || !this.appTrackEvent) {
      return;
    }

    this.analyticsService.track(this.appTrackEvent, this.props);
  }
  constructor(
    private analyticsService: AnalyticsService,
    @Optional() private buttonComponent: ButtonComponent
  ) {}
}
