import { timer } from 'rxjs';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

const MAX_RETRY = 7;

/**
 * @param delayMs - The initial delay in milliseconds.
 * @returns A function that applies the retry strategy to an observable.
 */
export function delayedRetry(delayMs = 1000): (src: Observable<unknown>) => Observable<unknown> {
  return (src: Observable<unknown>) =>
    src.pipe(
      retry({
        count: MAX_RETRY,
        delay: (error, retryCount) => {
          const exponentialDelay = delayMs * Math.pow(2, retryCount - 1);

          return timer(exponentialDelay);
        },
      }),
      catchError(() => throwError(() => new Error('Failed WS connection')))
    );
}
