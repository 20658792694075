import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  SecurityContext,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { IconName } from '@1stdigital/ng-sdk/core';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { ProgressSpinnerComponent } from '@app/shared/rounded-progress-container/progress-spinner.component';

import { CLICKABLE_CLASS, CustomSnackBarComponent, SnackBarData, SnackBarMode } from '../models';
import { getIdFromClickableClassString } from '../utils';

@Component({
  selector: 'app-snack-bar',
  standalone: true,
  imports: [FdtIconModule, FdtButtonModule, NgClass, ProgressSpinnerComponent, NgTemplateOutlet],
  templateUrl: './app-snack-bar.component.html',
  styleUrl: './app-snack-bar.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSnackBarComponent implements OnInit, AfterViewInit, CustomSnackBarComponent {
  message: string | null = '';
  caption: string | null = '';
  col1Icon!: IconName;
  snackBarModeClasses = '';
  isSuggestedAction = false;
  suggestedActionTitle = '';

  constructor(
    private sanitizer: DomSanitizer,
    public snackBarRef: MatSnackBarRef<AppSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData
  ) {}

  ngOnInit(): void {
    this.compileMessage();
    this.checkForSuggestedAction();
    this.setSnackBarAppearanceSettings();
  }

  ngAfterViewInit(): void {
    if (this.data.inMessageCallbacks) {
      this.bindCallbacks(this.data.inMessageCallbacks);
    }
  }

  closeSnack(): void {
    this.snackBarRef.dismiss();
  }

  callSuggestedAction(): void {
    const callback = this.data.undoCallback || this.data.suggestedAction?.callback;

    if (callback) {
      callback();
    }

    this.closeSnack();
  }

  private checkForSuggestedAction(): void {
    if (this.data.undoCallback) {
      this.isSuggestedAction = true;
      this.suggestedActionTitle = 'Undo';
    } else if (this.data.suggestedAction) {
      this.isSuggestedAction = true;
      this.suggestedActionTitle = this.data.suggestedAction.actionName;
    }
  }

  private compileMessage(): void {
    this.message = this.sanitizer.sanitize(SecurityContext.HTML, this.data?.message ?? '');
    this.caption = this.sanitizer.sanitize(SecurityContext.HTML, this.data?.caption ?? '');
  }

  private bindCallbacks(callbacks: { [id: string]: () => void }): void {
    /*
     * Clickable elements are inside innerHtml.
     * I use special class to retrieve elements that are clickable.
     */
    const clickables = document.getElementsByClassName(CLICKABLE_CLASS);

    for (const item of Array.from(clickables)) {
      /*
       * I use sanitizer, it removes id and data attributes from my compiled string.
       * that's why I keep id to bind callback to the span as an extra class of html element
       *
       * sanitizer bypass is considered as unsafe by Sonar, so I use a variant with class instead
       */
      const bindId = getIdFromClickableClassString(item.classList.value);

      if (bindId && callbacks[bindId]) {
        (item as HTMLElement).onclick = callbacks[bindId];
      }
    }
  }

  private setSnackBarAppearanceSettings(): void {
    const classMapping = {
      [SnackBarMode.Success]: 'fdt-snack-bar-success',
      [SnackBarMode.Error]: 'fdt-snack-bar-error',
      [SnackBarMode.Warning]: 'fdt-snack-bar-warning',
      [SnackBarMode.Info]: 'fdt-snack-bar-info',
    };
    this.snackBarModeClasses = classMapping[this.data.mode] || '';
    this.col1Icon = this.getCol1Icon();
  }

  private getCol1Icon(): IconName {
    switch (this.data.mode) {
      case SnackBarMode.Error:
        return 'closeLine';
      case SnackBarMode.Success:
        return 'checkLine';
      case SnackBarMode.Info:
        return 'informationLine';
      default:
        return 'informationLine';
    }
  }
}
