import { Injectable } from '@angular/core';

import { StorageKey } from '../models';

/**
 *
 * We are missing some things here;
 * The keys of storage are not CONSISTENT, some are upper case, some lower case;
 * At same time, we are missing one very important thing -> prefixing keys with some unique value
 *    because localStorage is shared across the entire domain.
 * Also its not TYPE SAFE!!!;
 *
 * Saying all this, this StorageService is not good enough for our needs and marked as:
 * @deprecated
 *
 * We should create a new service that will be more type safe and will have a prefix for keys.
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  setItem<T>(key: StorageKey, data: T): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getItem<T>(key: StorageKey): T | null {
    const item = localStorage.getItem(key);

    return this.parseItem(item);
  }

  removeItem(key: StorageKey): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  setSessionItem<T>(key: StorageKey, data: T): void {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  getSessionItem<T>(key: StorageKey): T | null {
    const item = sessionStorage.getItem(key);

    return this.parseItem(item);
  }

  removeSessionItem(key: StorageKey): void {
    sessionStorage.removeItem(key);
  }

  private parseItem<T>(item: string | null): T | null {
    if (!item) {
      return null;
    }

    return JSON.parse(item);
  }
}
