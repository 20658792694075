<mat-accordion multi class="stepper" mat-elevation="0">
  @for (step of steps; track step; let i = $index, isLast = $last, isFirst = $first) {
    <!--
      mat-elevation equal to "0"` didnt work, so we added the class directly to remove the box-shadow
      hideToggle - removes default arrows
      disabled - first step should be enabled, and each step should be disabled if prevoius step isnt done, or we might have previous step done, but 1st step in edit mode, so we check if we have editStep and is editStep === selected
      expanded - always expand the selected step, or if the step isDone we want to display property grid -->
    <mat-expansion-panel
      class="mat-elevation-z"
      hideToggle
      [disabled]="
        step.disabled ||
        (!isFirst && !steps.get(i - 1)?.isDone && selectedIndex !== i) ||
        (editStep && editStep !== step)
      "
      [expanded]="selectedIndex === i || (step.expanded && !editStep)"
      #expansionPanel
    >
      <mat-expansion-panel-header class="stepper__header" [class.stepper__header--expanded]="expansionPanel.expanded">
        <div class="stepper__header__container">
          <div class="stepper__step">
            <div class="stepper__step__icon">{{ i + 1 }}</div>
            <div class="subtitle-2">{{ step.header }}</div>
          </div>
          <!-- only if the step is control, othertwise dosnt make sense to edit if we dont have control, and if the step statre is DONE, if in edit state dosnt make sense to have the button -->
          @if (step.stepControl && step.isDone && !editStep && step.editable) {
            <button fdt-ghost-button (click)="onEditStep($event, step)">
              <fdt-icon name="editLine"></fdt-icon> Edit
            </button>
          }
        </div>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="step.content"></ng-container>
    </mat-expansion-panel>
  }
  @if (steps.last === selected && steps.length > 1) {
    <ng-container [ngTemplateOutlet]="submitTemp"></ng-container>
  }
</mat-accordion>

<!-- For now this logic lives here, in future if we encounter different cases for Submit, we might need to handle this outside -->
<ng-template #submitTemp>
  <div class="stepper__footer">
    @if (disclaimer) {
      <p class="stepper__footer__disclaimer">
        By clicking ‘Submit’ you agree to our
        <button class="stepper__footer__disclaimer__link" (click)="onDisclaimerClick()">
          {{ disclaimer === 'transfer' ? 'disclaimer' : 'end user agreement' }}
        </button>
      </p>
    }
    <button
      fdt-button
      color="primary"
      size="large"
      [loading]="steps.last.isBusy"
      [disabled]="steps.last.isBusy || steps.last.stepDisabled || submitDisabled"
      (click)="onSubmit()"
    >
      Submit
    </button>
  </div>
</ng-template>
