import { Amount, AssetClasses } from '@1stdigital/ng-sdk/core';

export interface AssetData {
  amount: Amount;
  assetClass: AssetClasses;
}

export interface PortfolioSummaryDataDto {
  total: number;
  summaries: AssetData[];
}

export interface PortfolioSummaryData extends PortfolioSummaryDataDto {
  amountSum: number;
}

export interface ChartAssetConfig {
  class: AssetClasses;
  colors: string;
  title: string;
}

export interface ColorMapping {
  [k: string]: {
    /**
     * @deprecated
     */
    color?: string;
    colorVariable: ColorVariables;
  };
}

export enum ColorVariables {
  Cash = '--icon-cash',
  Bond = '--icon-bonds',
  Cryptocurrency = '--icon-crypto',
  NFT = '--icon-nft',
  Commodity = '--icon-commodities',
  PrivateEquity = '--icon-private-equity',
  RealEstate = '--icon-real-estate',
  Stock = '--icon-stocks',
  EmptyBarSpace = '--container-secondary',
  ShadowedChartSegment = '--container-tertiary',
  TickFontColor = '--text-secondary-01',
  GridColor = '--border-primary',

  InitiatedContainer = '--container-status-initiated',
  InReviewContainer = '--container-status-in-review',
  CompletedContainer = '--container-status-completed',
  PendingContainer = '--container-status-pending',
  RejectedContainer = '--container-status-rejected',
  CancelledContainer = '--container-status-cancelled',
  FailedContainer = '--container-status-failed',

  Initiated = '--text-status-initiated',
  InReview = '--text-status-in-review',
  Completed = '--text-status-completed',
  Pending = '--text-status-pending',
  Rejected = '--text-status-rejected',
  Cancelled = '--text-status-cancelled',
  Failed = '--text-status-failed',
}
