import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

import { ButtonComponent } from '@1stdigital/ng-sdk/button';
import { IconComponent } from '@1stdigital/ng-sdk/icon';

@Component({
  selector: 'app-cards-disclaimer-modal',
  standalone: true,
  imports: [ButtonComponent, IconComponent],
  templateUrl: './cards-disclaimer-modal.component.html',
  styleUrl: './cards-disclaimer-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardsDisclaimerModalComponent {
  constructor(private readonly dialogRef: MatDialogRef<CardsDisclaimerModalComponent, boolean>) {}

  onCloseModal(): void {
    this.dialogRef.close(false);
  }
}
