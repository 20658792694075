import { Injectable } from '@angular/core';

import { EnvironmentLoaderService } from '@app/core/services/environment-loader.service';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private enabled: null | boolean = null;

  constructor(private environmentLoaderService: EnvironmentLoaderService) {}

  log(message?: unknown, ...optionalParams: unknown[]): void {
    if (this.isEnabled()) {
      console.log(message, ...optionalParams);
    }
  }

  error(message: string | Error, ...optionalParams: unknown[]): void {
    if (this.isEnabled()) {
      console.error(message, optionalParams);
    }
  }

  private isEnabled(): boolean {
    if (this.enabled === null) {
      // enabled if not production;
      this.enabled = !this.environmentLoaderService.isProduction();
    }

    return this.enabled;
  }
}
