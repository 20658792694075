import { ConnectionPositionPair } from '@angular/cdk/overlay';

import { TourContentArrowDirection } from '@app/tour/models/tour-content-arrow-direction.type';

/**
 * Determines the arrow direction based on the connection positions of the origin and overlay elements.
 *
 * @param positionPair - An object representing the connection positions between the origin and overlay.
 * @returns The direction of the arrow: 'left', 'right', 'top', or 'bottom', or `null` if not applicable.
 */
export function getArrowDirection(positionPair: ConnectionPositionPair): TourContentArrowDirection | null {
  const { originX, originY, overlayX, overlayY } = positionPair;

  if (originX === 'end' && overlayX === 'start') {
    return 'left';
  } else if (originX === 'start' && overlayX === 'end') {
    return 'right';
  }

  if (originY === 'bottom' && overlayY === 'top') {
    return 'top';
  } else if (originY === 'top' && overlayY === 'bottom') {
    return 'bottom';
  }

  return null;
}
