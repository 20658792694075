import { Amount } from '@1stdigital/ng-sdk/core';
import { StatusDescriptor } from '@1stdigital/ng-sdk/shared';

import { LegalEntity } from '../legal-entity.type';

export interface ClientAccessorDto {
  id: number;
  name: string;
  serviceEntities: ServiceEntityDto[];
  type: LegalEntity;
  status: ClientStatus;
}

export interface ServiceEntityDto {
  id: number;
  name: string;
  packageId?: number;
  totalBalance: Amount;
  isActive: boolean;
}

/**
 * Composed data that is stored during work with entity switcher.
 * Currently used in InactiveClient component and EntitySwitcher
 */
export interface ClientStoredData {
  clientId: number;
  clientName: string;
  clientType: LegalEntity;
  serviceEntityId: number;
  clientStatus: ClientStatus;
}

export type ClientStatus = 'none' | 'active' | 'dormant' | 'suspended' | 'closed';

export const accountStatusConfig: StatusDescriptor<ClientStatus>[] = [
  {
    icon: 'spam3Fill',
    color: 'accent',
    label: 'Suspended',
    value: 'suspended',
  },
  {
    icon: 'closeCircleFill',
    color: 'accent',
    label: 'Closed',
    value: 'closed',
  },
  {
    icon: 'moonFill',
    color: 'tertiary',
    label: 'Dormant',
    value: 'dormant',
  },
];
