export interface BigNumberLookupItem {
  value: number;
  symbol: string;
}

export interface ShorthandNumberOptions {
  digits: number;
  // Option is omitted by default
  shorthandGain?: ShorthandGain;
}

export interface ShorthandResult {
  isChanged: boolean;
  value: string;
}

/**
 * Shortened notation of 10 pow x,
 * so 1e3 is 10**3 => 1,000 (1 thousand)
 * 1e6 => 1,000,000, (1 million) etc
 */
export type ShorthandGain = null | 1 | 1e3 | 1e6 | 1e9 | 1e12 | 1e15 | 1e18 | 1e21 | 1e24 | 1e27;

export function findClosestBigNumber(num: number): BigNumberLookupItem | undefined {
  const lookup: BigNumberLookupItem[] = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'Qa' },
    { value: 1e18, symbol: 'Qi' },
    { value: 1e21, symbol: 'Sx' },
    { value: 1e24, symbol: 'Sp' },
    { value: 1e27, symbol: 'Oc' },
  ];

  return lookup
    .slice()
    .reverse()
    .find((item) => Math.abs(num) >= item.value);
}

/*
  In case if we need something similar to shorthand numbers, not only amounts,
  I would keep call function for amounts and maybe moved
  inner implementation to separate function.
  Possibly, we could have different settings to display amounts.
 */
/**
 * Calculates the shorthand amount based on the provided options.
 * Values less than 1 are returned as string with value unchanged
 *
 * @param {number} num - The number to be converted into shorthand.
 * @param {ShorthandNumberOptions} options - The options for shorthand conversion.
 * @param {number} options.digits - The number of decimal digits to keep in the shorthand amount.
 * @param {string} options.mode - The mode of the shorthand amount ('long' or 'short').
 * @param {number} options.shorthandGain - The minimum value for the number to be converted into shorthand.
 *
 * @returns {ShorthandResult} - An object containing the result of the shorthand conversion.
 * @property {boolean} isChanged - Indicates whether the shorthand conversion was applied (true) or not (false).
 * @property {string} value - The shorthanded amount.
 */
export function shorthandAmount(num: number, { digits, shorthandGain }: ShorthandNumberOptions): ShorthandResult {
  const result = {
    isChanged: false,
    value: `${num}`,
  };

  if (!shorthandGain) {
    return result;
  }

  const item = findClosestBigNumber(num);

  if (!item || item.value < shorthandGain) {
    return result;
  }

  result.isChanged = true;
  const regexp = '/.0+$|(?<=.[0-9]*[1-9])0+$/';
  const justNumber = (num / item.value).toFixed(digits).replace(regexp, '');

  result.value = justNumber.concat(`${item.symbol}`);

  return result;
}
