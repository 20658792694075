<div class="fdt-modal fdt-disclaimer">
  <div class="fdt-modal__header">
    <div class="fdt-modal__title">Disclaimer</div>
    <button fdt-ghost-button class="fdt-modal__delete-icon" (click)="onCloseModal()">
      <fdt-icon name="closeLine" size="24"></fdt-icon>
    </button>
  </div>

  <div class="fdt-modal__body fdt-disclaimer__body">
    <h3 class="fdt-disclaimer__title">General Disclaimer</h3>

    <ul class="fdt-disclaimer__list">
      <li>
        The Client shall read and review the information carefully before initiating any instruction. The Client
        confirms the accuracy and completeness of the instruction information provided and First Digital Trust Limited
        (“FDT”) is entitled to rely on the Client’s instruction to process the transaction.
      </li>
      <li>
        The Client shall be responsible for selecting and inputting the correct information in the instruction page,
        including but not limited to the currency, outgoing account, incoming account, and amount. FDT is not
        responsible for any errors or omissions made by the Client.
      </li>
      <li>
        FDT is not responsible for any fees charged by any financial institution involved during the process of
        completing the Client’s instruction. Before transferring funds to or from a designated account, the Client shall
        be responsible for ascertaining with its financial institution if they or their correspondent bank charge for
        that service.
      </li>
      <li>
        By submitting instruction, the Client confirms that all the information/documents it has provided (including but
        not limited to any information/documents provided in connection with compliance of any laws and regulations in
        relation to Anti-Money Laundering and Counter-Terrorism Financing) are true, accurate, complete, and not
        misleading in any respect.
      </li>
    </ul>
  </div>
</div>
