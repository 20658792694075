import { Injectable } from '@angular/core';

import { BaseApiService, DropdownItem } from '@1stdigital/ng-sdk/core';
import { Observable } from 'rxjs';

import { DocumentTypeGroup } from '../models/document-type-group.type';

@Injectable({
  providedIn: 'root',
})
export class FileStorageApiService extends BaseApiService {
  getDocumentTypes(): Observable<Record<DocumentTypeGroup, DropdownItem[]>> {
    return this.get('file-storage/document-types');
  }

  getDocument(key: string): Observable<{ body: string }> {
    return this.get(`file-storage/files?key=${key}`, {}, { responseType: 'blob' });
  }
}
