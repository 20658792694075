import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { FdtDateTimePipe } from '@1stdigital/ng-sdk/shared';
import {
  InboxMessage,
  InboxMessageIcon,
  InboxMessageType,
  NotificationType,
} from '@app/core/models/interfaces/inbox.interface';

import { ImageDirective } from '../../shared/directives/image.directive';
import { InboxInstructionReviewTextComponent } from './inbox-instruction-review-text/inbox-instruction-review-text.component';
import { InboxInstructionUpdateTextComponent } from './inbox-instruction-update-text/inbox-instruction-update-text.component';

type MessageCategory = 'instruction';

@Component({
  selector: 'app-inbox-message',
  standalone: true,
  imports: [
    FdtIconModule,
    FdtDateTimePipe,
    FdtButtonModule,
    ImageDirective,
    InboxInstructionReviewTextComponent,
    InboxInstructionUpdateTextComponent,
  ],
  templateUrl: './inbox-message.component.html',
  styleUrl: './inbox-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideRight', [
      state('read', style({ transform: 'translateX(110%)' })),
      transition('* => read', [
        animate(
          '700ms',
          keyframes([
            style({ transform: 'translateX(110%)', offset: 0.3 }),
            style({ transform: 'translateX(110%)', offset: 1 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class InboxMessageComponent implements OnChanges {
  @Output() markAsRead = new EventEmitter<InboxMessage>();
  @Output() clearRead = new EventEmitter<InboxMessage>();
  @Output() instructionReview = new EventEmitter<InboxMessage>();
  @Input() message!: InboxMessage;

  iconMap: Record<InboxMessageType, InboxMessageIcon> = {
    announcements: 'questionLine',
    instructionReview: 'noteEdit',
    instructionUpdate: 'fileInfoLine',
  };

  messageCategory: MessageCategory | null = null;

  messageAnimationStateSignal = signal<null | 'read'>(null);

  ngOnChanges(changes: SimpleChanges): void {
    const message = changes['message'];

    if (this.message) {
      this.messageCategory = this.getMessageCategory(this.message.data.requestData.eventId);
    }

    if (message.firstChange) {
      return;
    }

    if (message.previousValue && !message.previousValue.read && this.message.read) {
      this.messageAnimationStateSignal.set('read');
    }
  }

  onMarkAsRead(message: InboxMessage): void {
    this.markAsRead.emit(message);
  }

  onAnimationComplete(message: InboxMessage): void {
    if (!this.messageAnimationStateSignal()) {
      return;
    }

    this.clearRead.emit(message);
  }

  private getMessageCategory(eventId: NotificationType): MessageCategory | null {
    if (eventId.startsWith('ams.instruction')) {
      return 'instruction';
    }

    return null;
  }
}
