import { Injectable } from '@angular/core';

import { IconName } from '@1stdigital/ng-sdk/core';
import { ActivityStatus } from '@app/activity/shared/models/interfaces/activity-status.enum';
import { ColorMapping, ColorVariables } from '@app/dashboard/shared/models/portfolio-overview.interface';

@Injectable({
  providedIn: 'root',
})
export class StatusUtilsService {
  private statusMapping: { [k: string]: ColorMapping } = {
    [ActivityStatus.Initiated]: {
      bg: { colorVariable: ColorVariables.InitiatedContainer, color: '' },
      text: { colorVariable: ColorVariables.Initiated, color: '' },
    },
    [ActivityStatus.Pending]: {
      bg: { colorVariable: ColorVariables.PendingContainer, color: '' },
      text: { colorVariable: ColorVariables.Pending, color: '' },
    },
    [ActivityStatus.InReview]: {
      bg: { colorVariable: ColorVariables.InReviewContainer, color: '' },
      text: { colorVariable: ColorVariables.InReview, color: '' },
    },
    [ActivityStatus.Rejected]: {
      bg: { colorVariable: ColorVariables.RejectedContainer, color: '' },
      text: { colorVariable: ColorVariables.Rejected, color: '' },
    },
    [ActivityStatus.Cancelled]: {
      bg: { colorVariable: ColorVariables.CancelledContainer, color: '' },
      text: { colorVariable: ColorVariables.Cancelled, color: '' },
    },
    [ActivityStatus.Failed]: {
      bg: { colorVariable: ColorVariables.FailedContainer, color: '' },
      text: { colorVariable: ColorVariables.Failed, color: '' },
    },
    [ActivityStatus.Completed]: {
      bg: { colorVariable: ColorVariables.CompletedContainer, color: '' },
      text: { colorVariable: ColorVariables.Completed, color: '' },
    },
  };

  private statusLabelMapping: { [k: string]: string } = {
    [ActivityStatus.Initiated]: 'Initiated',
    [ActivityStatus.Pending]: 'Pending',
    [ActivityStatus.InReview]: 'In Review',
    [ActivityStatus.Rejected]: 'Rejected',
    [ActivityStatus.Cancelled]: 'Cancelled',
    [ActivityStatus.Failed]: 'Failed',
    [ActivityStatus.Completed]: 'Completed',
  };

  private statusIconMapping: { [k: string]: IconName } = {
    [ActivityStatus.Initiated]: 'timeLine',
    [ActivityStatus.Pending]: 'timeLine',
    [ActivityStatus.InReview]: 'eyeLine',
    [ActivityStatus.Rejected]: 'closeCircleLine',
    [ActivityStatus.Cancelled]: 'closeCircleLine',
    [ActivityStatus.Failed]: 'closeCircleLine',
    [ActivityStatus.Completed]: 'checkboxCircleLine',
  };

  getStatusLabel(key: ActivityStatus): string {
    return this.statusLabelMapping[key] || '';
  }

  getStatusIcon(key: ActivityStatus): IconName {
    return this.statusIconMapping[key] || '';
  }

  getStatusColor(status: ActivityStatus): string | undefined {
    const colorVariable = this.statusMapping[`${status}`]['text'].colorVariable;

    return this.getColorFromCss(colorVariable);
  }

  getStatusBgColor(status: ActivityStatus): string | undefined {
    const colorVariable = this.statusMapping[`${status}`]['bg'].colorVariable;

    return this.getColorFromCss(colorVariable);
  }

  private getColorFromCss(colorVariable: string): string {
    return getComputedStyle(document.documentElement).getPropertyValue(colorVariable);
  }
}
