<cdk-virtual-scroll-viewport
  itemSize="154"
  class="inbox-list__scroll-viewport"
  [minBufferPx]="500"
  [maxBufferPx]="1000"
>
  <ng-container *cdkVirtualFor="let message of messagesVM.data">
    <app-inbox-message
      class="inbox-list__messages"
      [message]="message"
      (markAsRead)="markAsRead.emit($event)"
      (clearRead)="clearRead.emit($event)"
      (instructionReview)="instructionReview.emit($event)"
    ></app-inbox-message>
  </ng-container>
  @if (messagesVM.isBusy) {
    <div class="inbox-list__sekeleton-group">
      <app-inbox-skeleton></app-inbox-skeleton>
      <app-inbox-skeleton></app-inbox-skeleton>
      <app-inbox-skeleton></app-inbox-skeleton>
    </div>
  }
</cdk-virtual-scroll-viewport>
