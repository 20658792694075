import { Injectable } from '@angular/core';

import { BaseApiService } from '@1stdigital/ng-sdk/core';
import { Observable } from 'rxjs';

import { NotificationDto } from '../models';

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService extends BaseApiService {
  getLatestNotification(): Observable<NotificationDto> {
    return this.get('notification/latest');
  }
}
