import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { InboxMessageRequestData, NotificationType } from '@app/core/models/interfaces/inbox.interface';

@Component({
  selector: 'app-inbox-instruction-update-text',
  standalone: true,
  imports: [],
  templateUrl: './inbox-instruction-update-text.component.html',
  styleUrl: './inbox-instruction-update-text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxInstructionUpdateTextComponent {
  @Input({ required: true }) eventId!: NotificationType;
  @Input({ required: true }) requestData!: InboxMessageRequestData;
}
