import { Injectable } from '@angular/core';

import { Asset, BaseApiService } from '@1stdigital/ng-sdk/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService extends BaseApiService {
  getAssets(): Observable<Asset[]> {
    return this.get('common/assets');
  }
}
