import { ErrorHandler, Injectable } from '@angular/core';

import { EnvironmentLoaderService, LoggingService } from '@app/core/services';

/**
 * Raygun error handler
 * Sends errors to Raygun
 *
 * This should be extended to include application version!
 *
 * @see https://raygun.com/
 */
@Injectable()
export class RaygunErrorHandler implements ErrorHandler {
  private rg4jsPromise = import('raygun4js').then((m) => m.default);
  private isEnabled: null | boolean = null;

  constructor(
    private envService: EnvironmentLoaderService,
    private loggingService: LoggingService
  ) {}

  handleError(e: Error): void {
    this.beforeHandleError();

    if (this.isEnabled) {
      this.rg4jsPromise.then((rg4js) => {
        rg4js('send', {
          error: e,
        });
      });
    } else {
      // so error is not `eaten` by RaygunErrorHandler
      this.loggingService.error(e);
    }
  }

  private beforeHandleError(): void {
    if (this.isEnabled === null) {
      // raygun not initialized
      this.isEnabled = !!this.envService.environment['raygunApiKey'];

      if (this.isEnabled) {
        this.rg4jsPromise.then((rg4js) => {
          rg4js('apiKey', this.envService.environment['raygunApiKey'] as string);
          rg4js('enableCrashReporting', true);
        });
      }
    }
  }
}
