@if (isVisibleSignal()) {
  <div class="nps-survey-popup" [ngStyle]="{ display: isTemporaryHidden() ? 'none' : 'block' }">
    <div class="nps-survey-popup__header">
      <div class="nps-survey-popup__header-title">New: NPS Survey</div>
      <button fdt-fab-button color="tertiary" size="large" (click)="onClosePopup()">
        <fdt-icon name="closeLine" size="24"></fdt-icon>
      </button>
    </div>
    <div class="nps-survey-popup__body">
      <div>Our quarterly NPS survey is ready for you!</div>
      <div>
        We value your feedback on our products & services, this helps us to improve our offerings and keep us up to date
        on what's important to you!
      </div>
    </div>
    <div class="nps-survey-popup__footer">
      <button fdt-button color="primary" size="large" [fullSize]="true" (click)="onOpenNpsSurvey()">
        Let us know your feedback
      </button>
    </div>
  </div>
}
