<div class="fdt-snack-bar">
  <div class="fdt-snack-bar__container" [ngClass]="snackBarModeClasses">
    <div class="fdt-snack-bar__col-1">
      <div class="fdt-snack-bar__icon-fill">
        <fdt-icon size="20" [name]="col1Icon"></fdt-icon>
      </div>
    </div>
    <div class="fdt-snack-bar__col-2">
      @if (message) {
        <span class="fdt-snack-bar__message" [innerHTML]="message"></span>
      }
      @if (caption) {
        <span class="fdt-snack-bar__caption" [innerHTML]="caption"></span>
      }
    </div>
    <div class="fdt-snack-bar__col-3" [ngClass]="{ 'fdt-snack-bar__col-3--centered': !!data.duration }">
      <div class="fdt-snack-bar__col-3-actions">
        @if (isSuggestedAction) {
          <button fdt-ghost-button class="fdt-snack-bar__action-btn" (click)="callSuggestedAction()">
            {{ suggestedActionTitle }}
          </button>
        }
        @if (data.duration) {
          <app-progress-spinner [diameter]="30" [countdown]="data.duration" (progressComplete)="closeSnack()">
            <ng-container *ngTemplateOutlet="closeBtnTmpl"></ng-container>
          </app-progress-spinner>
        } @else {
          <ng-container *ngTemplateOutlet="closeBtnTmpl"></ng-container>
        }
      </div>
    </div>
  </div>
</div>

<ng-template #closeBtnTmpl>
  <button class="fdt-snack-bar__close-btn" (click)="closeSnack()">
    <fdt-icon size="24" [name]="'closeLine'"></fdt-icon>
  </button>
</ng-template>
