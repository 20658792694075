import { inject } from '@angular/core';

import { AppUserService } from '@1stdigital/ng-sdk/core';
import { CommonApiService } from '@app/core/services/common-api.service';
import { LayoutApiService } from '@app/layout/layout-api.service';
import { forkJoin, Observable, switchMap, tap } from 'rxjs';

import { ClientService } from './services';
import { ClientsAccessorApiService } from './services/clients-accessor-api.service';
import { UserInfoApiService } from './services/user-info-api.service';

export const assetsAndEntityResolver = (): Observable<unknown> => {
  const clientContextService = inject(ClientService);
  const layoutService = inject(LayoutApiService);
  const commonApiService = inject(CommonApiService);
  const clientsAccessorApiService = inject(ClientsAccessorApiService);
  const userInfoApiService = inject(UserInfoApiService);
  const appUserService = inject(AppUserService);

  return layoutService.getClientAccessorList().pipe(
    tap((clients) => clientContextService.start(clients)),
    switchMap(() =>
      forkJoin([
        commonApiService.getAssets(),
        clientsAccessorApiService.getAssetMasterIds(),
        userInfoApiService.getUserInfoResponse(),
      ])
    ),
    tap(([assets, assetMasterIds, userInfo]) => {
      appUserService.setAssets(assets);
      appUserService.setAppAvailableAssets(assetMasterIds);
      userInfoApiService.setUserInfo(userInfo);
    })
  );
};
