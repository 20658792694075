import { ModuleSettingsBase } from '@app/core/services';
import { TourName } from '@app/tour/models/tour.interface';

export class LayoutModuleSettings extends ModuleSettingsBase<LayoutModuleSettings> {
  /**
   * Tour guide settings.
   */
  tourGuide: { [key in TourName]?: TourSettings } = {};

  /**
   * Indicates if the NPS survey is completed.
   */
  npsSurvey: boolean | null = null;

  /**
   * Last session when the NPS survey was shown.
   * Time value in milliseconds since midnight, January 1, 1970 UTC.
   */
  lastNpsSession?: number;

  /**
   * Number of times the NPS survey was skipped.
   */
  npsSessionSkiped = 0;

  constructor(instance?: Partial<LayoutModuleSettings>) {
    super(instance);

    if (instance?.tourGuide) {
      this.tourGuide = instance.tourGuide;
    }

    if (instance?.npsSurvey !== undefined) {
      this.npsSurvey = instance.npsSurvey;
    }

    if (instance?.lastNpsSession !== undefined) {
      this.lastNpsSession = instance.lastNpsSession;
    }

    if (instance?.npsSessionSkiped !== undefined) {
      this.npsSessionSkiped = instance.npsSessionSkiped;
    }
  }
}

/**
 * Tour Guide settings.
 * Used to store the state of the tour guide for a specific tour.
 * @see {@link https://1stdigital.atlassian.net/browse/STX-3348} story of very first tour guide implementation.
 */
export interface TourSettings {
  /**
   * Indicates if the tour is finished.
   */
  finish: boolean;

  /**
   * Number of times the tour was skipped.
   */
  skip: number;

  /**
   * Last session id when the tour was shown.
   * Time value in milliseconds since midnight, January 1, 1970 UTC.
   */
  lastSessionId?: number;
}
