<div class="fdt-modal cards-disclaimer">
  <div class="fdt-modal__header">
    <div class="fdt-modal__title">Card Services Agreement</div>
    <button fdt-ghost-button class="fdt-modal__delete-icon" (click)="onCloseModal()">
      <fdt-icon name="closeLine" size="24"></fdt-icon>
    </button>
  </div>

  <div class="fdt-modal__body">
    <ol class="cards-disclaimer__list">
      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 1</span>
          <span>GENERAL</span>
        </header>

        <ol>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Card Services.</span> The FD Card is a credit card issued by FDT for FDT’s
            clients. It is not a debit card or prepaid card.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Parties.</span> This Agreement is entered into by and between FDT and the Client.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">General Agreements.</span> By applying for, using or continuing to use the FD Card
            or the Card Services, the Client acknowledges that it has read, understood and agreed to be bound by this
            Agreement, including all schedules attached herein, and the General Agreements. The terms and conditions set
            forth in this Agreement shall supplement and apply in addition, and without prejudice, to the terms and
            conditions set forth in the General Agreements. In the event of any inconsistency between the terms and
            conditions of this Agreement and the General Agreements, this Agreement shall prevail insofar as it relates
            to the provision of the Card Services.
          </li>
        </ol>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 2</span>
          <span>INTERPRETATION</span>
        </header>

        <ol>
          <li class="cards-disclaimer__list-sub-item">
            Unless otherwise defined in this Agreement, all capitalized terms used in this Agreement shall have the same
            meanings ascribed to them in the Client Agreement.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            In this Agreement:

            <p>
              “Account Statement” means a document or report or information or statements provided to the Client
              detailing the Card Account information, including Transactions or any other amounts charged, fees owed or
              credited to the Card Account during a billing cycle.
            </p>

            <p>
              “Affiliates” means an entity that directly or indirectly controls, is controlled by or is under the common
              control with the Client or FDT, as may be applicable.
            </p>

            <p>
              “Application Form” means the onboarding form, whether digital or physical, completed by the Client for the
              establishment of a Card Account and receipt of Card Services under this Agreement.
            </p>

            <p>
              “Agreement” means this Card Services Agreement and shall include all schedules attached herein, as may be
              amended and updated from time to time.
            </p>

            <p>
              “Applicable Laws” means all relevant laws, regulations, statutes, statutory instruments, acts, orders,
              directives or administrative requirements in force from time to time issued by any applicable governmental
              agencies, Regulatory Authority, self-regulatory bodies, trade associations or industry bodies (whether or
              not having the force of law) having jurisdiction over the business, the business conduct and activities of
              any of the Parties
            </p>

            <p>
              “Authorized User” means any end-cardholder nominated by the Client and approved by FDT, such as an
              employee, director or officer of the Client, to whom an FD Card is issued.
            </p>

            <p>
              “Business Day” means any day other than a Saturday, Sunday or public holiday in Hong Kong SAR and excludes
              any day on which a typhoon signal No.8 or above or black rainstorm warning is hoisted in Hong Kong SAR at
              any time between 9:00 a.m. and 6:00 p.m.
            </p>

            <p>
              “Card Account” means a sub-account of a Client Account that is linked to an FD Card issued to the Client.
            </p>

            <p>“Card Network” means the payment network in connection with the Card Services from time to time.</p>

            <p>
              “Card Network Rules” means the then-effective bylaws, rules, regulations, guidelines for members of the
              Card Network, orders and interpretations issued by the Card Network, as they may be amended from time to
              time.
            </p>

            <p>“Card Services” means those services offered by FDT to the Client as set out in this Agreement.</p>

            <p>
              “Confidential Information” means any information or materials disclosed by one Party to the other Party
              that: (i) if disclosed in writing or in the form of tangible materials, is or was marked “confidential” or
              “proprietary” at the time of such disclosure; (ii) if disclosed orally or by visual presentation, is or
              was identified as “confidential” or “proprietary” at the time of such disclosure; or (iii) due to its
              nature or the circumstances of its disclosure, a person exercising reasonable business judgment would
              understand to be confidential or proprietary. The terms of this Agreement shall be considered the
              Confidential Information of the Parties.
            </p>

            <p>“Client” means the person in whose name the Card Account is opened.</p>

            <p>
              “Depository Account” means an account designated by FDT to which the Security Collateral are deposited.
            </p>

            <p>
              “Effective Date” means the date upon which the Client applied for the opening of a Card Account and is
              deemed by FDT to be qualified for the Card Services.
            </p>

            <p>
              “Fee Schedule” means the fee schedule as set out in Schedule 1 herein, as amended and updated from time to
              time.
            </p>

            <p>
              “FDT” means First Digital Trust Limited, a company incorporated in Hong Kong SAR with business
              registration number 70760197, and where the context so permits, include any of its Affiliates involved in
              providing the Card Services.
            </p>

            <p>
              “FD Card” means a credit card issued by FDT, linked to a Card Account, and enabled for Transactions over
              the Card Network. FD Card may include any physical or virtual card issued by FDT to the Client or an
              Authorized User.
            </p>

            <p>
              “General Agreements” means all the agreements that the Client may have with FDT or any of its Affiliates,
              including the Client Agreement and the Custody Services Agreement, and all other documentations which may
              be provided to the Client, including the Privacy Policy, the Apple Pay Terms and Conditions and the Google
              Pay Terms and Conditions.
            </p>

            <p>
              “Indebtedness” means any obligation for the payment or repayment of any and all debts or liabilities,
              whether present or future, actual or contingent, arising from the Card Accounts or use of FD Cards or the
              Card Services.
            </p>

            <p>
              “Late Payment Fee” means the payment fee chargeable by FDT or incurred and payable by the Client when the
              amount due on the FD Cards or the Card Accounts is not received by FDT on time.
            </p>

            <p>
              “Losses” means any losses, liabilities, claims, suits, demands, damages, judgments, expenses (including
              attorneys’ fees and collection costs), orders of restitution and penalties of a Party, including any civil
              monetary penalties and fines and penalties by the Principal Issuer or the Card Network.
            </p>

            <p>“Parties” means FDT and the Client.</p>

            <p>
              “Payment Processing Partner” means a financial institution or its designee that FDT works with to process
              or acquire the transfer of funds between the Client and FDT’s banking or payment infrastructure, and any
              other financial institution or other entity that FDT partners with to process Transactions that are part
              of the Card Services.
            </p>

            <p>
              “Personal Data” means any personal identifiable information that are non-public and that relates directly
              or indirectly to an individual as defined under the Applicable Laws, or as the case may be, an individual
              who can be identified, directly or indirectly, from the Transaction Data or from that and other
              information in the possession of either Party, including Personal Data of a Party’s clients, or its
              directors, officers, employees, authorized signatories, authorized representatives, shareholders or
              beneficial owners that a Party discloses to the other Party.
            </p>

            <p>
              “Principal Issuer” means the principal card issuer in connection with the Card Services from time to time.
            </p>

            <p>
              “Program Data” means any information, whichever is applicable, that is associated with the Client Account,
              Card Accounts, FD Cards, including CVVs and PINs, access credentials, Account Statements, Transactions,
              records, regulatory compliance data and any other information FDT uses or generate to provide the Card
              Services, but does not include Personal Data.
            </p>

            <p>
              “Regulatory Authority” means any governmental, regulatory authority or law enforcement department, court,
              agency, commission, board, tribunal, crown corporation or other law, rule or regulation making entity
              (including any stock exchange or central bank) that any of the Parties thereof submit to or are subject to
              the jurisdiction of in respect of this Agreement, and any successor or replacement of any of the
              foregoing.
            </p>

            <p>
              “Sanctions” means any Applicable Laws imposing sanctions, restrictions, or prohibitions on financial
              transactions or other business dealings that is administered or enforced by the U.S. Government (including
              the U.S. Department of Treasury’s Office of Foreign Assets Control, the U.S. Department of Commerce, or
              the U.S. Department of State and including designation as a “specially designated national” or blocked
              person), the United Nations Security Council and all other relevant Hong Kong SAR or international
              sanctions authority, including any executive orders issued in relation to the imposition of sanctions.
            </p>

            <p>
              “Security Breach” means any actual or suspected unauthorized access to, use or disclosure of Transaction
              Data or any Personal Data in a Party’s possession, custody or control (or in the possession, custody or
              control of any of its Affiliates, agents or service providers), and any actual or suspected security
              incident of its system or network or data breach (including as a result of theft or fraud) that has or
              could reasonably be expected to impact the confidentiality of any Personal Data, or impacts the delivery
              or receipt of the Card Services.
            </p>

            <p>
              “Security Collateral” means all security collateral, guarantee or any and all monies or VAs from time to
              time delivered, provided or paid by the Client to FDT in respect of the Card Services pursuant to this
              Agreement.
            </p>

            <p>
              “Settlement Currency” means the United States dollar (USD) settlement currency, or any other settlement
              currencies made available by FDT from time to time, of the FD Card and the Card Account.
            </p>

            <p>
              “Taxes” means any applicable taxes, including goods and services tax, sales, excise, import or export,
              value added or similar tax or duty, or withholding tax, or has the meaning assigned to it in the Taxes
              section.
            </p>

            <p>
              “Transactions” means any transaction under the Card Services, including any fund transfers or bank
              transfers, electronic money or telegraphic transfers and any point-of-sale or card-not-present
              transactions, as contemplated under this Agreement. This includes instances of any attempted transactions
              that have yet to be completed, or attempted transactions that may have eventually failed, for whatsoever
              reason as contemplated in this Agreement or transactions that are eventually undertaken and completed by
              FDT.
            </p>

            <p>
              “Transaction Data” means all data, including any Personal Data about the Client’s recipients or clients or
              payee, the transfer amount and currency, or such other data that may be collected during the period FDT is
              providing Card Services to the Client
            </p>

            <p>
              “VAs” or “Virtual Assets” means any cryptocurrencies or stablecoins that are accepted by FDT as a medium
              of exchange as payment for goods or services or for the discharge of a debt and that can be transferred,
              stored or traded electronically.
            </p>

            <p>
              “VA Partner” means a service provider that FDT partners with, for the purpose of accepting, storing,
              custodizing and processing VA on behalf of FDT.
            </p>
          </li>

          <li class="cards-disclaimer__list-sub-item">
            Section, clause and paragraph headings are for ease of reference only and shall not be read in isolation,
            nor shall they affect the interpretation of this Agreement or any part of it.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            Unless the context otherwise requires, words in the singular shall include the plural and in the plural
            shall include the singular.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            Unless the context otherwise requires, the word “or” shall have the inclusive meaning identified with the
            phrase “and/or”.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            Any words following the terms “including”, “include”, “in particular”, “for example” or any similar
            expression shall be construed as illustrative and shall not limit the sense of the words, description,
            definition, phrase or term preceding those terms.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            References to a “person” shall be construed to include any individual, any firm, any company, corporation or
            other body corporate, any government or governmental body, any state or agency of a state, any trust, or any
            joint venture, association or partnership (whether or not having separate legal personality).
          </li>

          <li class="cards-disclaimer__list-sub-item">
            Unless the context otherwise requires, a reference to one gender shall include a reference to the other
            genders.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            References in this Agreement to Schedules mean the schedules attached herein, the terms of which are
            incorporated into and form part of this Agreement. In the event of any inconsistency between this Agreement
            and any Schedules, the relevant terms of the Schedules prevail.
          </li>
        </ol>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 3</span>
          <span>FD CARD</span>
        </header>

        <ol>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Credit Card.</span>
            The Client and its Authorized Users may only use the FD Cards for bona-fide business or retail-related
            Transactions. If the Client wishes to give its FD Cards to its Authorized Users or request that FD Cards be
            issued to its Authorized Users, it is equally responsible for selecting who in its organization should have
            access to the FD Cards. The Client shall ensure that each Authorized User agrees and complies with the terms
            of this Agreement and the General Agreements. The Client shall also establish and maintain controls designed
            to ensure that the FD Cards are only used for bona fide purposes and are, at all times, in compliance with
            this Agreement, the Card Network Rules and the Applicable Laws. The Client is solely responsible for the
            Transactions made by any individuals given access to the FD Cards even if they are not the person associated
            with or named on the FD Cards.

            <p>
              FD Card is not for resale and may be cancelled, repossessed, or revoked at any time without prior notice
              pursuant to this Agreement, the Card Network Rules or Applicable Laws. The physical FD Card remains FDT’s
              property, and the Client shall return it to FDT or destroy it in accordance with FDT’s instructions upon
              request or upon termination of this Agreement.
            </p>
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Virtual Card.</span> The Client may also apply for a virtual FD Card. The virtual
            FD Card will have a different set of card details from the physical FD Card but will draw from the balance
            in the Card Account in the same manner.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Verification Checks.</span> Only fully verified clients of FDT will be able to use
            the Card Services, and any anonymous users are strictly prohibited and not permitted. The Client is required
            to provide certain information and documentation to FDT about itself and its Authorized Users for FDT to
            carry out verification checks when the Client requests and applies for an FD Card (in addition to the checks
            required for opening a Client Account or other services offered by FDT or any of its Affiliates). FDT may
            also carry out further checks on an ad-hoc basis from time to time. The Client agrees to timely supply all
            relevant information and documentation for FDT to verify and authorize its and its Authorized Users
            information and Transactions on an on-going basis. The Client further agrees that FDT may conduct any such
            checks by contracting and consulting relevant registries and government authorities and accordingly may
            share its and its Authorized Users’ data, including any Personal Data, with these relevant registries and
            government authorities. The Client represents, warrants and undertakes unconditionally that it is duly
            authorized to provide the information and documentation required by FDT, and that all information and
            documentation provided are correct and up-to-date.

            <p>
              Failure to timely comply with requests for information or documentation may result in the refusal of the
              FD Card application, or the suspension of FD Card or the Card Services. FDT shall not be responsible for
              any Losses arising from the suspension of FD Card or the Card Services as a result of carrying out such
              checks.
            </p>
          </li>
        </ol>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 4</span>
          <span>USE OF FD CARD</span>
        </header>

        <ol>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Card Usage.</span> The Client represents and warrants to adhere and comply with
            the terms of this Agreement as long as any of the Card Accounts remains open or has an outstanding balance.
            The Client also acknowledges and agrees that it may also be subject to the Card Network Rules and the
            compliance and regulatory requirements of the Card Network and the Principal Issuer, and FDT’s provision of
            the Card Services is subject to the fulfillment of such requirements.

            <p>
              The Client shall only use the Card Services for the purposes as agreed and set forth in this Agreement and
              any use of the Card Services for any other purpose will be regarded as a breach of this Agreement. The
              Client and its Authorized Users shall also take all reasonable steps to prevent the unauthorized use of FD
              Cards and Card Accounts. In particular, the Client shall not, nor permit the Authorized Users or any other
              third-party to, use or access FD Card or the Card Services, directly or indirectly to:
            </p>

            <ol class="cards-disclaimer__low-alpha-list">
              <li>
                engage in any activity that interferes with, disrupts, damages, or accesses in an unauthorized manner
                the Card Services, servers, networks, data, or other properties of the Card Network, the Principal
                Issuer, FDT or any of its Affiliates, service providers, suppliers or licensors;
              </li>
              <li>
                do or omit to do anything which FDT reasonably believes to be disreputable, or which is otherwise
                capable of damaging the reputation or goodwill of the Card Network, the Principal Issuer, FDT or any of
                its Affiliates, service providers, suppliers or licensors;
              </li>
              <li>engage in any criminal, fraudulent, or other unlawful activity;</li>
              <li>
                facilitate any illegal transactions or any transactions that are identified as a restricted business or
                activity, including use of FD Card or the Card Services in or for the benefit of a territory,
                organization, entity or person embargoed or blocked by any government including those on the Sanctions
                lists;
              </li>
              <li>
                make the Card Services available to, or use the Card Services for the benefit of, anyone other than the
                Client, unless authorized and approved by FDT in writing;
              </li>
              <li>
                sell, resell, license, sublicense, distribute, rent or lease the Card Services, or transfer any rights
                granted to the Client under this Agreement;
              </li>
              <li>act as a service bureau or pass-through agent for the Card Services;</li>
              <li>
                permit direct or indirect access to or use of the Card Services in a way that circumvents a contractual
                usage limit, or work around any of the technical limitations of the Card Services, or enable
                functionality that is disabled or prohibited, including imposing an unreasonable or disproportionately
                large load on the Card Services, as reasonably determined by FDT;
              </li>
              <li>
                permit direct or indirect access to or use of the Card Services where it is prohibited to offer, or
                provide these to (including to any persons residing or operating in) or from territories that are
                deemed, stated or identified as prohibited by FDT, the Principal Issuer, the Card Network, the Card
                Network Rules or the Applicable Laws, from time to time;
              </li>
              <li>
                access, or attempt to access, non-public systems, programs, data, or services of FDT or any of its
                Affiliates;
              </li>
              <li>
                copy, reproduce, republish, upload, post, transmit, resell, or distribute in any way, any data, content,
                or any part of the Card Services, or any technical documentations of FDT or any of its Affiliates;
              </li>
              <li>
                modify, reverse engineer, copy, frame, scrape, rent, lease, loan, sell, distribute, or create derivative
                works based on the Card Services, in whole or in part; or
              </li>
              <li>
                perform, or attempt to perform, any actions that would interfere with the normal operation of the Card
                Services or affect the use of the Card Services by other clients of FDT.
              </li>
            </ol>

            <p>
              The Client shall be responsible for any such use or Transactions. In addition, these Transactions will be
              subject to the Card Network Rules and this Agreement, and the Client will be required to reimburse the
              Card Network, the Principal Issuer and FDT for all damages and expenses arising from its breach.
            </p>

            <p>
              The Client hereby acknowledges its liability in respect of the obligations in this Agreement and the Card
              Network Rules, and further covenant with FDT that it shall: (a) on FDT’s demand, pay to FDT all amounts or
              monies actually or contingently owning now or in the future by it to FDT, as determined by FDT in its sole
              and absolute discretion, including those obligations for which FDT acts as the ultimate guarantor should
              it default or fail to meet them; and (b) perform its obligations under this Agreement or the Card Network
              Rules as and when they fall due for performance.
            </p>

            <p>
              FDT may decline any Transaction for any reason, including violation of this Agreement, the Card Network
              Rules, suspected fraud or creditworthiness. FDT may also decline to authorize any Transactions for
              merchants that are characterized by the Card Network, the Principal Issuer or FDT as prohibited merchants.
              Additionally, the Card Network, the Principal Issuer, FDT or any other intermediary service providers
              (including any merchant acquirers or card processors) may deny or reverse any Transactions for any reason.
              FDT shall not be responsible for any Losses, damages, or consequences or other harms resulting from any
              declined or reversed Transactions, or if anyone or any merchant refuses to accept FD Card for any reasons.
              FDT also reserves the right to take appropriate measures, including suspending all or any part of the
              Client’s or its Authorized Users’ FD Cards, Card Accounts or access to the Card Services, if the monthly
              decline rate of any of the Client’s or its Authorized Users’ FD Cards or Card Accounts exceeds twenty
              percent (20%) in any month, and the Client shall indemnify FDT of any and all Losses FDT may incur arising
              from such circumstances.
            </p>

            <p>
              From time to time, due to circumstances beyond FDT’s control (such as system failures, fires, floods,
              natural disasters, or other unpredictable events), the Card Services may be unavailable. When this
              happens, the Client and its Authorized Users may be unable to use the FD Cards or obtain information about
              the Card Accounts. FDT shall not be responsible or liable, under any circumstances, if this happens.
            </p>
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Credit Limit.</span> When the Client opens a Card Account, it will receive a
            credit limit. Such credit limit will be the maximum aggregated amount available for all Transactions on the
            FD Card and the Card Account. Unless otherwise informed by FDT to the Client, FDT will bill each
            Transaction, place a hold on the Card Account and apply them against the credit limit balance until the
            Transaction is cleared and finalized and the hold is released. FDT will maintain an accounting of every
            successful Transaction amount under the Card Account against the credit limit balance and reducing the
            credit limit balance with each successful Transaction. Any authorization request or Transactions that
            exceeds the credit limit balance will be declined with the Client’s and its Authorized Users’ credit scores
            adversely affected. The Client acknowledges and agrees that FDT is under no obligation to provide any notice
            or warning when the credit limit is approaching or when the credit limit balance is nearing zero. The Client
            shall be responsible for keeping track of all Transactions and its credit limit balance and shall indemnify
            FDT of any and all Losses FDT may incur as a result of any Transactions so declined.

            <p>
              The Client’s initial credit limit will be determined by FDT, in its sole and absolute discretion, based on
              a credit review in conjunction with any Security Collateral provided to FDT. FDT may also increase,
              decrease, restrict or cancel the Client’s credit limit at any time, in its sole and absolute discretion,
              based on the Client’s Transaction history and the Card Accounts, and any related risk and credit
              considerations. FDT may do so at any time and for any reason permitted by the Card Network Rules or the
              Applicable Laws, even if the Client is not in default on the Card Accounts. Such an increase, decrease,
              restriction or cancellation of credit limit will not affect the Client’s continued payment obligations
              that are due and payable to FDT.
            </p>

            <p>
              From time to time, the Client may request FDT to increase its credit limit. FDT may choose to grant or
              reject such request at its sole and absolute discretion.
            </p>
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold"> Other Limits.</span>
            FDT may establish limits on the types of merchants with which a Transaction may be made with an FD Card. FDT
            reserves the right, in its sole and absolute discretion, to restrict the maximum amount of any Transactions
            and the number of Transactions allowed for an FD Card or the Card Account. FDT may, in its sole and absolute
            discretion, decline or authorize any Transactions that would cause any such applicable designated limits to
            be exceeded.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold"> International Use.</span>
            In the event FD Cards are used to make international Transactions, such Transactions will be converted into
            the Settlement Currency if the Transactions are made in a currency other than the Settlement Currency.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Security Collateral.</span> In certain cases, at FDT’s sole and absolute
            discretion, the Client may be requested to provide Security Collateral to FDT in such form as specified by
            FDT in order to gain access to a Card Account or an FD Card. Where applicable and as requested, the Client
            shall transfer any and all Security Collateral as applicable from its custody account with FDT into the
            Depository Account as instructed. FDT may change the Depository Account without notice to or consent from
            the Client. The Client acknowledges that any Security Collateral sent to an incorrect account or VA wallet
            address, or using an incorrect VA token or blockchain network, may be permanently lost and that FDT shall
            not be responsible, in any circumstances, for any Losses arising from the Client’s error or any amount sent
            to an incorrect account or VA wallet address

            <p>
              The Client shall ensure the minimum level of Security Collateral as specified by FDT is deposited and
              maintained with FDT at all times. FDT may, at its sole and absolute discretion, increase the required
              minimum Security Collateral level from time to time to reflect, among other factors, any anticipated
              changes in the projected spending or the credit limit of the Card Account, in which case the Client shall
              ensure additional Security Collateral is deposited and maintained with FDT.
            </p>

            <p>
              The Client acknowledges and agrees that the failure to timely deposit and maintain the minimum level of
              Security Collateral at any time will result in: (a) a late charge of 23% per annum, accrued daily, until
              the minimum level of Security Collateral amount is received by FDT in full. Such late charge will be
              directly deducted from the Security Collateral or billed and payable by the Client on the next month
              billing cycle; and (b) notwithstanding the foregoing, FDT may, at its sole and absolute discretion,
              suspend or terminate all or any part of the Card Services at any time until the minimum level of Security
              Collateral is received by FDT in full, and FDT shall not be liable for any Losses arising from the
              Client’s failure to deposit and maintain the minimum level of Security Collateral. The Client acknowledges
              and agrees that FDT is under no obligation to provide any notice or warning when the available remaining
              Security Collateral is nearing the minimum level or zero. The Client shall remain solely responsible for
              ensuring that the Security Collateral is replenished in time and shall indemnify FDT of any and all Losses
              FDT may incur as a result of any Transactions so declined.
            </p>
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Security Collateral in the hands of FDT.</span> For valuable consideration, the
            adequacy and receipt of which is hereby acknowledged, the Client hereby absolutely, unconditionally and
            irrevocably assigns, transfers, conveys and delivers to FDT all of its present and future rights, title and
            interest in the following, free and clear of any liens, claims, encumbrances or other interests: (i) all
            Security Collateral; (ii) all monies and VAs now or in the future held in, on deposit in or otherwise
            allocated to the Depository Account, including any monies or VAs transferred to the Depository Account after
            the date hereof by the Client; (iii) any other VAs now or in the future issued with respect to any of the
            foregoing VAs as a result of a fork or other event that results in the holders of VAs receiving additional
            or replacement VAs, whether or not such other VAs or cryptocurrency is held in, on deposit in or otherwise
            allocated to the Depository Account; (iv) all rights to receive delivery of or withdraw any of the foregoing
            monies or VAs from the Depository Account and all rights against any of the foregoing monies or VAs, and the
            proceeds thereof; and (v) all proceeds of the foregoing.

            <p>
              Accordingly, the Client acknowledges and agrees that: (a) FDT shall have immediate and exclusive
              possession and control, and be the sole, absolute, legal and equitable owner, of the Security Collateral;
              (b) the Client shall not be entitled to any return, in whole or in part, of the Security Collateral, other
              than a return of a sum in the same currency and amount in the circumstances set out in this Agreement; (c)
              no interest shall be payable in respect of the Security Collateral deposited from time to time and any
              monies or VAs which FDT may earn in respect of the Security Collateral deposited shall be the absolute
              property of FDT; (d) the Depository Account may hold such Security Collateral on a commingled basis; (e)
              FDT may, without notice to or consent from the Client and for FDT’s own account or benefit or otherwise,
              deal, sell, pledge, distribute, transfer, use or carry out any actions, either separately or in common
              with other such monies or VAs, any or all of the Security Collateral for any purpose as FDT sees fit from
              time to time without responsibility for loss, and that FDT may do so without retaining in its possession
              or control for delivery a like amount of similar Security Collateral; and (f) FDT shall have the rights
              stated in this Agreement with respect to the Security Collateral, in addition to all other rights which
              FDT may have by law.
            </p>

            <p>
              All fees due to FDT under this Agreement will be deducted or netted from the Security Collateral and any
              incoming transaction amounts and are automatically forwarded or acquired by FDT. The Client acknowledges
              and agrees that FDT has and retains the right of set-off with respect to any actual or identified Loss or
              any other amounts which may be owing from time to time by the Client to FDT against the Security
              Collateral or any property of the Client which at any time FDT shall have in its possession or control.
              The right of set-off in favor of FDT, if exercised, shall be deemed to have been exercised at the time FDT
              first restricts the Client’s access to the Security Collateral or its property in FDT’s possession or
              control, even though this set-off may be entered on FDT’s books and records at a later time.
            </p>
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Return of Security Collateral.</span> A sum in the same currency and amount as the
            Security Collateral shall be returned to the Client within thirty (30) Business Days of the termination of
            this Agreement upon its payment in full of any outstanding credit balance and finance charges in accordance
            with this Agreement. At such time, FDT’s sole obligation shall be to transfer the remaining sum to the
            Client’s custody account with FDT after setting-off any amount the Client owes on any of the FD Cards and
            Card Accounts, or any claims FDT may have, or otherwise under this Agreement.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Representation, Warranties and Covenants Relating to Security Collateral.</span>
            To induce FDT to enter into this Agreement, the Client hereby represent, warrant and covenants to FDT the
            following, in relation to the Security Collateral provided by the Client to FDT: (a) it is the sole, legal
            and equitable owner of the Security Collateral and no other security agreement, financing statement or other
            security instrument covering the Security Collateral exists; (b) it has rights in or the power to transfer
            the Security Collateral, and its title to the Security Collateral is free and clear of liens, adverse claims
            and restrictions on transfer or pledge; (c) there are no actions, suits, litigation or proceedings, at law
            or in equity, pending by or against it before any court, administrative agency or arbitrator in relation to
            the Security Collateral; (d) it will not sell, dispose or otherwise transfer the Security Collateral or any
            interest in the Security Collateral without prior written consent from FDT; (e) it will not create or allow
            any other security interest or lien on the Security Collateral; (f) it will execute and deliver any
            financing statement, instrument or other documents and to take such other actions upon FDT’s request to
            evidence and effectuate the assignment contemplated herein and to perfect or otherwise record FDT’s interest
            in the Security Collateral; and (g) it will promptly pay all Taxes and assessments due on the Security
            Collateral, if any.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold"> Interest Absolute.</span> To the extent permitted by Applicable Laws, the Client
            hereby waive demand, notice, protest, notice of acceptance of this Agreement, the Security Collateral
            received or delivered and all other demands and notices of any description. To the extent permitted by the
            Applicable Laws, all rights and interests of FDT hereunder and all Indebtedness of the Client hereunder
            shall be absolute and unconditional irrespective of: (a) any illegality or lack of validity or
            enforceability of any Indebtedness or any related agreement or instrument; (b) any change in the time, place
            or manner of payment of, or in any other term of, the Indebtedness or any amendment or other modification of
            this Agreement or any other agreement, including any increase in the Indebtedness resulting from any
            extension of additional credit or otherwise; (c) any taking, exchange, substitution, release, impairment or
            non-perfection of any Security Collateral or any other collateral, or any taking, release, impairment,
            amendment, waiver or other modification of any guaranty, for all or any of the Indebtedness; (d) any manner
            of sale, disposition or application of proceeds of any Security Collateral or any other collateral or other
            assets to all or part of the Indebtedness; (e) any default, failure or delay, willful or otherwise, in the
            payment of the Indebtedness; (f) any defense, set-off or counterclaim (other than a defense of payment or
            performance) that may at any time be available to, or be asserted by, it against FDT; or (g) any other
            circumstance (including any statute of limitations) or any existence of or reliance on any representation by
            FDT that might vary the risk of the Client or otherwise operate as a defense available to, or a legal or
            equitable discharge of, it or any guarantor or surety.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Account Statements.</span> The Transaction history and Account Statements will be
            available to the Client in the Card Accounts and will include instructions for making payments the amounts
            due and the due date for payment. Save for manifest error, the Transaction records in the Card Accounts
            shall be conclusive evidence of the accuracy and authenticity of the Transactions made and shall be final
            and binding on the Client for all purposes. The Client should check the Transaction history regularly. FDT
            shall not be responsible for alerting the Client to any suspected unauthorized Transactions associated with
            its or its Authorized Users’ FD Cards or the Card Accounts.

            <p>
              The Client’s payments shall be in the same currency as the Settlement Currency from its custody account
              with FDT, or any other currency or settlement method otherwise acceptable and agreeable to FDT. FDT does
              not accept cash payments.
            </p>

            <p>
              FDT may impose a Late Payment Fee if fees and charges are not received when due. The Client agrees and
              acknowledges that, in the event of a delay in payment, in whole or in part, of any monetary obligations
              under this Agreement, FDT reserves the right to take appropriate measures, including suspending all or any
              part of the Client’s or its Authorized Users’ FD Cards, Card Accounts or access to the Card Services.
            </p>

            <p>
              FDT will continue to charge interest charges during the billing cycles when the Client carries any
              remaining balance. If the Client’s Account Statement is ninety (90) days past due, part of a bankruptcy
              proceeding or otherwise charges off, the entire balance is immediately due and payable
            </p>
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Applicable Fees.</span> FDT will charge fees and interest charges to the Card
            Accounts as disclosed to the Client in the Account Statements and as described in the Fee Schedule. Interest
            charges begin to accrue from the day a Transaction occurs. FDT will continue to charge the Client interest
            charges when it carries any remaining Account Statement balance. In addition to the fees and interest
            charges, the Client shall be responsible for any penalties imposed on it, FDT or the Principal Issuer in
            relation to the Card Accounts or arising from its or its Authorized Users’ use of FD Cards. Such fees and
            penalties may include periodic fees, foreign transaction fees, penalties for misuse, funds transfer fee,
            account maintenance fees, FD Card issuance or replacement fees and penalties for any late or failed
            payments. All fees or penalties the Client owes are in addition to the amounts owed for all Transactions on
            the FD Cards and Card Accounts. The Client agrees and acknowledges that, to the fullest extent of the law,
            FDT’s internal calculations of all fees or penalties that are due and payable are accurate, valid, final and
            binding on the Client.

            <p>FDT may revise or amend the Client’s fees, charges and payment terms, from time to time</p>
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Taxes.</span> All amounts payable to FDT are exclusive of any value-added tax or
            goods-or-services tax, withholding and other statutory taxes as applicable, and all such applicable taxes
            shall be the financial responsibility of and payable by the Client. The Client agrees to defend, hold
            harmless and indemnify FDT and its Affiliates from and against any VAT, GST, turnover and other taxes or
            levies including penalties, interests and surcharges (“Taxes”) due on any product or service of it and costs
            or damages related to such Taxes. The Client shall (i) utilize best effort to ensure that FDT and its
            Affiliates cannot be held liable for any Taxes and costs or damages related to such Taxes; (ii) promptly
            inform FDT of any such liability; and (iii) provide FDT with all relevant information and documentation in
            that respect.
          </li>
        </ol>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 5</span>
          <span>FD CARD TRANSACTION</span>
        </header>

        <ol>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Transaction Authorization.</span> The Client shall pay FDT and be responsible for
            all amounts due on the Card Accounts. This includes amounts where the Client or its Authorized Users did not
            sign a purchase slip or other documents for the Transactions (where applicable) or any Transactions that
            does not require the Client or its Authorized Users to insert its PIN. As the Client is responsible for
            securing the FD Cards, Card Accounts and any FD Cards security features, including the CVV and PIN, the
            Client shall pay FDT and be responsible for any Transactions, fees and penalties resulting from its failure
            to exercise reasonable care in safeguarding the FD Cards from loss or theft, failure to promptly report loss
            or theft and for all other Transactions on the FD Cards issued to it or its Authorized Users. If the Client
            or its Authorized Users use the FD Card number without presenting the physical FD Card (including mail,
            telephone, or internet purchases), this will be treated the same way as if the Client or its Authorized
            Users used the FD Card in person. If the Client or its Authorized Users let or allow someone else to use the
            FD Cards or Card Accounts for any other purposes, the Client is similarly responsible for such use and for
            all Transactions that such person makes, as if it were duly authorized by the Client. The Client therefore
            agrees that it will be bound by and financially responsible for any Transactions initiated under its or its
            Authorized Users’ FD Card credentials or processed in accordance with its or its Authorized Users’
            instructions, even if it is later discovered that it was not duly authorized by the Client. The Client may
            also be required to reimburse FDT, the Principal Issuer and the Card Network for all amounts or expenses
            paid by such entities as a result of such use.

            <p>
              It is the Client’s sole responsibility to monitor any suspicious or unauthorized activities on the FD
              Cards and Card Accounts. The Client agrees to notify FDT immediately by contacting FDT of any loss, theft,
              fraud, potential fraud or unauthorized use of the FD Cards or Card Accounts, and fully cooperate with FDT
              with investigations to the same as required or instructed by FDT. The Client shall continue to be liable
              for any unauthorized use of the FD Cards and Card Accounts to the fullest extent permitted by the
              Applicable Laws. The Client shall be liable to FDT for any fees, charges, costs, amounts, expenses or
              other Losses that result from its actions or inactions. The Client agrees and acknowledges that FDT is not
              liable or responsible to it, and it waives any right to bring a claim against FDT, for any such Losses.
            </p>
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Merchant Refunds.</span> If the Client or its Authorized Users is entitled to a
            refund for goods or services purchased with an FD Card, these refunds will be accepted as credits to the
            Card Account. FDT does not control when a merchant sends the refund. FDT will also require a reasonable
            amount of time after it receives the refunds to credit and process them to the Card Account.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Disputed Transactions.</span> The Client shall inspect each Account Statement it
            receives and the Transaction history as shown in the Card Accounts regularly and inform FDT about any errors
            or questions it has. If the Client does not notify FDT or raise any errors or objections, FDT will assume
            that all information in the Account Statements and the Transaction history is correct and accurate. If the
            Client has a dispute with the product or service that is the subject of a Transaction, it shall first
            contact the merchant or seller of that product or service to dispute the relevant Transactions and resolve
            the dispute. If the Client is unable to resolve the dispute with the merchant or seller, then the Client may
            report a chargeback or the disputed Transaction to FDT immediately and in any event within thirty (30) days
            from the date the Transactions appears or is posted on the Card Accounts. The Client’s failure to report a
            disputed Transaction within the thirty (30) days’ stipulated timeline will constitute a waiver of its right
            to dispute the Transaction and will be considered as acceptance of the validity of the Transaction.

            <p>
              When reporting a disputed Transaction, the Client shall complete a specified dispute form and provide FDT
              with all relevant information and documentation to support its claim, and any such information and
              documentation as may be required to facilitate the Principal Issuer in submitting the disputed Transaction
              to the Card Network for their ultimate investigation and determination. The Client understands that any
              chargeback is subject to the Card Network Rules, and it may not be able to successfully chargeback the
              disputed Transaction. In no circumstance does FDT represents or guarantees the validity of the dispute, or
              the result of the investigation conducted by the Card Network, and the Client shall indemnify FDT of any
              and all Losses FDT may incur arising from such disputes.
            </p>

            <p>
              To avoid any Late Payment Fee and sustained reduction to its credit limit or credit balance, the Client
              shall pay the disputed Transaction while FDT and the Card Network determine the validity of the dispute.
              If the Client does not notify FDT immediately and provide sufficient information, FDT will not be able to
              lodge a claim in time in which case the Client will be liable for the Transaction. If the dispute is
              deemed valid, FDT will credit the disputed Transaction amount in the original currency back to the Card
              Account. By crediting such disputed Transaction amount back to the Card Account, the Client agrees to, and
              shall procure its Authorized Users to, automatically assign, transfer and give FDT all rights and claims,
              excluding tort claims, that the Client and its Authorized Users may have against such merchant for any
              disputed Transaction fully or partially credited to the Card Account. This includes: (i) giving FDT any
              merchandise or other purchases the Client or its Authorized Users received in connection with the disputed
              Transaction amount if FDT instructs; (ii) not pursue any claim or reimbursement from the merchant and such
              other person; and (iii) assist FDT to pursue and get reimbursement from the merchant and such other
              person. The Client’s assistance includes giving FDT any and all documents that FDT requests and that are
              acceptable to FDT.
            </p>
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Sole Use.</span> The Client undertakes not to authorize any other entities,
            including any other of its Affiliates, to use the Card Accounts, and it may not assign or otherwise transfer
            any FD Card to any of its Affiliates or any other person or entity without FDT’s prior written approval.
          </li>
        </ol>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 6</span>
          <span>ACCOUNT DEFAULT</span>
        </header>

        <ol>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Account Default.</span> FDT may consider the Client to be in default of this
            Agreement if: (a) the Client fails to or do not make any payments of the Account Statements when it is due,
            or any payments of the Account Statements that the Client makes is rejected, not paid, or cannot be
            processed for whatsoever or any reason; (b) the Client or its Authorized Users exceeded the credit limit
            designated for any of the FD Cards and Card Accounts; (c) a bankruptcy or other insolvency proceeding is
            filed by or against the Client, including the appointment of a receiver for any part of its property, any
            assignment for the benefit of its creditors, any type of creditors workout, or any levy, garnishment,
            attachment or similar proceeding is instituted against any property of it; (d) FDT determines, at its sole
            and absolute discretion, that any warranty, representation, statement or documentation made or furnished to
            FDT is false, incomplete or misleading, either now or at the time it was made or at any time thereafter, or
            the Client otherwise tries to defraud FDT; (e) the Client does not comply with or breach any provisions of
            this Agreement or any of the General Agreements; (f) the Client or its Authorized Users pose an unacceptable
            regulatory, reputational or financial risk to FDT as determined at FDT’s sole and absolute discretion; or
            (g) an adverse change occurs in the Client’s financial condition, or its ability to repay any amount due to
            FDT or any of its Affiliates when it is due, or its ability to perform under this Agreement or any of the
            General Agreements.

            <p>
              In the event FDT determines that the Client or any of the Card Accounts is likely to be in default, FDT
              may take any actions necessary, including decreasing or adjusting its credit limit, ceasing to authorize
              any Transactions or refusing to issue new FD Cards.
            </p>
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Remedies.</span> Upon an account default, FDT may declare all amounts due under
            this Agreement immediately due and payable and shall have all the rights and remedies available to it under
            all Applicable Laws. FDT shall have the right to set-off or recoup any amount the Client owes on any of the
            FD Cards or Card Accounts, or any claims FDT may have related to this Agreement, against or from the
            Security Collateral. Without prejudice to any other rights and remedies available to it under this
            Agreement, FDT may (a) take immediate and exclusive possession of the Security Collateral (if not already
            taken) and apply any or all of the Security Collateral in satisfaction of any Indebtedness, obligations or
            amounts owed by the Client pursuant to this Agreement (including any costs, fees, or expenses incurred in
            connection with the sale or disposition of the Security Collateral) in such order as FDT shall elect, and
            FDT’s obligation to return a sum equals to the Security Collateral shall be reduced by such amount; and (b)
            exercise any rights it may have under the Card Network Rules, this Agreement or any of the General
            Agreements. To the extent permitted by the Applicable Laws, the Client hereby waives any requirement of
            prior demand or notice of default or application of the Security Collateral. The Client further agrees FDT
            may exercise its rights under this Agreement against any Client Accounts it maintains with FDT, including by
            authorizing FDT to debit or setoff funds it owes FDT from any amounts processed by FDT under this Agreement
            or any of the General Agreements. The Client shall reimburse FDT and its Affiliates all costs and expenses,
            including all accounting and legal fees and disbursements and court costs, related or incidental, to the
            collecting amounts owed by the Client under this Agreement, the holding or realization of the Security
            Collateral, or the establishment or enforcement of any of the rights of FDT under this Agreement. FDT may
            also, at its sole and absolute discretion, assign any or all sums owing to it in connection with a FD Card
            or a Card Account to any third-party agency engaged by it (including any debt collection agency, credit
            management organization or solicitor firm) for debt recovery. To the extent permitted by the Applicable
            Laws, the Client and its Authorized Users shall jointly and severally indemnify FDT for all debt recovery
            costs and expenses it incurs. If the Client is a sole proprietor, it understands that, by agreeing to this
            Agreement and applying for and using any FD Card, it agrees to unconditionally and irrevocably jointly and
            severally guarantee in a personal capacity as the principal debtor to pay and satisfy all amounts owed to
            FDT under the FD Card and Card Services on demand. The Client may not assign or transfer any of its rights
            or obligations under this guarantee without FDT’s prior written consent.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">FDT Appointed Attorney-In-Fact.</span> The Client hereby appoints FDT as its
            attorney-in-fact, with full authority in the place and its stead and in its name or otherwise, from time to
            time during the continuance of an account default to take any action and to execute any instrument that FDT
            may deem necessary or advisable to accomplish the purposes of this Agreement, even though FDT shall not be
            obligated to and shall have no liability to the Client or any third party for failure to do so or take
            action. This appointment shall be irrevocable. The Client hereby ratifies all that said attorney-in-fact
            shall lawfully do or cause to be done by virtue hereof.
          </li>
        </ol>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 7</span>
          <span>CARD EXPIRY</span>
        </header>

        <p>
          An FD Card has an expiry date. The expiry date is either printed on the card or is available on the Card
          Account, whichever is applicable. The Client and its Authorized Users shall not use an FD Card after its
          expiry. If the Client or its Authorized Users have used the FD Card in the two (2) weeks before it expires,
          FDT may contact the Client to inform it that FDT will automatically transfer the outstanding Account Statement
          balance to a new FD Card. The new FD Card will be issued and sent to the Client at its address as stated on
          the Application Form. If the FD Card has not been used in the eight (8) weeks prior to its expiry, FDT may
          contact the Client to ask if it wants to replace the FD Card. If the Client asks FDT to do so, FDT will issue
          the Client a replacement FD Card within four (4) weeks. A replacement card fee may apply.
        </p>

        <p>
          The Client and its Authorized Users will not be able to use an FD Card after its expiry if the Client does not
          renew or replace the FD Card. Subject to the absence of any applicable outstanding obligations or payments
          that are due and payable, the Client may request not to renew or replace the FD Card, and the Card Account
          will be deactivated.
        </p>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 8</span>
          <span>ENDING OR SUSPENSION OF CARD SERVICES</span>
        </header>

        <p>
          The Client may ask FDT to close the Card Account by contacting FDT’s client services. Should the Client
          desires to close the Card Account and terminate this Agreement prior to the end of its term, it shall be
          entitled to terminate only upon: (a) a written notice to FDT of its intent to so terminate; and (b) payment in
          full to FDT in an amount equals to (i) the total minimum fees payable to FDT as stipulated in the Fee Schedule
          for the remaining months of the term of this Agreement; and (ii) the sum total of all fees (including any fees
          associated with any Transactions incurred by the Client or its Authorized Users that were due and payable to
          FDT) accrued up to the date of the termination.
        </p>

        <p>
          FDT may separately provide the Client with additional details after receiving and reviewing its request, which
          may include any outstanding payment obligations and payment information. The closure of the Card Account will
          not affect any of the Client’s obligation to pay the Account Statements or any and all amounts it owes plus
          any finance and other charges under this Agreement. The Client’s obligation to pay the Account Statements and
          all amounts it owes plus any finance and other charges under this Agreement are subject to all Applicable Laws
          regarding repayment requirements. Any physical FD Card the Client may receive in the future remains FDT’s
          property and the Client shall return it to FDT or destroy it in accordance with FDT’s instructions upon
          termination of this Agreement. If the Client or its Authorized Users use the FD Card or any Transactions is
          posted to the Card Account after it asks FDT to close the Card Account, FDT may keep the Card Account open or
          reopen the Card Account. FDT may similarly close or suspend any Card Account and the Client’s right to obtain
          any credit limit at any time and for any reason, even if the Client is not in default. A suspension of the
          Card Account may be permanent or temporary, at FDT’s sole and absolute discretion. If the Card Account is
          closed or suspended for any reason, the Client and its Authorized Users shall stop using the FD Card and
          cancel all billing arrangements to the FD Card and Card Account. FDT will not do this for the Client. The
          Client shall still pay FDT all amounts it owes on the Card Account, even if they are charged after the Card
          Account is closed or suspended. If FDT closes or permanently suspends a Card Account, the Client shall also
          return the physical FD Card to FDT or destroy it in accordance with FDT’s instructions.
        </p>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 9</span>
          <span>IMPORTANT NOTES</span>
        </header>

        <p>
          Other than as expressly set out in this Agreement, neither FDT or its Affiliates, nor their respective
          officers, directors, employees, suppliers or agents, make any specific promises about the FD Card or the Card
          Services. FDT and its Affiliates do not make any commitments about the quality, safety, legality or any other
          aspect of any goods or services purchased with FD Card. FDT and its Affiliates are not liable for any Losses
          arising from any merchant refusing to accept FD Card, or any automated teller machine failing to dispense
          cash. FDT and its Affiliates do not in any way guarantee, and disclaims all representations and warranties,
          that the Card Services or any services any third-party provide in connection with this Agreement will be
          error-free, available or uninterrupted, or that they are or will be compatible with, or operate in, any
          computer operating system, network or system configuration or any other environment (as the case may be). The
          Client hereby waives irrevocably any rights or remedies it may otherwise have had in respect of any of the
          same. FDT may suspend, withdraw, discontinue the use of FD Cards or any part of the Card Services without
          notice or assigning any reasons thereof. FDT and its Affiliates will not be liable to the Client if, for any
          reason, the FD Card is unable to be used at any time or for any period.
        </p>

        <p>
          FD Card and the Card Services are provided strictly on an “as is” basis. Except as expressly set out in this
          Agreement, all conditions, terms, obligations, undertakings, representations and warranties, whether express,
          implied, statutory, by common law, custom, trade usage, course of dealing or other, including any express or
          implied undertakings or warranties of satisfactory quality, merchantability, suitability, reasonability,
          fitness for a particular purpose or non-infringement of third-party rights, are hereby excluded and disclaimed
          to the highest and maximum extent allowed under the Applicable Laws.
        </p>

        <p>
          FDT, its agents, assigns, successors, service providers and their respective Affiliates will not lose any of
          their rights if FDT delays in taking any action for any reason or if FDT does not notify the Client. For
          example, FDT may waive interest charges or fees without notifying the Client and without losing its right to
          charge them in the future. FDT may always enforce its rights later and may take other actions not listed in
          this Agreement if the Applicable Laws allow them. The Client does not have to receive notice from FDT of any
          delay, demand or dishonor. FDT may proceed against the Client before proceeding against someone else.
        </p>

        <p>
          To the fullest extent permitted by the Applicable Laws, the Client shall be responsible and hold FDT (and its
          officers, directors, employees, suppliers, agents and Affiliates) harmless from any and all Losses, damage,
          liabilities, claims, costs and expenses due to, or arising out of or in connection with, its breach of this
          Agreement or any Applicable Laws, its or its Authorized Users’ use of the FD Card or the Card Services or any
          fraud by it or its Authorized Users. This provision shall continue to be in force even after the Client and
          its Authorized Users cease using the FD Card and the Card Services.
        </p>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 10</span>
          <span>ACCOUNT DEFAULT</span>
        </header>

        <ol>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Term.</span> The initial term of this Agreement shall be one (1) year from the
            Effective Date, unless separately agreed upon by the Parties. The initial 1-year term shall be extended
            automatically for a one (1) year renewal term unless a Party provides written notice of at least thirty (30)
            days prior to the expiration of the initial term of its request not to extend it.

            <p>
              Without prejudice to any other rights and remedies available to it under this Agreement, FDT may at any
              time terminate this Agreement or the Card Services, or suspend the provision of the Card Services, by
              written notice to the Client if:
            </p>

            <ol class="cards-disclaimer__low-alpha-list">
              <li>
                the Client commits a breach of any of the provisions or its obligations under this Agreement or any of
                the General Agreements;
              </li>

              <li>
                the Client becomes insolvent, or there is an occurrence of any circumstance, event or series of
                circumstances or events that, to FDT’s judgment, adversely affects the Client’s ability to meet its
                current or future liabilities (or potential liabilities) or obligations under this Agreement;
              </li>

              <li>
                the Client ceases, or there is a reasonable likelihood of it ceasing, to carry on all or a material part
                of its business, including any changes to the information provided in the Application Form;
              </li>

              <li>
                FDT becomes entitled to enforce any set-off, Security Collateral or guarantee granted pursuant to this
                Agreement, or such Security Collateral or guarantee is not provided within the required timeframe;
              </li>

              <li>
                there is a threatened or actual withdrawal, removal, termination or unenforceability of the Security
                Collateral or guarantee granted pursuant to this Agreement;
              </li>

              <li>
                FDT is required to do so under any Applicable Laws, or by the direction of a Regulatory Authority, the
                Card Network or the Principal Issuer, including any action or made statements, orders, requests,
                directives or demands regarding the activities of the Client or its Authorized Users;
              </li>

              <li>
                FDT determines, in its sole and absolute discretion, that the Client or its Authorized Users has engaged
                in an activity that causes a significant risk of financial, legal or regulatory, or reputational harm to
                FDT, the Principal Issuer or the Card Network, including due to any changes to the information provided
                in the Application Form;
              </li>

              <li>
                FDT believes, in its sole and absolute discretion, that a Transaction, this Agreement or the performance
                of it, or the Client’s business activities may be contrary to any Applicable Laws, including Sanctions;
                or
              </li>

              <li>
                there is a withdrawal or termination of any license, permission or authorization required to operate the
                Client’s business
              </li>
            </ol>

            <p>
              Without prejudice to any other rights and remedies available to it under this Agreement, FDT may also
              terminate this Agreement or the Card Services, or suspend the provision of the Card Services, (i) by
              giving the Client not less than thirty (30) days’ prior written notice without assigning any reasons
              thereof; or (ii) immediately upon written notice to the Client if the Client’s Card Accounts have been
              inactive, including where no Transaction has occur on the FD Cards or Card Accounts for at least twelve
              (12) consecutive weeks.
            </p>
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Effects of Termination.</span> Upon termination of this Agreement, FDT shall
            immediately discontinue the operation of the Card Services provided to the Client. In the event this
            Agreement is terminated by FDT due to a breach by the Client, the Client shall pay liquidated damages to FDT
            in an amount equals to (i) the total minimum fees payable to FDT as stipulated in the Fee Schedule for the
            remaining months of the term of this Agreement; and (ii) the sum total of all fees (including any fees
            associated with any Transactions incurred by the Client or its Authorized Users that were due and payable to
            FDT) derived from the Client for the twelve (12) months immediately prior to the termination.

            <p>
              These liquidated damages are agreed upon by the Parties as a reasonable estimate of the anticipated harm
              and not as a penalty. The Client acknowledges that such damages are difficult to ascertain, and the
              agreedupon liquidated damages represent a fair and reasonable compensation to FDT for the harm caused by
              the early termination of the Agreement. The payment of liquidated damages is without prejudice to any
              other rights or remedies that FDT may have under this Agreement, the General Agreements or the Applicable
              Laws. This clause shall survive the termination of this Agreement and the General Agreements for any
              reason.
            </p>

            <p>
              In no event will termination of this Agreement relieve the Client of its obligation to pay any fees
              payable to FDT for the period prior to the date of termination.
            </p>

            <p>
              Except as otherwise provided in this Agreement, upon termination or expiration of this Agreement, all
              rights and obligations of the Parties will immediately cease and terminate, and no Party will have any
              further obligation to the other Party with respect to this Agreement; provided, however, that all
              provisions of this Agreement which by their nature extend beyond the expiration or termination of this
              Agreement, and any obligations or liabilities incurred by the Client prior to termination, shall survive
              termination or expiration of this Agreement.
            </p>

            <p>
              Additionally, promptly upon termination of this Agreement, the Client shall: (a) return to, destroy, erase
              or delete all tangible property of FDT, including any Confidential Information or intellectual property of
              FDT, whether held in electronic form or otherwise, at the instruction of FDT; (b) cease any use of the
              Confidential Information of FDT or any part thereof; and (c) certify in writing to FDT that it has
              complied with the requirements of this provision, provided that FDT may retain documents and materials
              containing, reflecting, incorporating or based on the Confidential Information of the Client to the extent
              required by law or any applicable governmental or regulatory authority.
            </p>

            <p>
              For a period of six (6) months following the termination or expiration of this Agreement, the Client shall
              not engage in any business or enter into any agreements that directly compete with the services provided
              by FDT or any of its Affiliates, including the Card Services or other services stipulated in any other
              agreements that the Client may have separately with FDT or any of its Affiliates in Hong Kong SAR. This
              paragraph shall survive the termination or expiration of this Agreement.
            </p>
          </li>
        </ol>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 11</span>
          <span>REPRESENTATIONS AND WARRANTIES</span>
        </header>

        <p>
          The Client warrants, represents and undertakes to FDT on an ongoing basis for as long as this Agreement
          remains in effect that:
        </p>

        <ol class="cards-disclaimer__low-alpha-list">
          <li>
            the Client has full capacity and authority to enter into and to perform the obligations of this Agreement;
          </li>
          <li>this Agreement is executed by a duly authorized representative of the Client;</li>
          <li>
            there are no actions, suits or proceedings or investigations of any kind pending or threatened against or
            affecting the Client before any court or administrative body or arbitration tribunal that might affect its
            ability to meet and carry out its obligations under this Agreement;
          </li>

          <li>
            once duly executed, the terms of this Agreement will constitute legal, valid and binding obligations of the
            Client;
          </li>

          <li>
            these terms do not violate or create a default under any Applicable Law, or the Client’s articles of
            association or any other organizational documents or any contractual provisions binding on or affecting it;
          </li>

          <li>
            the Client maintains and will continue to maintain an effective compliance program that addresses the risk
            of money laundering, terrorist financing, Sanctions and fraud as per the standards prescribed by the
            relevant Regulatory Authorities and the Applicable Laws to which it is subject;
          </li>

          <li>
            the use of the Card Services by the Client complies with the Applicable Laws, and the Client has obtained,
            and will continue to hold, any applicable licenses that it is required to obtain and maintain under the
            Applicable Laws to carry on any business activities;
          </li>

          <li>
            the Client will perform and procure the performance of the obligations under this Agreement in compliance
            with all Applicable Laws;
          </li>

          <li>
            all information provided by the Client to FDT about itself, its Authorized Users, its representatives, its
            connected parties, its business, its clients or recipients, is true and accurate, and that relevant
            authorization or consent has been from these individuals;
          </li>

          <li>
            the Client, its Authorized Users and its Affiliates are not and have not been under any investigation or had
            an approval, license or authorization restricted, suspended or terminated by any governmental or Regulatory
            Authorities or body because of the suspicion of or confirmed involvement in any way with any fraudulent or
            suspicious financial activities or for any AML/CFT lapses;
          </li>

          <li>
            the Client, any of its legal representatives, connected parties and Authorized Users, have never been
            convicted of any crime relating to dishonesty, fraud or financial crimes;
          </li>

          <li>
            neither the Client or any of its clients or Authorized Users are entities or individuals that are the
            subject or target of any domestic or international sanctions;
          </li>

          <li>
            the Client, its Authorized Users, its employees, its service providers, contractors and agents will at all
            times act consistently with the terms of this Agreement;
          </li>

          <li>
            the Client is not entering into this Agreement with the intention of engaging in criminal or fraudulent
            activities;
          </li>

          <li>
            the Client is compliant with and in good standing under the Applicable Laws of the territory of its
            operation, formation or organization;
          </li>

          <li>
            the Client and its Affiliates have not and will not offer, promise, make or authorize the offering,
            promising or making of any payment or transfer of anything of value (including cash or cash equivalents),
            directly or indirectly, to any governmental official or other person with whom it or any of its Affiliates
            deals in a commercial transaction for the purpose of seeking improper action, inaction, influence, benefit,
            or undue advantage from such person in order to benefit itself, its Affiliates’ or FDT’s business; and
          </li>

          <li>
            the Client has adopted and will continue to maintain an effective anti-corruption and anti-bribery policy,
            which at a minimum, prohibits the direct or indirect offer, authorization, or payment of money or anything
            of value to secure an undue advantage or improperly influence a government official or business partner, and
            otherwise provides controls to assure compliance with the warranties contained in this provision and the
            keeping of accurate and complete books, records and accounts of its business activities under this
            Agreement.
          </li>
        </ol>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 12</span>
          <span>DISCLAIMER & LIMITATION OF LIABILITIES</span>
        </header>

        <ol>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Disclaimer of Liabilities.</span> FDT will not be liable for any failure to
            perform (nor any defective or delayed performance of) any of its obligations under this Agreement, if and to
            the extent that such failure, defect or delay is due to: (a) the Client’s breach of this Agreement, or any
            negligent, wrongful or bad faith acts or omissions of the Client; (b) a suspension of any Card Services
            under or in connection with any provisions stipulated in this Agreement; and (c) FDT complying with any of
            its obligations under any agreement with the Principal Issuer, the Card Network Rules or Applicable Laws.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Limitation of Liabilities.</span> Nothing in this Agreement shall operate to limit
            or exclude a Party’s liability: (a) for death or personal injury caused by its negligence, or that of its
            employees, agents or subcontractors; (b) for gross negligence, willful misconduct, fraud or fraudulent
            misrepresentation by the Client; (c) for breach of any Card Network Rules, if applicable; (d) for the
            Client’s obligations under the Card Network Rules, including fines or penalties under the Card Network
            Rules; (e) to pay any fees or other monetary amounts due under this Agreement; or (f) which may not be
            limited or excluded by law.

            <p>
              Neither Party shall have any liability to the other Party for any indirect, punitive, incidental, special,
              consequential or exemplary damages, for any Loss of profits, Loss of revenue, Loss of business
              opportunity, other Losses or any other damages arising out of, relating to or in connection with, this
              Agreement or the Client’s use of the Card Services, even if such damages or Losses are known or
              foreseeable or should have been known and regardless of whether a Party has been advised of the
              possibility of such damages or Losses, including any damages or Losses resulting from a Party’s use or
              inability to use any of the Card Services or from the unavailability of the computer systems used by FDT
              to provide Card Services to the Client
            </p>

            <p>
              In addition to the general exclusion under the Client Agreement, except for each Party’s liability as
              stipulated in this section above, the Client agrees to limit any liability of FDT under this Agreement to
              its direct and documented damages, and the Client further agrees that under no circumstances will any such
              liability exceed in aggregate of the amount of considerations paid by the Client to FDT during the three
              (3) months period immediately preceding the event that gave rise to its claim for damages. These
              limitations on the liability of FDT will apply to the Client regardless of the legal theory on which the
              claim is based, including contract, tort (including negligence), strict liability or any other theory or
              basis.
            </p>
          </li>
        </ol>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 13</span>
          <span>INDEMNIFICATION</span>
        </header>

        <p>
          In addition to the general indemnity under the Client Agreement, the Client will, at its own expense, defend,
          indemnify and hold FDT and its representatives and Affiliates harmless from and against any and all Losses
          asserted against or incurred by FDT arising out of, relating to or resulting from, either directly or
          indirectly: (a) a breach of any term, condition, obligation, representation or warranty in this Agreement; (b)
          any fraud, potential fraud, gross negligence or willful misconduct; (c) any data security or technological
          breaches; (d) any actual or alleged infringement, violation or misappropriation of a third party's
          intellectual property or proprietary rights; (e) any chargebacks or Transactions that becomes subject to a
          dispute or chargeback process; (f) any violation of, or any usage of FD cards that violates, any Applicable
          Laws or Card Network Rules, including incidents of over-clearing involving an authorization for a lower amount
          followed by a subsequent charge for a higher amount, where such practice is intended to mislead FDT; (g) any
          actual or attempted Transactions, whether authorized or unauthorized; (h) any other actions, inactions or
          omissions, including the acts or omissions of any of the Client’s Authorized Users, directors, shareholders,
          officers, employees, Affiliates, agents, contractors, service providers or personnel; and (g) any inquiries,
          investigation or actions by any governmental body related to the Card Services.
        </p>

        <p>
          This section does not and will not limit the Client’s responsibility and liability to FDT for any other
          amounts for which the Client is responsible pursuant to any other provisions of this Agreement.
        </p>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 14</span>
          <span>PERSONAL DATA PROTECTION & SECURITY MEASURES</span>
        </header>

        <ol>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Personal Data Protection.</span> In the performance or receipt of the Card
            Services, each Party shall comply with all Applicable Laws relating to personal data, data protection or
            privacy and security standards mandated by any Regulatory Authorities. Where FDT receives any Personal Data
            from the Client or its Affiliates pursuant to any contract or arrangement or under this Agreement, the
            Client acknowledges and agrees that FDT shall only ensure that it complies with the Personal Data (Privacy)
            Ordinance (Cap. 486) or any other Applicable Laws in the territory that FDT operates in relating to privacy
            and protection of Personal Data and that the Personal Data is provided with a standard of protection that is
            comparable to that under the Applicable Laws of the said territory. FDT shall deal with the Personal Data
            solely for the permitted purpose as stipulated in and to fulfil the obligations stipulated in this
            Agreement.

            <p>
              The Client hereby represents and warrants that it has, and at all times shall have: (a) obtained all
              necessary rights, lawful basis and, where necessary, consents under the Applicable Laws; and (b) provided
              all its Authorized Users with appropriate, clear and complete privacy notices in accordance with the
              relevant data privacy laws, to disclose to FDT and its Affiliates, or allow FDT and its Affiliates to
              collect, use, retain, disclose and otherwise process for the purposes of providing the Card Services, or
              authorizes FDT and its Affiliates to collect, including information that FDT and its Affiliates may
              collect directly using cookies or other similar means, in connection with FDT’s provision of the Card
              Services.
            </p>

            <p>
              The Client hereby further acknowledges and agrees of the same as aforementioned to FDT’s and its
              Affiliates’ disclosure of its and its Authorized Users’ Personal Data to the Card Network, the Principal
              Issuer, Regulatory Authorities, Payment Processing Partners and VA Partners to the extent necessary for
              the provision of the Card Services, in accordance with FDT’s Privacy Policy.
            </p>
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Security Measures.</span> The Client shall be responsible for its own data
            security and shall promptly notify FDT in the event of any Security Breach. The Client agrees to cooperate
            with FDT in the event of an investigation of suspected unauthorized use of the Card Services or Security
            Breach.

            <p>
              The Client agrees that Program Data belongs to FDT, and it may not use Program Data for any purpose
              unrelated to its use of the Card Services without FDT’s express written consent. The Client agrees to
              maintain administrative, physical and technical safeguards for protection of the security, confidentiality
              and integrity of the Transaction Data. Those safeguards will include measures for preventing access, use,
              modification or disclosure of data by the Parties and their Affiliates except: (a) for FDT to provide the
              Card Services, and prevent or address service or technical problems; (b) for FDT to mitigate fraud,
              financial Loss or other harm to the Client, other users, FDT, the Principal Issuer and the Card Network;
              (c) for FDT to analyze, develop and improve the Card Services, systems and tools; (d) as compelled under
              the Applicable Laws or in response to a Regulatory Authority or law enforcement request, subpoena or
              regulatory inquiry; or (e) as expressly permitted in writing.
            </p>

            <p>
              The Client understands that the internet is not a secure means of transmission, and acknowledges and
              accepts that: (a) any and all transmissions over the internet, whether the information being transmitted
              is in encrypted form or otherwise, involves the risks of possible interception and transmission by third
              parties or alteration or unauthorized use or misuse thereof, for whatever purposes; and (b) sending such
              information over the internet involves the risk of information also being received by persons who may not
              be in the employ of or engaged by the Party to which the transmission is addressed or by other unintended
              recipients.
            </p>

            <p>
              Notwithstanding the foregoing, the Client agrees to implement a technology risk management framework and
              controls as may be required for compliance with the Applicable Laws, to abide by reasonable security
              protocol or programs that may be requested in writing by FDT in order to maintain the integrity of
              computer systems in accordance with prevailing industry standards from time to time, and to minimize the
              incidents of unauthorized access or alteration of data.
            </p>

            <p>
              Each Party will be responsible for acting only on instructions which are actually received from the other
              Party through the internet or applicable computer application program.
            </p>
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Right to Security & Technological Audits.</span> If a Security Breach, leak, loss
            or compromise of Personal Data or Transaction Data has occurred on the Client’s systems, website, mobile
            applications or any other applications affecting its compliance with this Agreement, FDT may appoint a
            third-party auditor to conduct a security audit of the Client’s systems and facilities, and the Client shall
            permit access and fully cooperate with any requests for information or assistance that the auditor makes to
            it as part of the security audit.
          </li>
        </ol>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 15</span>
          <span>CONFIDENTIALITY</span>
        </header>

        <ol>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Publicity & Attribution.</span> FDT may publicly identify and attribute the Client
            as a client of FDT on all types of media channels and in FDT’s marketing materials, including the use of the
            Client’s logo and samples of its use of the Card Services. Neither Party will imply or suggest any untrue
            sponsorship, endorsement or affiliation between the Parties.
          </li>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold"> Confidential Information.</span> Each Party agrees: (i) to maintain the other
            Party’s Confidential Information in strict confidence and to take at least reasonable precautions to protect
            such Confidential Information (including all precautions against unauthorized access, misuse, theft, damage
            or destruction the receiving Party employs with respect to its own similar Confidential Information); (ii)
            not to disclose such Confidential Information to any third party; and (iii) not to use such Confidential
            Information for any purpose except for the purposes set forth in this Agreement. Each Party may disclose the
            other Party’s Confidential Information to its employees, officers, directors, service providers, Affiliates,
            advisors or consultants who have a bona fide need to know such Confidential Information for the purposes set
            forth in this Agreement, but solely to the extent necessary to pursue such purpose and for no other purpose;
            provided, that each such employees, officers, directors, service providers, Affiliates, advisors or
            consultants first executes a written agreement (or is otherwise already bound by a written agreement) that
            contains use and nondisclosure restrictions at least as protective of the other Party’s Confidential
            Information as those set forth in this Agreement, and provided that each Party is and remains responsible
            for the acts and omissions of its employees, officers, directors, service providers, Affiliates, advisors
            and consultants hereunder.
          </li>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Exceptions.</span> The obligations and restrictions in the above will not apply to
            any information or materials that the receiving Party can show: (i) were, at the date of disclosure or have
            subsequently become, generally known or available to the public through no act or failure to act by the
            receiving Party; (ii) were rightfully known by the receiving Party prior to receiving such information or
            materials from the disclosing Party; (iii) are rightfully acquired by the receiving Party from a third party
            who has the right to disclose such information or materials; or (iv) are independently developed by the
            receiving Party without access to any Confidential Information of the disclosing Party.
          </li>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Compelled Disclosure</span> Nothing in this Agreement will be deemed to restrict a
            Party from disclosing the other Party’s Confidential Information to the extent required or requested by any
            Applicable Laws, stock exchanges, Regulatory Authorities, government/law enforcement agencies or court of
            competent jurisdiction; provided that the disclosing Party notifies the other, to the extent legally
            permissible, of the information to be disclosed as early as reasonably practicable before the disclosure and
            takes any protection order or all reasonable action to avoid or limit the disclosure.
          </li>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Return of Confidential Information.</span> Except as otherwise provided in this
            Agreement or agreed by the Parties, upon termination or expiration of this Agreement, each Party shall
            promptly return to the other Party or destroy all Confidential Information of the other Party in its
            possession or control; provided that FDT may retain copies of the Client’s Confidential Information for
            purposes of any disputes relating to such termination.
          </li>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">No License.</span> Except as otherwise stated herein, (i) all Confidential
            Information remains the sole and exclusive property of the disclosing Party; and (ii) each Party
            acknowledges and agrees that nothing in this section will be construed as granting any rights to the
            receiving Party, by license or otherwise, in or to any Confidential Information of the disclosing Party, or
            any patent, copyright or other intellectual property or proprietary rights of the disclosing Party, except
            as specified in this Agreement.
          </li>
        </ol>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 16</span>
          <span>INTELLECTUAL PROPERTY</span>
        </header>

        <ol>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">. FDT Marks.</span> “First Digital” and all logos related to the Card Services,
            including any and all trade names, trademarks, service marks, slogans, copyrights, software, business
            processes and other present and future intellectual property rights of FDT or any of its Affiliates, in
            whatever media, whether registered or unregistered, created or developed by FDT or any of its Affiliates
            pursuant to the performance of this Agreement (“IP Rights”) are and shall be the sole and absolute property
            of FDT and its Affiliates. The Client may not copy, imitate or use any of the IP Rights or property without
            FDT’s express prior written consent.
          </li>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Software.</span> All proprietary rights in and to the software, including any
            programs, processing systems or any related materials, including any technical documentation, as well as any
            modifications, improvements or upgrades of the same, are the sole and exclusive property of FDT and its
            Affiliates, none of which may, notwithstanding termination of this Agreement, be revealed, sold, disclosed,
            shared or otherwise communicated, directly or indirectly, by the Client to any third party.

            <p>
              The Client hereby acknowledges and agrees that all FDT’s infrastructure used by FDT pursuant to the
              performance of this Agreement is the exclusive property of FDT and its Affiliates, and that FDT and its
              Affiliates own all intellectual property rights therein. The Client may not (i) claim or register
              ownership of the rights in FDT’s infrastructure, software and systems on its behalf or on behalf of
              others; (ii) sublicense any rights in FDT’s infrastructure, software and systems; (iii) import or export
              any part of FDT’s infrastructure, software and systems to any person or territory that is the target of
              domestic or international sanctions; (iv) use FDT’s infrastructure, software and systems in a manner that
              violates this Agreement or any Applicable Laws; (v) conduct or perform any vulnerability or penetration
              testing or any other similar tests on or through FDT’s infrastructure, software and systems; or (v)
              attempt to do any of the foregoing.
            </p>
          </li>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Ideas & Feedback.</span> If the Client submits comments or ideas to FDT, FDT will
            presume that the submission was voluntary, unsolicited by FDT, and delivered to FDT without any restrictions
            or confidentiality obligations on FDT’s use of the comments or ideas. The Client hereby assign all rights,
            titles and interest in and to any comments or ideas it submits to FDT, whether or not the submitted comments
            or ideas are protectable by any applicable intellectual property laws in the territory of its or FDT’s
            operation. The Client agrees that it will assist in perfecting such assignment at the request of FDT. The
            Client also agrees that FDT has no fiduciary or any other obligation to it in connection with any idea or
            comments it submits to FDT, and that FDT is free to use, copy, display, perform, distribute, modify and
            re-format the comments or ideas in any manner that FDT may determine, without any attribution or
            compensation to the Client.
          </li>
        </ol>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 17</span>
          <span>RECORD-KEEPING & AUDITS</span>
        </header>

        <ol>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Record-Keeping.</span> The Parties shall maintain a record-keeping policy and keep
            all proper records of all Authorized Users, including any Transaction Data performed under the Card
            Services, and shall preserve and maintain the records in its custody for a period of five (5) years and all
            such records shall be kept confidential and made available for inspection by either Party or any Regulatory
            Authorities.
          </li>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Audits.</span> The Client acknowledges that its business practices may be subject
            to review and audit by the Regulatory Authorities. The Client further acknowledges and agrees that it shall
            cooperate with, and submit to, any examination, inquiry, information request, site visit or the like, to the
            extent required by any Regulatory Authority, Card Network, Principal Issuer, Payment Processing Partner or
            VA Partner with audit examination or supervisory authority over FDT and its service providers, to the
            fullest extent requested by such Regulatory Authority, Card Network, Principal Issuer, Payment Processing
            Partner or VA Partner. The Client shall cooperate with FDT fully with all information requests made by FDT
            and utilize best effort to provide all information requests made by any Regulatory Authority, Card Network,
            Principal Issuer, FDT Payment, Processing Partner or VA Partner within twenty-four (24) hours upon receipt
            of the request for information.

            <p>
              FDT reserves the right to, or to engage a third-party auditor to, audit the Client’s use of the Card
              Services and performance under this Agreement.
            </p>

            <p>
              The Client agrees and undertakes to act upon and implement any reasonable recommendations made by FDT
              (including any recommendations made by any Regulatory Authority, Card Network, Principal Issuer, Payment
              Processing Partner or VA Partner that FDT is subject to, and FDT’s appointed auditors) and the cost of
              implementing those recommendations shall be borne by the Client entirely. Where an audit undertaken by FDT
              reveals the Client’s breach or its failure to comply with any obligations as required by any Applicable
              Laws, the Card Network Rules or this Agreement, the Client’s agrees to pay any costs and charges incurred
              by FDT in respect of such audit.
            </p>
          </li>
        </ol>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 18</span>
          <span>GOVERNING LAW AND JURISDICTION</span>
        </header>

        <ol>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Governing Law.</span> This Agreement and any dispute or claim (including
            non-contractual disputes or claims) arising out of or in connection with it or its subject matter or
            formation shall be governed by and construed in accordance with the law of Hong Kong SAR.
          </li>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Jurisdiction.</span> Each Party irrevocably agrees that the courts of Hong Kong
            SAR shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or
            claims) arising out of or in connection with this Agreement or its subject matter or formation.
          </li>
        </ol>
      </li>

      <li class="cards-disclaimer__list-item">
        <header class="cards-disclaimer__section-head">
          <span>SECTION 19</span>
          <span>MISCELLANEOUS</span>
        </header>

        <ol>
          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Notice.</span> Except as otherwise specified in this Agreement, any notice or
            other communication given to a Party under or in connection with this Agreement shall be in writing and
            shall be: (i) delivered by hand or by pre-paid first-class post or other next working day delivery service
            at its registered office (if a company) or its principal place of business (in any other case); or (ii) sent
            by email. Any notice or communication shall be deemed to have been received: (i) if delivered by hand, on
            signature of a delivery receipt; and (ii) if sent by pre-paid first-class post or other next working day
            delivery service, at 9:00 am on the second Business Day after posting; and (iii) if sent by email, one (1)
            Business Day after the email is sent.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Severability.</span> If any provision or part of a provision of this Agreement is
            deemed illegal, invalid or unenforceable, such illegality, invalidity or unenforceability shall not affect
            the legality, validity and enforceability of any other provision of this Agreement, which shall be construed
            as if such illegal, invalid or unenforceable provision had not been included in this Agreement, unless the
            severability of such illegal, invalid or unenforceable provision would destroy the underlying business
            purposes of this Agreement, in which case the Parties shall negotiate in good faith to substitute such
            illegal, invalid or unenforceable provision with a legal, valid and enforceable provision which carries out
            the original intent of the Parties.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Relationship of the Parties.</span> The Parties acknowledge and agree that this
            Agreement does not create a fiduciary relationship, partnership, joint venture or relationships of trust or
            agency between the Parties, and that the Card Services are provided by FDT as an independent contractor.
            Each Party may not, and may not attempt to make any representation, warranty or commitment on behalf of the
            other Party. Each Party confirms it is acting on its own behalf and not for the benefit of any other person.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Waiver of Rights.</span> No failure or delay of FDT in exercising any right or
            remedy under this Agreement constitutes a waiver of that right. Any waiver of any right is limited to the
            specific instance. The exclusion or omission of any provision or term of this Agreement shall not constitute
            a waiver of any right or remedy FDT may have under the Applicable Laws. Whenever the consent of FDT is
            required under this Agreement, the granting of such consent by FDT in any one instance shall not constitute
            continuing consent to subsequent instances where such consent is required and in all cases such consent may
            be granted or withheld in the sole and absolute discretion of FDT.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Recording.</span> The Client consents to telephonic or electronic monitoring or
            recordings of any communications between the Parties for security and quality of service purposes and agree
            that FDT may produce telephonic or electronic recordings or computer records as evidence in any proceedings
            brought in connection with this Agreement.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Entire Agreement.</span> The Parties agree that this Agreement supersedes and
            extinguishes all previous agreements, promises, assurances, warranties, representations and understandings
            between them, whether written or oral, relating to its subject matter, excluding the General Agreements. The
            Client agrees that FDT is responsible for the performance of only those duties set forth in this Agreement.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Amendments.</span>
            FDT may at any time and at its sole and absolute discretion, without notice or assigning any reasons
            thereof, amend, modify, vary, delete or supplement this Agreement or any additional or specific terms or
            conditions relating to any Card Account or the Card Services. FDT may, from time to time, publish a revised
            version of this Agreement or any such additional or specific terms or conditions at FDT’s offices, FDT
            Website, Client Portal or other Online Facilities. The Client’s continued use of the FD Card or the Card
            Services, or maintenance or operation of a Card Account, shall constitute acceptance of the revised
            Agreement and any such additional or specific terms or conditions in totality and to be legally bound by the
            terms and conditions as amended. If the Client does not agree to any of the terms of this Agreement or the
            revised or amendments thereof, the Client should immediately terminate, cease the use of, and cancel all FD
            Cards and the Card Services.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Non-Exclusivity.</span> Nothing in this Agreement shall be deemed or interpreted
            as to restrict or prejudice the rights of FDT to enter into similar services agreements, or to subscribe or
            provide any services to any entities in any territory. During the term of this Agreement, the Client agrees
            that FDT shall be the exclusive provider of the Card Services, and it shall not seek or engage with any
            other suppliers or service providers for the same services. The Client further agrees not to circumvent,
            avoid, bypass, or obviate FDT, directly or indirectly, in dealings with any third-party service providers
            that may provide services or support FDT in connection with the offering of the Card Services.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Assignment.</span> FDT may transfer, sell or assign the Card Services, this
            Agreement or any other rights or obligations, in whole or in part, under this Agreement to any successors or
            assigns. The Client may not transfer, sell or assign any of its obligations under this Agreement to another
            person or entity without FDT’s prior written consent. Any assignment in violation of the foregoing will be
            null and void from the beginning. All representations, warranties, covenants and agreements by or on behalf
            of the Client contained in this Agreement shall bind its successors and assigns and shall inure to the
            benefit of FDT and its successors and assigns.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">No Third Party Rights.</span> Unless expressly stated otherwise, this Agreement
            does not give rise to any rights under the Contracts (Rights of Third Parties) Ordinance (Cap. 623) to
            enforce or enjoy the benefit of any term of this Agreement. The rights of the Parties to rescind or vary
            this Agreement are not subject to the consent of any other person.
          </li>

          <li class="cards-disclaimer__list-sub-item">
            <span class="body-2-bold">Electronic Signatures.</span> The Parties acknowledge and agree that this
            Agreement and related documents entered into in connection with this Agreement may be executed by means of
            electronic signature, which shall be considered as an original signature for all purposes and shall have the
            same force and effect as an original signature. “Electronic Signature” means any electronic proof of
            acceptance combined with metadata, such as date/time stamps and IP addresses, in a process attached to or
            logically associated with this Agreement and executed and adopted by each Party with the intent to sign this
            Agreement (including the use of checkboxes and typing of full name). Evidence of the electronic signature
            will be captured and stored in electronic format in FDT’s database, and that electronic signature will
            create and evidence a binding contract between the Parties.
          </li>
        </ol>
      </li>
    </ol>
  </div>
</div>
