import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { MatTabChangeEvent } from '@angular/material/tabs';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { FdtFormFieldModule, FdtHintDirective } from '@1stdigital/ng-sdk/form-field';
import { FdtSkeletonDirective } from '@1stdigital/ng-sdk/loading-indicators';
import { FdtTabModule } from '@1stdigital/ng-sdk/tab';
import { InboxMessage } from '@app/core/models/interfaces/inbox.interface';
import { ClientService, InboxStoreService } from '@app/core/services';
import { EmptyViewComponent } from '@app/shared/empty-view/empty-view.component';
import { AppFormModule } from '@app/shared/form/form.module';
import { InfoBlockComponent } from '@app/shared/info-block/info-block.component';
import { assetTypeMapping } from '@app/shared/models';
import { debounceTime, delay, finalize, first, mergeMap, Subject, Subscription } from 'rxjs';

import { InboxMessageComponent } from '../inbox-message/inbox-message.component';
import { InboxMessagesComponent } from '../inbox-messages/inbox-messages.component';
import { InboxSkeletonComponent } from '../inbox-skeleton/inbox-skeleton.component';

@Component({
  selector: 'app-inbox-message-layout',
  standalone: true,
  imports: [
    EmptyViewComponent,
    InfoBlockComponent,
    FdtButtonModule,
    FdtFormFieldModule,
    AppFormModule,
    FdtHintDirective,
    FdtTabModule,
    InboxMessageComponent,
    FdtSkeletonDirective,
    InboxMessagesComponent,
    InboxSkeletonComponent,
  ],
  templateUrl: './inbox-message-layout.component.html',
  styleUrl: './inbox-message-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxMessageLayoutComponent implements OnInit {
  @Output() closeWidget = new EventEmitter();

  unreadMessagesVMSignal = toSignal(this.inboxStore.unreadMessageStore$, { requireSync: true });
  readMessagesVMSignal = toSignal(this.inboxStore.readMessageStore$, { requireSync: true });
  markAllReadLoadingSignal = signal(false);

  tabSelectedIndex = 0;
  assetTypeMapping = assetTypeMapping;

  private loadMoreUnreadMessagesSubscription?: Subscription;
  private readonly clearReadNotifications$ = new Subject<string>();
  private activeServiceEntityId: number | null = null;

  constructor(
    private readonly inboxStore: InboxStoreService,
    private readonly router: Router,
    private readonly clientService: ClientService
  ) {
    this.clientService.activeServiceEntity$
      .pipe(takeUntilDestroyed())
      .subscribe((serviceEntity) => (this.activeServiceEntityId = serviceEntity?.id ?? null));
  }

  ngOnInit(): void {
    this.inboxStore.wsMessage$
      .pipe(
        // Delay the fetch to allow time for the new message to be processed and included.
        // Without this delay, fetching immediately may not include the latest unread message
        // because the data hasn't been fully updated yet.
        delay(300),
        mergeMap(() => this.inboxStore.getMessages('unread'))
      )
      .subscribe();
  }

  loadMoreReadMessages(): void {
    this.inboxStore.getMessages('read', this.readMessagesVMSignal().pageInfo.startCursor).subscribe();
  }

  loadMoreUnreadMessages(): void {
    this.loadMoreUnreadMessagesSubscription = this.inboxStore
      .getMessages('unread', this.unreadMessagesVMSignal().pageInfo.startCursor)
      .subscribe();
  }

  onSelectedTabChange(event: MatTabChangeEvent): void {
    this.tabSelectedIndex = event.index;

    if (this.tabSelectedIndex === 1) {
      this.inboxStore.getMessages('read').subscribe();
    }
  }

  onMarkAllAsRead(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    // Setting this to true disables the "Mark All as Read" button
    // and displays an overlay with the class `inbox-list__container-overlay`
    // to block scrolling and interactions with the container.
    this.markAllReadLoadingSignal.set(true);

    this.loadMoreUnreadMessagesSubscription?.unsubscribe();
    this.inboxStore
      .markAllAsRead()
      .pipe(finalize(() => this.markAllReadLoadingSignal.set(false)))
      .subscribe();

    this.listenClearReadNotifications();
  }

  onMarkAsRead(message: InboxMessage): void {
    this.inboxStore.markAsRead(message.messageId).subscribe();
    this.listenClearReadNotifications();
  }

  onClearRead(message: InboxMessage): void {
    this.clearReadNotifications$.next(message.messageId);
  }

  onInstructionReview(message: InboxMessage): void {
    const messageServiceEntity = message.data.requestData.serviceEntityId;

    if (!messageServiceEntity || messageServiceEntity === this.activeServiceEntityId) {
      return this.navigateToReviewInstruction(message);
    }

    this.clientService.activeClient$.pipe(first()).subscribe((client) => {
      if (client === null) {
        return;
      }

      const serviceEntity = client?.serviceEntities.find(({ id }) => id === messageServiceEntity);

      if (!client || !serviceEntity) {
        return;
      }

      this.clientService.saveClientSelectionTemporary(client);
      this.clientService.saveActiveServiceEntityTemporary(serviceEntity);
      this.clientService.emitValues(client, serviceEntity);

      this.navigateToReviewInstruction(message);
    });
  }

  private navigateToReviewInstruction(message: InboxMessage): void {
    // Perform optimistic navigation: navigate to the new route immediately before the markAsRead request completes
    this.router.navigate([
      'activity',
      message.data.requestData.instructionOperationType,
      this.assetTypeMapping[message.data.requestData.assetClass],
      message.data.requestData.instructionId,
    ]);
    this.closeWidget.emit();
    this.onMarkAsRead(message);
  }

  private listenClearReadNotifications(): void {
    // Previously, this logic used `first` to emit only when a specific condition was met.
    // The condition checked if the number of emissions (`i`) matched the `takeIndex`,
    // which represents the number of notifications being processed.
    // However, with virtual scrolling, the exact number of items rendered at a time is uncertain
    // and may vary based on the container size and scrolling behavior.
    // To prevent relying on the number of items rendered, the logic was updated to use `debounceTime`

    this.clearReadNotifications$.pipe(debounceTime(50)).subscribe((messageId) => {
      const { markingAll } = this.unreadMessagesVMSignal();
      markingAll ? this.inboxStore.removeAllReadNotifications() : this.inboxStore.removeReadNotification(messageId);
    });
  }
}
