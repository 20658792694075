import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { BASE_API_URL, BaseApiService } from '@1stdigital/ng-sdk/core';
import { DocumentType } from '@app/core/models/document-type.enum';
import { mergeMap, Observable, of, switchMap } from 'rxjs';

/* eslint-disable */

export interface UploadFileTemporaryRequest {
  formFile?: FileParameter | null;
  size?: number;
  contentType?: string | null;
  documentType?: DocumentType;
  applicationId?: number | null;
  uploadFileClientId?: number | null;
  legalEntityId?: number | null;
  fileId?: number | null;
  customMinSize?: number | null;
}

export interface TemporaryUploadedFileDto {
  key?: string;
  size?: number;
  contentType?: string;
  documentType?: DocumentType;
  userId?: string;
  fileName?: string;
  dateCreated?: string;
  fileToProcess?: File;
}

export interface FileParameter {
  data: any;
  fileName: string;
}

@Injectable({
  providedIn: 'root',
})
export class NewFileStorageService extends BaseApiService {
  private readonly baseUrl2: string = inject(BASE_API_URL);
  private http2 = inject(HttpClient);

  uploadFileTemporary(fileModel: UploadFileTemporaryRequest): Observable<TemporaryUploadedFileDto> {
    const formData = new FormData();

    ['fileName', 'size', 'key', 'contentType', 'documentType', 'fileId'].forEach((k: string) => {
      const value = (<any>fileModel)[k];

      if (value) {
        formData.append(k, value.toString());
      }
    });

    if (fileModel?.formFile) {
      formData.append(
        'FormFile',
        fileModel.formFile.data,
        fileModel.formFile.fileName ? fileModel.formFile.fileName : 'FormFile'
      );
    }

    const url = this.baseUrl2 + '/file-storage/upload-temp';
    const options_: any = {
      body: formData,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    };

    return this.http2
      .request('post', url, options_)
      .pipe(switchMap((resp) => this.processUploadFileTemporary(resp as any)));
  }

  protected processUploadFileTemporary(response: HttpResponseBase): Observable<TemporaryUploadedFileDto> {
    const status = response.status;
    let responseBlob;

    if (response instanceof HttpResponse) {
      responseBlob = response.body;
    } else if ((response as any).error instanceof Blob) {
      responseBlob = (response as any).error;
    } else {
      responseBlob = undefined;
    }

    let _headers: any = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      // here was blobToText under
    }
    return blobToText(responseBlob).pipe(
      mergeMap((_responseText: string) => {
        let result200: any = null;
        result200 = _responseText === '' ? null : (JSON.parse(_responseText) as TemporaryUploadedFileDto);
        return of(result200);
      })
    );
  }
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next('');
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = (event) => {
        observer.next((event.target as any).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}
