import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class DialogUnsavedChangesService {
  // Used to store asset transfer first step form
  private formRef: FormGroup | null = null;

  // Check for canDeactivate create-instruction guard
  get isStepFormTouched(): boolean {
    if (!this.formRef) {
      return false;
    }

    return this.formRef.touched;
  }

  setStepFormRef(formRef: FormGroup | null): void {
    this.formRef = formRef;
  }
}
