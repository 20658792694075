import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { InboxMessage, InboxMessageState } from '@app/core/models/interfaces/inbox.interface';
import { EmptyViewComponent } from '@app/shared/empty-view/empty-view.component';
import { filter, map, pairwise, throttleTime } from 'rxjs';

import { InboxMessageComponent } from '../inbox-message/inbox-message.component';
import { InboxSkeletonComponent } from '../inbox-skeleton/inbox-skeleton.component';

@Component({
  selector: 'app-inbox-messages',
  standalone: true,
  imports: [InboxMessageComponent, InboxSkeletonComponent, ScrollingModule, EmptyViewComponent],
  templateUrl: './inbox-messages.component.html',
  styleUrl: './inbox-messages.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxMessagesComponent implements AfterViewInit {
  @ViewChild(CdkVirtualScrollViewport) scroller!: CdkVirtualScrollViewport;
  @Output() markAsRead = new EventEmitter<InboxMessage>();
  @Output() clearRead = new EventEmitter<InboxMessage>();
  @Output() instructionReview = new EventEmitter<InboxMessage>();
  @Output() loadMore = new EventEmitter<void>();
  @Input({ required: true }) messagesVM!: InboxMessageState;

  constructor(private destroyRef: DestroyRef) {}

  ngAfterViewInit(): void {
    this.scroller
      .elementScrolled()
      .pipe(
        filter(() => this.messagesVM.pageInfo.hasNextPage && !this.messagesVM.isBusy),
        map(() => this.scroller.measureScrollOffset('bottom')),
        pairwise(),
        filter(([y1, y2]) => y2 < y1 && y2 < 120),
        throttleTime(200),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.loadMore.emit();
      });
  }
}
