import { AssetClasses } from '@1stdigital/ng-sdk/core';

export const enum AssetTypeEnum {
  Fiat = 'fiat',
  Crypto = 'crypto',
  Empty = '',
}

export const assetTypeMapping: Record<string, AssetTypeEnum> = {
  [AssetClasses.Cash]: AssetTypeEnum.Fiat,
  [AssetClasses.Cryptocurrency]: AssetTypeEnum.Crypto,
  [AssetClasses.Stock]: AssetTypeEnum.Empty,
  [AssetClasses.Bond]: AssetTypeEnum.Empty,
  [AssetClasses.RealEstate]: AssetTypeEnum.Empty,
  [AssetClasses.PrivateEquity]: AssetTypeEnum.Empty,
  [AssetClasses.Commodity]: AssetTypeEnum.Empty,
  [AssetClasses.NFT]: AssetTypeEnum.Empty,
};

export type AssetType = 'fiat' | 'crypto';
