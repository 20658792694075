export enum DocumentType {
  BankStatement = 'bank-statement',
  BirthCertificate = 'birth-certificate',
  CertificateOfInsurance = 'certificate-of-insurance',
  CertificateOfNaturalisation = 'certificate-of-naturalisation',
  DrivingLicence = 'driving-licence',
  GovernmentLetter = 'government-letter',
  HomeOfficeLetter = 'home-office-letter',
  ImmigrationStatusDocument = 'immigration-status-document',
  MarriageCertificate = 'marriage-certificate',
  NationalIdentityCard = 'national-identity-card',
  NationalInsuranceCard = 'national-insurance-card',
  Passport = 'passport',
  Payslip = 'payslip',
  ResidencePermit = 'residence-permit',
  TaxId = 'tax-id',
  UtilityBillElectric = 'utility-bill-electric',
  UtilityBillGas = 'utility-bill-gas',
  UtilityBillOther = 'utility-bill-other',
  WorkPermit = 'work-permit',
  W8Ben = 'w8ben',
  LossUsNationality = 'loss-us-nationality',
  ArticlesOfAssociation = 'articles-of-association',
  CertificateOfIncorporation = 'certificate-of-incorporation',
  RegisterOfMembers = 'register-of-members',
  RegisterOfDirectors = 'register-of-directors',
  AnnualReturns = 'annual-returns',
  FinancialStatements = 'financial-statements',
  UtilityBillWater = 'utility-bill-water',
  UtilityBillLandlinePhone = 'utility-bill-landline-phone',
  UtilityBillInternet = 'utility-bill-internet',
  CreditCardStatement = 'credit-card-statement',
  TaxReceipt = 'tax-receipt',
  Other = 'other',
  Transaction = 'transaction',
  UtilityBillMobile = 'utility-bill-mobile',
  DepositSlip = 'deposit-slip',
  ProofOfIdentificationOfBeneficialOwner = 'proof-of-identification-of-beneficial-owner',
  ProofOfIdentificationOfDirector = 'proof-of-identification-of-director',
  ProofOfAddressOfDirector = 'proof-of-address-of-director',
  Invoice = 'invoice',
  ServiceAgreement = 'service-agreement',
  CertificateOfIncumbency = 'certificate-of-incumbency',
  ProofOfSourceOfFunds = 'proof-of-source-of-funds',
  CopyOfLicenseForConductingRegulatedActivities = 'copy-of-license-for-conducting-regulated-activities',
  ProofOfAddressOfBeneficialOwner = 'proof-of-address-of-beneficial-owner',
  WithdrawalCompletionSlip = 'withdrawal-completion-slip',
  PaymentCompletionSlip = 'payment-completion-slip',
  BusinessRegistration = 'business-registration',
}
