import { Injectable } from '@angular/core';

import { BaseApiService, PaginatedListRequestParams, PaginatedListResponse } from '@1stdigital/ng-sdk/core';
import { CreateTicketRequestDto, CreateTicketResponseDto } from '@app/help-center/shared/models/ticket.interface';
import { Observable } from 'rxjs';

import { SendTicketMessageRequest } from './shared/models/send-ticket-request.interface';
import { Ticket } from './shared/models/ticket.interface';
import { TicketMessage } from './shared/models/ticket-message.interface';

@Injectable({
  providedIn: 'root',
})
export class HelpCenterApiService extends BaseApiService {
  getTickets(request: PaginatedListRequestParams): Observable<PaginatedListResponse<Ticket>> {
    return this.getList('help-center/tickets', request);
  }

  createTicket(body: CreateTicketRequestDto): Observable<CreateTicketResponseDto> {
    return this.post('help-center/tickets', body);
  }

  getTicket(ticketId: number): Observable<Ticket> {
    return this.get(`help-center/tickets/${ticketId}`);
  }

  getTicketMessages(ticketId: number): Observable<TicketMessage[]> {
    return this.get(`help-center/tickets/${ticketId}/messages`);
  }

  sendTicketMessage(ticketId: number, body: SendTicketMessageRequest): Observable<TicketMessage> {
    return this.post(`help-center/tickets/${ticketId}/messages`, body);
  }
}
