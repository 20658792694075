import { fdtProp } from '@1stdigital/ng-sdk/annotations';
import { EntityType } from '@1stdigital/ng-sdk/core';
import { DocumentType } from '@app/core/models/document-type.enum';
import { FileDto } from '@app/core/models/interfaces/file.interface';

export class FileModel implements FileDto {
  key: string = '';
  size: number = 0;
  fileName?: string;
  contentType?: string;
  label?: string;
  documentType?: DocumentType;
  userId?: string;
  dateCreated?: Date;
}

export class AssetTransferFileModel {
  @fdtProp('Attachment', 'file', { fileEntityType: EntityType.Instruction })
  attachments: FileModel[] = [];
}
