import { Injectable } from '@angular/core';

import { BaseApiService } from '@1stdigital/ng-sdk/core';
import { AppModuleName } from '@app/core/models';
import { LoggingService } from '@app/core/services/logging.service';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';

type AppSettings = Record<AppModuleName, ModuleSettingsBase<unknown>>;

/**
 * Module Settings Contract
 */
interface ModuleSettingsBaseContract {
  /**
   * Timestamp of the last time the settings were stored.
   * Stored time value in milliseconds since midnight, January 1, 1970 UTC.
   */
  stored: number;
}

/**
 * Abstract class enforcing constructor logic with Partial<T>.
 */
export abstract class ModuleSettingsBase<T> implements ModuleSettingsBaseContract {
  stored!: number;

  /**
   * Enforces that we create a constructor with Partial<T> as an argument.
   * In order to be able to set the default values for the class properties.
   * @param data Partial<T> - Partial data to be assigned to the class instance
   */
  constructor(data?: Partial<T>) {
    Object.assign(this, data);
  }
}

type ModuleSettingsConstructor<T = ModuleSettingsBase<unknown>> = new (data?: Partial<T>) => T;

/**
 * Aplication settings service,
 * responsible for fetching and saving settings for different modules.
 *
 * The settings are stored in the API on 'application-data' endpoint;
 * each module has its own settings class that extends ModuleSettingsBase.
 *
 * In case we need reactive state management, we can refactor this service to use NgRx Store.
 * But mostlikelly we need just a simple state management, where we don't need reactive updates
 */
@Injectable({
  providedIn: 'root',
})
export class ApplicationSettingsService extends BaseApiService {
  // Holds settings for different modules
  private settings!: AppSettings;

  // Map of module name to their corresponding settings class
  static readonly settingsClassMap = new Map<AppModuleName, ModuleSettingsConstructor<ModuleSettingsBase<unknown>>>();

  static registerSettingsClass(
    moduleName: AppModuleName,
    settingsClass: ModuleSettingsConstructor<ModuleSettingsBase<unknown>>
  ): void {
    ApplicationSettingsService.settingsClassMap.set(moduleName, settingsClass);
  }

  userId!: string;

  constructor(private loggingService: LoggingService) {
    super();
  }

  /**
   * Gets the settings for the provided module name.
   * Can throw error if settings not loaded before
   * But for sake of simplicity we also have synchronous version of this method
   */
  getSettings<T>(moduleName: AppModuleName): T {
    const SettingsClass = ApplicationSettingsService.settingsClassMap.get(moduleName);

    if (!SettingsClass) {
      throw new Error(`Settings class not found for module: ${moduleName}`);
    }

    // we are ensuring the clone is returned;
    return new SettingsClass(this.settings[moduleName]) as T;
  }

  /**
   * Most likelly we will not need (one user can be logged in in one tab atm)
   */
  getSettingsAsync<T>(moduleName: AppModuleName): Observable<T> {
    return this.getInternal().pipe(map(() => this.settings[moduleName] as T));
  }

  init(): Observable<boolean> {
    return this.getInternal().pipe(
      tap(() => {
        this.loggingService.log('ApplicationSettingsService: Settings loaded');
      }),
      map(() => true),
      catchError((error) => {
        this.loggingService.error('Failed to save settings', error);
        return of(false);
      })
    );
  }

  /**
   * Saves the settings for the specified module.
   */
  saveSettings(moduleName: AppModuleName, newSettings: ModuleSettingsBase<unknown>): Observable<boolean> {
    return this.getInternal().pipe(
      switchMap(() => {
        const SettingsClass = ApplicationSettingsService.settingsClassMap.get(moduleName);
        // Get the current settings for the module
        const currentSettings = this.settings[moduleName] || {};

        // Merge the new settings into the existing settings
        const mergedSettings = Object.assign(currentSettings, newSettings);

        if (!SettingsClass) {
          this.loggingService.error(`Settings class not found for module: ${moduleName}`);
          return of(false);
        }

        const moduleSettings = { ...this.settings, [moduleName]: new SettingsClass(mergedSettings) };
        // we are creaing a new instance of the settings class with merged settings
        // like this we are doing `housekeeping` of the settings class
        // and we are ensuring that the settings are of the correct type
        // And removing any additional properties that are not part of the settings class (legacy proepreties)
        this.beforeSave(moduleSettings);

        // Save the merged settings to storage via API
        return this.saveInternal(moduleSettings).pipe(
          // We are doing map here to ensure that the observable completes
          // Boolean value is just a decoy, most likely we can go with void;
          map(() => true),
          catchError((error) => {
            this.loggingService.error('Failed to save settings', error);
            return of(false);
          })
        );
      })
    );
  }

  /**
   * Loads the settings from API.
   */
  private getInternal(): Observable<AppSettings> {
    return this.get<{ json: string; userId: string }>('user-application-data').pipe(
      tap(({ userId }) => (this.userId = userId)),
      map(({ json }) => {
        let settings = JSON.parse(json) as AppSettings;
        const defaultSettings = this.getDefaultSettings();
        settings = { ...defaultSettings, ...settings };
        Object.keys(settings).forEach((moduleName) => {
          const SettingsClass = ApplicationSettingsService.settingsClassMap.get(moduleName as AppModuleName);
          let moduleSettings: ModuleSettingsBase<unknown>;

          if (SettingsClass) {
            moduleSettings = new SettingsClass(settings[moduleName as AppModuleName]);

            settings[moduleName as AppModuleName] = moduleSettings;
          }
        });
        return settings;
      }),
      catchError(() => of(this.getDefaultSettings())),
      tap((settings) => (this.settings = settings)) // Store settings locally after fetching
    );
  }

  private getDefaultSettings(): AppSettings {
    const result = {} as AppSettings;

    ApplicationSettingsService.settingsClassMap.forEach((SettingsClass, moduleName) => {
      result[moduleName] = new SettingsClass({});
    });

    return result;
  }

  /**
   * Sends a PATCH request to update the settings via API.
   */
  private saveInternal(settings: AppSettings): Observable<void> {
    return this.patch<void>('user-application-data', { json: JSON.stringify(settings) });
  }

  /**
   * Things to do before saving the settings.
   *     1. Updates the timestamp of the settings before saving.
   */
  private beforeSave(settings: AppSettings): void {
    const time = new Date().getTime();

    Object.keys(settings).forEach((moduleName) => {
      const newSettings = JSON.stringify(settings[moduleName as AppModuleName]);
      const oldSettings = JSON.stringify(this.settings[moduleName as AppModuleName]);

      if (newSettings !== oldSettings) {
        settings[moduleName as AppModuleName].stored = time;
      }
    });
  }
}
