import { ConnectedPosition } from '@angular/cdk/overlay';

import {
  TourConfigPosition,
  tourConfigPositionsMap,
  TourPositionsOffset,
} from '@app/tour/models/tour-config-position.type';

/**
 * Generates a list of connected positions for an overlay based on preferred positions and optional offsets.
 *
 * @param preferredPositions - An array of positions preferred for the overlay.
 * @param offset - Optional offsets for specific positions.
 * @returns An array of `ConnectedPosition` objects including both preferred and default positions.
 */
export function generateOrderedPosition(
  preferredPositions: TourConfigPosition[] = [],
  offset?: TourPositionsOffset
): ConnectedPosition[] {
  const defaultPositions = filterDefaultPosition(preferredPositions);

  return getPositionsConfig([...preferredPositions, ...defaultPositions], offset);
}

/**
 * Retrieves the configuration for each position including offsets.
 */
function getPositionsConfig(positions: TourConfigPosition[], offset?: TourPositionsOffset): ConnectedPosition[] {
  return positions.map((position: TourConfigPosition) => {
    const offsetConfig = offset?.[position] ? offset[position] : {};

    return {
      ...tourConfigPositionsMap[position],
      ...offsetConfig,
    };
  });
}

/**
 * Filters out the default positions that are not included in preferredPositions.
 */
function filterDefaultPosition(preferredPositions: TourConfigPosition[]): TourConfigPosition[] {
  return Object.keys(tourConfigPositionsMap).filter(
    (key) => !preferredPositions.includes(key as TourConfigPosition)
  ) as TourConfigPosition[];
}
