<div class="fdt-modal fdt-confirmation-modal">
  <div class="fdt-modal__header">
    <div class="fdt-modal__title">
      {{ config.title }}
    </div>
    <button fdt-ghost-button class="fdt-modal__delete-icon" (click)="onCancelModal()">
      <fdt-icon name="closeLine" size="24"></fdt-icon>
    </button>
  </div>

  <div class="fdt-confirmation-modal__body">
    <div class="fdt-confirmation-modal__message">
      {{ config.message }}
    </div>

    <div class="fdt-confirmation-modal__button-container">
      <button fdt-button color="secondary" [fullSize]="true" (click)="onCancelModal()">{{ config.noLabel }}</button>
      <button fdt-button [fullSize]="true" (click)="onSubmitModal()">{{ config.yesLabel }}</button>
    </div>
  </div>
</div>
