import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { FdtIconFrameComponent } from '@1stdigital/ng-sdk/shared';
import { StorageKeys } from '@app/core/models';
import { NotificationsApiService } from '@app/core/services/notifications-api.service';
import { StorageService } from '@app/core/services/storage.service';

@Component({
  selector: 'app-announcement',
  standalone: true,
  imports: [FdtIconModule, FdtButtonModule, FdtIconFrameComponent],
  templateUrl: './announcement.component.html',
  styleUrl: './announcement.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementComponent implements OnInit {
  message: string | null = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private host: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private storageService: StorageService,
    private notificationsApi: NotificationsApiService,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const announcementDismissed = this.storageService.getItem<boolean>(StorageKeys.APP_ANNOUNCEMENT_DISMISSED);

    if (announcementDismissed) {
      this.host.nativeElement.remove();
    } else {
      this.notificationsApi.getLatestNotification().subscribe(({ content }) => {
        if (content) {
          this.message = this.sanitizer.sanitize(SecurityContext.HTML, content);
          // We are using modals with full screen, we need this global class, so we can set a margin-top on cdk-overlay-container
          this.renderer.addClass(this.document.body, 'app-announcement-active');
          this.cd.detectChanges();
        }
      });
    }
  }

  onCloseAnnouncement(): void {
    this.host.nativeElement.remove();
    this.renderer.removeClass(this.document.body, 'app-announcement-active');
    this.storageService.setItem(StorageKeys.APP_ANNOUNCEMENT_DISMISSED, true);
  }
}
