import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { KycSelfieContextService } from '@app/core/services/kyc-selfie-context.service';
import { MsalService } from '@azure/msal-angular';
import { map, Observable, of, switchMap } from 'rxjs';
import { EnvironmentLoaderService } from 'src/app/core/services';
import { KycApiService } from 'src/app/kyc-selfie/kyc-api.service';
import { UserDetailForKYCSelfieResponse } from 'src/app/kyc-selfie/shared/kyc-selfie.models';

export const kycSelfieGuard = (): Observable<boolean> => {
  const router = inject(Router);
  const kycSelfieContextService = inject(KycSelfieContextService);
  const kycService = inject(KycApiService);
  const authService = inject(MsalService);
  const envService = inject(EnvironmentLoaderService);

  const enableAllKycChecks = envService.environment['enableAllKycChecks'];
  const userDetailForKycSelfie = kycSelfieContextService.getActiveKYCSelfieEntity();

  // Feature Flag Off - Not enforcing Onfido checking
  // KYC status:
  // - NULL: First time, ask to complete Onfido verification
  // - NOT NULL: Any status, bypass for now

  // Feature Flag On - Enforcing Onfido checking
  // KYC status:
  // - NULL: First time, ask to complete Onfido verification
  // - Passed: Route to dashboard
  // - Other statuses: Wait for result on the KYC page

  // Remarks: After processing all failed Onfido cases by compliance ops, we will remove this flag.

  if (!enableAllKycChecks) {
    if (userDetailForKycSelfie) {
      return of(true);
    }

    return kycService.getSelfieCheck().pipe(
      map((kycSelfieResponse) => {
        if (kycSelfieResponse?.status) {
          return true;
        }

        // No Onfido submitted if status is undefined
        router.navigate(['/kyc-selfie']);
        return false;
      })
    );
  }

  if (userDetailForKycSelfie?.kycSelfieStatus === 'passed') {
    return of(true);
  }

  return kycService.getUserKycSelfieDetails().pipe(
    switchMap((kycDetail) => {
      if (kycDetail.kycSelfieStatus === 'passed') {
        return of(true);
      }

      return kycService.getSelfieCheck().pipe(
        switchMap((kycSelfieResponse) => {
          if (kycSelfieResponse?.status === 'passed') {
            const idTokenClaims = authService.instance.getActiveAccount()?.idTokenClaims;
            kycSelfieContextService.setActiveKycSelfieEntity({
              fullName: kycDetail.fullName,
              dateOfBirth: kycDetail.dateOfBirth,
              email: idTokenClaims?.['signInNames.emailAddress'],
              kycSelfieStatus: 'passed',
              onfidoApplicantId: kycDetail.onfidoApplicantId,
              kycSelfieCheckId: kycDetail.kycSelfieCheckId,
            } as UserDetailForKYCSelfieResponse);
            return of(true);
          }

          router.navigate(['/kyc-selfie']);
          return of(false);
        })
      );
    })
  );
};
