@if (activeTourState$ | async; as tourState) {
  <section class="tour-step">
    <span class="tour-step__arrow" [class]="arrowDirectionSignal()" [style]="arrowPositioningStyle()"></span>
    <header class="tour-step__head">
      @if (tourState.step.label; as label) {
        <p class="tour-step__count">{{ label }}</p>
      } @else if (tourState.tour.steps.length > 1) {
        <p class="tour-step__count">{{ tourState.stepIndex + 1 }}/{{ tourState.tour.steps.length }}</p>
      }

      <h2 class="tour-step__title">{{ tourState.step.title }}</h2>
    </header>

    <p class="tour-step__description">
      {{ tourState.step.description }}
    </p>

    <footer class="tour-step__actions">
      @if (tourState.stepIndex === tourState.tour.steps.length - 1) {
        <div class="tour-step__nav-actions">
          @if (tourState.stepIndex > 0) {
            <button fdt-ghost-button (click)="onBack()">Back</button>
          }
          <button fdt-button (click)="onComplete()">{{ tourState.step.nextBtnText ?? 'Finish Tour' }}</button>
        </div>
      } @else {
        <button fdt-ghost-button (click)="onTakeTourLater()">Take tour later</button>

        <div class="tour-step__nav-actions">
          @if (tourState.stepIndex > 0) {
            <button fdt-ghost-button (click)="onBack()">Back</button>
          }
          <button fdt-button size="small" (click)="onNext()">{{ tourState.step.nextBtnText ?? 'Next' }}</button>
        </div>
      }
    </footer>
  </section>
}
