import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';

import { AppModule } from './app/app.module';

// Apply the polyfill to ensure country flags are rendered as emojis across the application;
// To see the country flag emojis correctly, make sure to set a 'Twemoji Country Flags' font;
polyfillCountryFlagEmojis();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
