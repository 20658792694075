import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

import { ButtonComponent } from '@1stdigital/ng-sdk/button';
import { IconComponent } from '@1stdigital/ng-sdk/icon';

@Component({
  selector: 'app-transfer-transfer-disclaimer-modal',
  standalone: true,
  imports: [ButtonComponent, IconComponent],
  templateUrl: './transfer-disclaimer-modal.component.html',
  styleUrl: './transfer-disclaimer-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferDisclaimerModalComponent {
  constructor(private readonly dialogRef: MatDialogRef<TransferDisclaimerModalComponent, boolean>) {}

  onCloseModal(): void {
    this.dialogRef.close(false);
  }
}
