import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout/layout.component';
import { LayoutApiService } from './layout-api.service';

@NgModule({
  declarations: [],
  imports: [LayoutComponent],
  exports: [LayoutComponent],
  providers: [LayoutApiService],
})
export class LayoutModule {}
