@switch (eventId) {
  @case ('ams.instruction.rsn.failed.notenoughgasfee.creator') {
    Your RSN instruction couldn't process due to insufficient funds for the network fee. Please retry. (Ref:
    {{ requestData.instructionReferenceNumber }}).
  }
  @case ('ams.instruction.fiat.withdrawal.initiated.client') {
    Withdrawal instruction created for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }}.
  }
  @case ('ams.instruction.fiat.withdrawal.completed.client') {
    Withdrawal instruction for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} is complete (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.payment.pending.client') {
    Payment instruction for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} is in progress (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.payment.completed.client') {
    Payment instruction for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} is complete (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.payment.cancelled.client') {
    Payment instruction for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} has been cancelled (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.payment.initiated.client') {
    Payment instruction created for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.deposit.completed.client') {
    Deposit instruction for {{ requestData.currency }} {{ requestData.amount }} is complete (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.withdrawal.cancelled.client') {
    Withdrawal instruction for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} has been cancelled (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.payment.rejected.client') {
    Payment instruction for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} has been rejected (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.deposit.initiated.client') {
    Deposit instruction created for {{ requestData.currency }} {{ requestData.amount }} to (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.deposit.pending.client') {
    Deposit instruction for {{ requestData.currency }} {{ requestData.amount }} is in progress (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.fiat.deposit.cancelled.client') {
    Deposit instruction for {{ requestData.currency }} {{ requestData.amount }} has been cancelled (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.crypto.payment.completed.client') {
    Payment instruction for {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.address }} is complete
    (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.crypto.payment.cancelled.client') {
    Payment instruction for {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.address }} has been
    cancelled (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.crypto.deposit.completed.client') {
    Deposit instruction for {{ requestData.currency }} {{ requestData.amount }} is complete (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.crypto.deposit.cancelled.client') {
    Deposit instruction for {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.packageName }} has
    been cancelled (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.crypto.withdrawal.cancelled.client') {
    Withdrawal instruction for {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.address }} has been
    cancelled (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.crypto.withdrawal.completed.client') {
    Withdrawal instruction for {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.address }} is
    complete (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.crypto.deposit.initiated.client') {
    Deposit instruction created for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.packageName }} (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.crypto.withdrawal.initiated.client') {
    Withdrawal instruction created for
    {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.address }}.
  }
  @case ('ams.instruction.crypto.payment.initiated.client') {
    Payment instruction created for {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.address }}.
  }
  @case ('ams.instruction.rsn.completed.client.sender') {
    Your account has been debited per your RSN Transfer instruction; your transfer is complete.
  }
  @case ('ams.instruction.rsn.completed.client.receiver') {
    You've received funds in your account from {{ requestData.fromPackageName }} -
    {{ requestData.fromServiceAccountNumber }} for {{ requestData.currency }} {{ requestData.amount }} transferred on
    {{ requestData.dateUpdated }}.
  }
  @case ('ams.instruction.rsn.initiated.client') {
    RSN payment created for {{ requestData.currency }} {{ requestData.amount }} from {{ requestData.fromPackageName }} -
    {{ requestData.fromServiceAccountNumber }} to {{ requestData.toPackageName }} -
    {{ requestData.toServiceAccountNumber }} (Ref: {{ requestData.referenceNumber }})
  }
  @case ('ams.instruction.rsn.pending.client') {
    RSN instruction for {{ requestData.currency }} {{ requestData.amount }} from {{ requestData.fromPackageName }} -
    {{ requestData.fromServiceAccountNumber }} to {{ requestData.toPackageName }} -
    {{ requestData.toServiceAccountNumber }} is in progress (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.rsn.completed.client') {
    RSN instruction for {{ requestData.currency }} {{ requestData.amount }} from {{ requestData.fromPackageName }} -
    {{ requestData.fromServiceAccountNumber }} to {{ requestData.toPackageName }} -
    {{ requestData.toServiceAccountNumber }} is complete (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.rsn.cancelled.client') {
    RSN instruction for {{ requestData.currency }} {{ requestData.amount }} from {{ requestData.fromPackageName }} -
    {{ requestData.fromServiceAccountNumber }} to {{ requestData.toPackageName }} -
    {{ requestData.toServiceAccountNumber }} has been cancelled (Ref: {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.otc.initiated.client') {
    OTC instruction created: {{ requestData.sourceAmount }} {{ requestData.sourceCurrencySymbol }} to
    {{ requestData.destinationAmount }} {{ requestData.destinationCurrencySymbol }} (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.otc.completed.client') {
    OTC instruction for {{ requestData.sourceAmount }} {{ requestData.sourceCurrencySymbol }} to
    {{ requestData.destinationAmount }} {{ requestData.destinationCurrencySymbol }} is complete (Ref:
    {{ requestData.referenceNumber }}).
  }
  @case ('ams.instruction.rsn.completed.client') {
    RSN instruction for {{ requestData.currency }} {{ requestData.amount }} from {{ requestData.fromPackageName }} -
    {{ requestData.fromServiceAccountNumber }} to {{ requestData.toPackageName }} -
    {{ requestData.toServiceAccountNumber }} is complete (Ref: {{ requestData.referenceNumber }}).
  }
}
