import { Injectable } from '@angular/core';

import { BaseApiService } from '@1stdigital/ng-sdk/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientsAccessorApiService extends BaseApiService {
  getAssetMasterIds(): Observable<number[]> {
    return this.get('clients-accessor/asset-master-ids');
  }
}
