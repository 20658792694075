import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { TrackEventDirective } from '@app/shared/directives/track-event.directive';
import { InfoBlockComponent } from '@app/shared/info-block/info-block.component';

import { CreateHelpTicketModalComponent } from '../create-help-ticket-modal/create-help-ticket-modal.component';

@Component({
  selector: 'app-help-widget',
  standalone: true,
  imports: [
    MatMenuModule,
    FdtButtonModule,
    FdtIconModule,
    InfoBlockComponent,
    CreateHelpTicketModalComponent,
    TrackEventDirective,
  ],
  templateUrl: './help-widget.component.html',
  styleUrl: './help-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpWidgetComponent {
  closeWidget(trigger: MatMenuTrigger): void {
    trigger.closeMenu();
  }
}
