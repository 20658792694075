<app-file-uploader
  [control]="ngControl.control"
  [multiple]="config.multiple"
  (upload)="onAttachmentUpload($event)"
  [label]="config.label"
  [documentTypeGroup]="config.documentTypeGroup"
  [documentType]="config.documentType"
  [temporaryUploaded]="value || []"
  (focused)="onFocus()"
  (blurred)="onBlur()"
></app-file-uploader>
