<div class="progress-container" [style.width.px]="usedDiameter" [style.height.px]="usedDiameter">
  <div
    class="progress-container__content"
    (cdkObserveContent)="onProjectContentChanged()"
    [style.width.px]="innerContainerSize"
    [style.height.px]="innerContainerSize"
  >
    <div #passedContent class="progress-container__inner-container">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="progress-container__spinner-container">
    @if (usedDiameter) {
      <mat-progress-spinner
        class="progress-container__spinner-main"
        mode="determinate"
        [strokeWidth]="3"
        [diameter]="usedDiameter"
        [value]="progress"
      >
      </mat-progress-spinner>
      <mat-progress-spinner
        class="progress-container__spinner-empty"
        mode="determinate"
        strokeWidth="3"
        [diameter]="usedDiameter"
        value="100"
      >
      </mat-progress-spinner>
    }
  </div>
</div>
