<section class="inbox-message">
  <section
    class="inbox-message__content"
    [class.inbox-message--unread]="!message.read"
    [@slideRight]="messageAnimationStateSignal()"
    (@slideRight.done)="onAnimationComplete(message)"
  >
    <header class="inbox-message__header">
      <div class="inbox-message__type">
        <div class="inbox-message__icon">
          <fdt-icon [name]="iconMap[message.data.notificationInboxType] ?? 'questionLine'" size="16"></fdt-icon>
          @if (messageCategory) {
            <span>{{ messageCategory }}</span>
          }
        </div>

        <span class="inbox-message__date">{{ message.created | fdtDateTime: 'medium' }}</span>
      </div>

      @if (!message.read) {
        <button fdt-ghost-button (click)="onMarkAsRead(message)" class="inbox-message__read-btn">
          <fdt-icon name="checkLine"></fdt-icon>
        </button>
      }
    </header>

    <main class="inbox-message__body">
      <span class="inbox-message__title">{{ message.title }}</span>
      @if (message.data.notificationInboxType === 'instructionReview') {
        <span class="inbox-message__text">
          <app-inbox-instruction-review-text
            [eventId]="message.data.requestData.eventId"
            [requestData]="message.data.requestData"
          ></app-inbox-instruction-review-text>
        </span>
        @if (!message.read && message.data.requestData.eventId === 'ams.instruction.waitingforreview.checker') {
          <button class="button-1" fdt-button color="primary" (click)="instructionReview.emit(message)">
            Review Instruction
          </button>
        }
      } @else if (message.data.notificationInboxType === 'instructionUpdate') {
        <span class="inbox-message__text">
          <app-inbox-instruction-update-text
            [eventId]="message.data.requestData.eventId"
            [requestData]="message.data.requestData"
          ></app-inbox-instruction-update-text>
        </span>
      } @else if (message.data.notificationInboxType === 'announcements') {
        <span class="inbox-message__text"> {{ message.preview }} </span>
      }
    </main>
  </section>

  <p class="inbox-message__placeholder inbox-message__text">Read</p>
</section>
