<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<button
  fdt-fab-button
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="helpCenterMenu"
  size="large"
  color="accent"
  class="fdt-btn--floating"
  appTrackEvent="Help center bubble clicked"
>
  <fdt-icon [name]="menuTrigger.menuOpen ? 'closeLine' : 'questionLine'"></fdt-icon>
</button>
<mat-menu
  #helpCenterMenu="matMenu"
  backdropClass="fdt-dialog-cdk-overlay-backdrop"
  xPosition="before"
  yPosition="above"
  class="fdt-mat-mdc-menu-panel fdt-dropdown-panel help-widget__menu-panel"
>
  <div class="help-widget" (keydown)="$event.stopPropagation()" (click)="$event.stopPropagation()">
    <app-create-help-ticket (ticketCreated)="closeWidget(menuTrigger)"></app-create-help-ticket>
  </div>
</mat-menu>
