import {
  addLine,
  alertLine,
  arrowDownLine,
  arrowDownSFill,
  arrowDownSLine,
  arrowLeftSLine,
  arrowRightSFill,
  arrowRightSLine,
  arrowUpDownLine,
  arrowUpLine,
  arrowUpSFill,
  arrowUpSLine,
  bankCard2Line,
  bankCardLine,
  bankFill,
  bankLine,
  barChart2Fill,
  barChart2Line,
  buildingLine,
  calendarLine,
  caretLeft,
  caretRight,
  chat1Line,
  check,
  checkboxCircleLine,
  checkDoubleLine,
  checkLine,
  clock,
  closeCircleFill,
  closeCircleLine,
  closeLine,
  contactsBook3Fill,
  contactsBook3Line,
  copperCoinFill,
  copperCoinLine,
  customerService2Fill,
  customerService2Line,
  deleteBinLine,
  downloadLine,
  draftLine,
  editLine,
  envelopeSimple,
  externalLinkLine,
  eyeFill,
  eyeLine,
  fd,
  fileCopyLine,
  fileCsv,
  fileEditLine,
  fileInfoLine,
  fileLine,
  filePdf,
  gear,
  gift,
  graduationCapFill,
  graduationCapLine,
  groupLine,
  historyLine,
  home3Fill,
  home3Line,
  informationLine,
  lineChartLine,
  link,
  loader4Line,
  lock2Line,
  logoutBoxRLine,
  loopLeftLine,
  menuLine,
  moonFill,
  more2Fill,
  more2Line,
  noteEdit,
  notification3Fill,
  notification3Line,
  pieChartFill,
  pieChartLine,
  questionLine,
  redPacketFill,
  redPacketLine,
  searchLine,
  sendPlaneLine,
  settings3Line,
  shareBoxLine,
  shieldKeyholeLine,
  shoppingCartLine,
  skipLeftLine,
  skipRightLine,
  snowflakeLine,
  spam3Fill,
  spam3Line,
  starFill,
  starLine,
  timeFill,
  timeLine,
  uploadCloud2Line,
  uploadLine,
  userFill,
  userLine,
  walletFill,
  walletLine,
} from '@1stdigital/ng-sdk/icon';

export const ICON_REGISTRY = {
  alertLine,
  arrowDownSFill,
  arrowRightSFill,
  arrowDownLine,
  arrowUpDownLine,
  arrowUpLine,
  arrowLeftSLine,
  arrowRightSLine,
  shieldKeyholeLine,
  skipLeftLine,
  skipRightLine,
  bankFill,
  bankCardLine,
  buildingLine,
  home3Fill,
  bankCard2Line,
  bankLine,
  home3Line,
  barChart2Fill,
  customerService2Fill,
  pieChartFill,
  barChart2Line,
  customerService2Line,
  lineChartLine,
  loader4Line,
  lock2Line,
  pieChartLine,
  clock,
  copperCoinFill,
  redPacketFill,
  walletFill,
  copperCoinLine,
  redPacketLine,
  walletLine,
  eyeFill,
  menuLine,
  moonFill,
  more2Fill,
  spam3Fill,
  spam3Line,
  starFill,
  timeFill,
  addLine,
  calendarLine,
  caretLeft,
  caretRight,
  checkLine,
  checkboxCircleLine,
  closeCircleLine,
  closeCircleFill,
  closeLine,
  deleteBinLine,
  downloadLine,
  draftLine,
  eyeLine,
  historyLine,
  informationLine,
  loopLeftLine,
  more2Line,
  gift,
  shareBoxLine,
  shoppingCartLine,
  starLine,
  timeLine,
  notification3Fill,
  notification3Line,
  userFill,
  userLine,
  editLine,
  contactsBook3Fill,
  contactsBook3Line,
  fileCsv,
  filePdf,
  fileCopyLine,
  fileLine,
  graduationCapFill,
  graduationCapLine,
  link,
  uploadCloud2Line,
  settings3Line,
  gear,
  snowflakeLine,
  // dropdown
  arrowDownSLine,
  arrowUpSLine,
  arrowUpSFill,
  searchLine,
  uploadLine,
  groupLine,
  fileInfoLine,
  externalLinkLine,
  questionLine,
  fileEditLine,
  chat1Line,
  sendPlaneLine,
  fd,
  envelopeSimple,
  logoutBoxRLine,
  check,
  checkDoubleLine,
  noteEdit,
};
