import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-view',
  standalone: true,
  imports: [],
  templateUrl: './empty-view.component.html',
  styleUrl: './empty-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyViewComponent {
  @Input() header!: string;
  @Input() subtitle!: string;
}
