import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { map } from 'rxjs';

import { EnvironmentLoaderService } from '../services';
import { UserInfoApiService, UserInfoForClientResponse } from '../services/user-info-api.service';

export const cardGuard: CanActivateFn = () => {
  const router = inject(Router);
  const envService = inject(EnvironmentLoaderService);
  const userInfoService = inject(UserInfoApiService);

  if (envService.environment['enableCard']) {
    return userInfoService.getUserInfoResponse().pipe(
      map((userInfo: UserInfoForClientResponse) => {
        const cardApplication = !!envService.environment['cardApplication'];

        if (!cardApplication) {
          return userInfo.isCardHolder || userInfo.isCardAdmin || router.parseUrl('404');
        }

        return true;
      })
    );
  }

  return router.parseUrl('404');
};
