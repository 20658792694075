import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Environment } from 'src/environments/environment.interface';

// auth runtime config
interface AuthRuntimeConfig {
  authority: string;
  redirect_url: string;
  client_id: string;
  scope: string;
}

// auth config for msal
interface AuthConfig {
  authority: string;
  redirectUri: string;
  clientId: string;
  scope: string;
}

export type EnvConfigValueType = Record<string, string | boolean | number | object>;
type EnvConfigType = Record<string, EnvConfigValueType>;
type AuthConfigType = Record<string, AuthRuntimeConfig>;

@Injectable({ providedIn: 'root' })
export class EnvironmentLoaderService {
  envConfig: EnvConfigType = {};
  authConfig: AuthConfigType = {};

  constructor(private handler: HttpBackend) {}

  async loadConfig(envPath: string, configPath: string): Promise<void> {
    await this.loadEnvConfig(envPath).then(() => this.loadAuthConfig(configPath));
  }

  async loadEnvConfig(configPath: string): Promise<void> {
    this.envConfig = (await lastValueFrom(new HttpClient(this.handler).get(configPath))) as EnvConfigType;
  }

  async loadAuthConfig(configPath: string): Promise<void> {
    this.authConfig = (await lastValueFrom(new HttpClient(this.handler).get(configPath))) as AuthConfigType;
  }

  get environmentName(): string {
    const envObject: EnvConfigValueType | undefined = Object.values(this.envConfig).find((env: EnvConfigValueType) =>
      location.host.includes(env['host'] as string)
    );

    if (!envObject) {
      throw new Error('env config not set error');
    }

    const envName: string = Object.keys(this.envConfig)[Object.values(this.envConfig).indexOf(envObject)];

    return envName;
  }

  isProduction(): boolean {
    return this.environmentName === 'prod';
  }

  get environment(): EnvConfigValueType {
    const env: string = this.environmentName;
    const envConfig = this.envConfig[env];
    const typedEnvironment = environment as Environment;

    // If `apiEndpoint` exists in the Environment, use it to override the value passed in `envConfig`;
    return {
      ...envConfig,
      apiEndpoint: typedEnvironment.apiEndpoint ?? envConfig['apiEndpoint'],
    };
  }

  get baseUrl(): string {
    return this.environment['apiEndpoint'] as string;
  }

  get authEnvironmentName(): string {
    const authObject: AuthRuntimeConfig | undefined = Object.values(this.authConfig).find((ac: AuthRuntimeConfig) =>
      ac.redirect_url.includes(location.host)
    );

    if (!authObject) {
      throw new Error('auth config not set error');
    }

    const envName: string = Object.keys(this.authConfig)[Object.values(this.authConfig).indexOf(authObject)];

    return envName;
  }

  get config(): AuthConfig {
    const env: string = this.authEnvironmentName;
    const authObject = this.authConfig[env];

    return {
      authority: authObject.authority,
      redirectUri: authObject.redirect_url,
      clientId: authObject.client_id,
      scope: authObject.scope,
    };
  }
}
