import { Tour, TourStep } from '@app/tour/models/tour.interface';

const onboardingSteps: TourStep[] = [
  {
    id: 1,
    title: 'Switch between Client & Service Suite',
    description:
      'Seamlessly manage onboarded clients within your organization, with easy switching between our tailored service suites & products.',
    preferredPositions: ['right', 'bottom'],
    positionsOffset: { right: { offsetY: 40 } },
  },
  {
    id: 2,
    title: 'Manage all your Assets & Holdings',
    description:
      'Review and manage your portfolio with full visibility and control of your existing accounts and balances. Quickly access detailed account overviews with historical data and filtering options.',
    preferredPositions: ['right', 'bottom'],
  },
  {
    id: 3,
    title: 'Track instruction progress in Activity history',
    description:
      'Robust filtering gives you complete control and allows you to see the status of all your active transactions and asset movements.',
    preferredPositions: ['right', 'bottom'],
  },
  {
    id: 4,
    title: 'Create instructions',
    description:
      'Transfer your assets within, across, and outside of Smart Trust with quick turnaround times and peace of mind.',
    preferredPositions: ['right', 'bottom'],
  },
  {
    id: 5,
    title: 'Whitelist accounts & wallets',
    description: 'Easily add, edit, and manage both your own and third-party accounts for seamless transfers.',
    preferredPositions: ['right', 'top'],
    positionsOffset: { right: { offsetY: -20 } },
  },
];

const relaunchOnboardingSteps: TourStep[] = [
  {
    id: 1,
    title: 'Launch onboarding tour',
    description: 'You can always launch the onboarding tour later in the Profile menu.',
    preferredPositions: ['right', 'top'],
    nextBtnText: 'Got It',
  },
];

const notificationsSteps: TourStep[] = [
  {
    id: 1,
    title: 'Portal updates & instruction progress',
    description:
      'Stay informed with the latest Smart Trust updates and track the progress of your instructions through notifications.',
    preferredPositions: ['right-top', 'bottom'],
    positionsOffset: { 'right-top': { offsetY: -10 } },
    nextBtnText: 'Got It!',
    label: 'New Feature',
  },
];

/**
 * An array of available tours.
 * Each tour is identified by a unique name and consists of a sequence of steps which are executed one by one in array order.
 *
 * @property {TourName} name - unique name of the tour.
 * @property {TourStep[]} steps - array of steps.
 */
export const LAYOUT_TOURS: Tour[] = [
  {
    name: 'onboarding',
    steps: onboardingSteps,
  },
  {
    name: 'relaunch-onboarding',
    steps: relaunchOnboardingSteps,
  },
  {
    name: 'notifications',
    steps: notificationsSteps,
  },
];
